<template>

    <div class="pu_wd_def_min pu_wrapper_main">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(s) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_banner_top />
            <inc_user_left />
            <inc_user_title />
            <!-------------------------- 인클루드(s) -------------------------->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">

                <!-- 리스트(s) -->
                <div>
                    <table class="pu_table_list">
                        <colgroup>
                            <col width="90px" />
                            <col width="*%" />
                            <col width="90px" />
                            <col width="*%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col" style="border-left: solid 1px #bebebe;">질문 내용</th>
                                <th scope="col" style="border-left: solid 1px #bebebe;"></th>
                                <th scope="col" style="border-left: solid 1px #bebebe;">해설 내용</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, idx) in list_1">
                                <th style="vertical-align:top;">
                                    <a class="pu_ss_btn_red" href="javascript:;" style="margin-top: 15px; padding: 0px 10px;"
                                       v-on:click="$PageMove('./regi?no=' + row.NO + '&type=' + 'Q')">질문 수정</a>

                                    <a class="pu_ss_btn_dark" href="javascript:;" style="margin-top: 5px; padding: 0px 10px;"
                                       v-on:click="exec('CHECK_COMP', row.NO)">수정 완료</a>
                                </th>
                                <td style="vertical-align: top; border-left: solid 1px #bebebe;">
                                    <div class="pu_table_inner" style="border-top: unset;"
                                         v-html="$ConHtml(row.Q)"></div>
                                </td>
                                <th style="vertical-align: top; border-left: solid 1px #bebebe; ">
                                    <a class="pu_ss_btn_red" href="javascript:;" style="margin-top: 15px; padding: 0px 10px;"
                                       v-on:click="$PageMove('./regi?no=' + row.NO + '&type=' + 'A')" >해설 수정</a>
                                </th>
                                <td style="vertical-align: top; border-left: solid 1px #bebebe;">
                                    <div class="pu_table_inner" style="border-top: unset;"
                                         v-html="$ConHtml(row.A)"></div>
                                </td>
                            </tr>
                            <tr v-if="list_1.length == 0">
                                <td colspan="4">조회된 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 리스트(e) -->
                
                <div class="pu_divBtnMain">
                    <div class="pu_divLeft">                        
                    </div>
                    <div class="pu_divCenter">
                        <!-- 페이징(s) -->
                        <div class="pu_pagination_2" v-html="paging"></div>
                        <!-- 페이징(e) -->
                    </div>
                    <div class="pu_divRight">
                    </div>
                </div>

            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 서브 실제 컨텐츠 영역 (e) -------------------------->
        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0
                , paging: ""      // 페이징

                , list_1: []
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", 1);
            }

            // 실행
            , exec: function (gubun, num) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.$GetQueryMap();
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/ngd_edit/list";

                            // 데이터
                            data["curPage"] = num;

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    case "CHECK_COMP":
                        {
                            if (confirm("수정완료 처리하시겠습니까?")) {

                                // url 설정
                                url = "/vue_api/user/ngd_edit/check_exam";

                                // 폼
                                data["no"] = num;

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#searchForm")));

                                // dimm 보이기
                                this.$ShowDimmLogo();
                            }
                            else {
                                return;
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "SEARCH":
                        {
                            _this.rownum = data.ROWNUM;
                            _this.paging = data.PAGING;
                            _this.list_1 = data.LIST_1;
                        }
                        break;

                    case "CHECK_COMP":
                        {
                            _this.exec("SEARCH", 1);
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }
    }

</script>