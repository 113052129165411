<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">
            <!-- 왼쪽 메뉴 layout(s) -->
                <div id="AdminNav" class="pu_LnbWrap">
                    <a  class="pu_openbtn" style="display: none;" onclick="openNav()" title="메뉴펼쳐보기"><i class="xi-bars xi-x2"></i></a>
                    <h1 class="pu_toggleN">㈜정P&C연구소 
                        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()" title="메뉴닫기"><i class="xi-dedent xi-x"></i></a>
                    </h1>
                    <nav>
                        <div class="pu_lnbmenu">
                            <ul>
                                <li><a href="list01"><span class="xi-devices"></span><span>㈜정P&C연구소</span></a></li>
                                <li><a href="list02"><span class="xi-lightbulb-o"></span><span>연구/사업</span></a></li>
                                <li><a href="list03"><span class="xi-chart-pie"></span><span>분석센터</span></a></li>
                                <li><a href="list04"><span class="xi-browser-text"></span><span>양돈정보(유료)</span></a></li>
                                <li><a href="list05"><span class="xi-cart-o"></span><span>JPNC Mall</span></a></li>
                                <li class="pu_on"><a href="list06"><span class="xi-pen"></span><span>홈페이지관리</span></a></li>
                            </ul>
                        </div>
                            <div class="pu_submenu">
                                    <ul class="pu_accordion-menu">
                                        <li><div class="pu_dropdownlink">배너관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">메인배너관리</a></li>
                                                <li><a href="">하단배너관리</a></li>
                                                <li><a href="">JPNC몰 배너관리</a></li>
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">팝업관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">메인팝업관리</a></li>                                           
                                            </ul>
                                        </li>
                                        <li class="pu_open"><div class="pu_dropdownlink">회원관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">가입회원</a></li>
                                                <li><a href="">휴먼회원</a></li>
                                                <li class="pu_on"><a href="">탈퇴회원</a></li>
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">관리자관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">관리자계정관리</a></li>
                                                <li><a href="">관리자접속이력</a></li>
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">메뉴관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">사용자 메뉴관리</a></li>
                                                <li><a href="">관리자 메뉴관리</a></li>
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">footer관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">회사관리</a></li>
                                                <li><a href="">이용약관</a></li>
                                                <li><a href="">개인정보취급방침</a></li>
                                            </ul>
                                        </li>
                                        </ul>
                            <!-- submenu E -->
     
                        </div>
                    </nav>
                </div>
                <!--왼쪽 메뉴 layout(e) -->

                <!-- 컨텐츠 layout (s) -->
                <div id ="main" class="pu_ContentsWrap">
                    <div class="pu_ContentsDiv">
                        <!-- top title (s) -->
                        <section class="pu_locationSection">
                            <h2>탈퇴회원</h2>
                            <div>
                                <ul class="pu_location">
                                    <li><a href="#"><i class="xi-home">홈</i></a></li>
                                    <li>홈페이지관리</li>
                                    <li>회원관리 </li>
                                    <li>탈퇴회원 </li>
                                </ul>
                                <div class="pu_logout"><a href="login.html" class="xi-unlock-o" title="로그아웃"><span>로그아웃</span></a></div>
                            </div>
                        </section>
                        <!-- top title (e) -->

                        <!-- 검색조건 (s) -->
                        <section class="pu_searchSection">
                            <div class="pu_searchBox">
                                <dl>
                                    <dt class="pu_wd90"><label for="">검색조건</label></dt>
                                    <dd>
                                        <select name="" class="pu_select-box" title="조건을선택하세요." style="width:150px">
                                            <option value="">선택하세요</option>
                                            <option value="" selected>아이디</option>
                                            <option value="">이름</option>
                                        </select>
                                        <input type="text" placeholder="아이디를 입력해주세요." style="width:300px">
                                    </dd>
                                </dl>
                                <dl>
                                    <dt class="pu_wd90"><label for="">탈퇴일자</label></dt>
                                    <dd>
                                        <div class="pu_dateSelect">
                                            <ul class="pu_date_calendar" style="width:350px">
                                                <li>
                                                    <input type="text" name="" id="" class="" value="2021-08-10">
                                                    <input type="image" src="/images/icon_calendar.png">
                                                </li>
                                                <li>~</li>
                                                <li>
                                                    <input type="text" name="" id="" class="" value="2021-12-10">
                                                    <input type="image" src="/images/icon_calendar.png">
                                                </li>
                                            </ul>
                                            <ul class="pu_date_day">
                                                <li><a href="">22년5월</a></li>
                                                <li><a href="">22년5월</a></li>
                                                <li><a href="">22년7월</a></li>
                                                <li><a href="">1개월</a></li>
                                                <li><a href="">6개월</a></li>
                                                <li><a href="">1년</a></li>
                                            </ul>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                            <div class="pu_searchBtn"><a href="#" class="xi-search"><span>검색</span></a></div>
                        </section>
                        <!-- 검색조건(e) -->

                        <!-- 컨텐츠영역 (s) -->
                        <section class="pu_contentsSection pu_pd15">
                            <!-- 테이블영역 (s)  -->
                            <div class="pu_listTable01">
                                <p class="pu_listNum"><i class="xi-user"></i> 탈퇴 회원 <strong>2</strong>명 
                                   <span class="pu_txtGrey">(회원 탈퇴 시, 아이디를 제외한 모든 개인 정보는 삭제됩니다)</span></p>
                                <div class="pu_TableX01">
                                    <table>
                                        <colgroup>
                                            <col width="6%">
                                            <col width="18%">
                                            <col span="2" width="10%">
                                            <col width="*">
                                          </colgroup>
                                        <thead>
                                          <tr>
                                            <th scope="col">번호</th>
                                            <th scope="col">아이디(이메일)</th>
                                            <th scope="col">가입일</th>
                                            <th scope="col">탈퇴일</th>
                                            <th scope="col">탈퇴사유</th>
                                          </tr>
                                        </thead>
                                          <tbody>
                                          <tr>
                                            <td>10</td>
                                            <td>hong@jpnc.co.kr</td>
                                            <td>2022-08-10 </td>
                                            <td>2022-08-15</td>
                                            <td class="pu_leftTxt">대만 내 새 감염자는 22개 현시에서 발생했으며 남성 1만2921명, 여성 1만5094명이고 13명은 확인하고 있다. 나이는 5세 미만에서 90대 사이다.</td>
                                          </tr>
                                          <tr>
                                            <td>10</td>
                                            <td>hong@jpnc.co.kr</td>
                                            <td>2022-08-10 </td>
                                            <td>2022-08-15</td>
                                            <td class="pu_leftTxt">안사요.</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                </div>
                            </div>
                            <!-- 테이블영역 (e)  -->
                            <!-- 하단 페이징영역 (s)  -->
                            <div class="pu_paging">
                                <div class="pu_pagination">
                                    <a href="" class="pu_first" title="처음페이지로 이동"><span>처음</span></a>
                                    <a href="" class="pu_prev" title="이전페이지로 이동"><span>이전</span></a>
                                    <a href="" class="pu_on">1</a>
                                    <a href="" class="">2</a>
                                    <a href="" class="">3</a>
                                    <a href="" class="">4</a>
                                    <a href="" class="">5</a>
                                    <a href="" class="">6</a>
                                    <a href="" class="">7</a>
                                    <a href="" class="">8</a>
                                    <a href="" class="">9</a>
                                    <a href="" class="">10</a>
                                    <a href="" class="pu_next" title="다음페이지로 이동"><span>다음</span></a>
                                    <a href="" class="pu_last" title="마지막페이지로 이동"><span>마지막</span></a>
                                  </div>
                            </div>
                            <!-- 하단 페이징영역 (d)  -->
                            
                        </section>
                        <!-- 컨텐츠영역 (e) -->

                    </div>
                    <div class="pu_pb20"></div>
                </div>
                <!-- 컨텐츠 layout  (e) -->
        </div>

    </div>
</template>
<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGIFORM"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

                , id: "" // 아이디
                , pw: "" // 비밀번호

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // enter 키 이벤트
                this.$SetEventEnter("id", function () { $("[name='pw']").focus(); });
                this.$SetEventEnter("pw", this.saveData);

                // dimm 숨기기
                this.$HideDimmLogo();

            }

            // 저장
            , saveData () {

                // 비밀번호
                var id = $("[name='id']");
                if (!id.val().trim()) {
                    alert("아이디를 입력해주세요");
                    id.focus();
                    return false;
                }

                // 비밀번호
                var pw = $("[name='pw']");
                if (!pw.val().trim()) {
                    alert("비밀번호를 입력해주세요");
                    pw.focus();
                    return false;
                }
                
                var qs = this.$GetQueryMap();
                var data = this.$FormToMap($("#regiForm"));
                data["gubun_page"] = this.gubun_page;

                console.log(pw);

                // API 조회
                this.$SendPost("/vue_api/zadmin/login", data, this.saveEventReturn);

            }

            // 저장 데이터
            , saveEventReturn (data, err) {

            }

        }

        , beforeCreate() {

        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>