<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <inc_zadmin_head />
            <inc_zadmin_left />

            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">

                    <inc_zadmin_title />

                    <!-- 검색조건 (s) -->
                    <section class="pu_searchSection">
                        <form id="searchForm" class="pu_searchBox" onsubmit="return false"></form>
                        <!--<div class="pu_searchBtn"><a href="javascript:" class="dev_btn_search xi-search"><span>검색</span></a></div>-->
                    </section>
                    <!-- 검색조건(e) -->

                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">

                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <p class="pu_listNum">
                                <i class="xi-paper-o"></i> 총 <strong>{{ rownum }}</strong>개의 시험문제가 있습니다.
                            </p>
                            <div class="pu_TableX01">
                                <table id="dev_exam_from">
                                    <colgroup>
                                        <col width="90px" />
                                        <col width="*%" />
                                        <col width="*%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">질문 내용</th>
                                            <th scope="col">해설 내용</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row, idx) in list_1">
                                            <td>
                                                <a class="pu_btn_dark" href="javascript:;" v-on:click="publExam(row.NO)" style="margin-top: 5px; white-space: nowrap;"><i class="xi-check"></i> 문제 발급</a>
                                            </td>
                                            <td>
                                                <div v-html="$ConHtml(row.Q)"></div>
                                            </td>
                                            <td>
                                                <div v-html="$ConHtml(row.A)"></div>
                                            </td>
                                        </tr>
                                        <tr v-if="list_1.length == 0">
                                            <td colspan="3">조회된 결과가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->

                        <div class="pu_sticky_bottom">
                            <!-- 버튼 스타일영역 (s)  -->
                            <div class="pu_btnDiv01 pu_over_hidden">

                                <article class="pu_f_left">
                                    <!--페이징-->
                                    <div class="pu_pagination" v-html="paging"></div>
                                    <!--//페이징-->
                                </article>

                                <article class="pu_f_right">
                                </article>
                            </div>
                            <!-- 버튼 스타일영역 (s)  -->
                        </div>

                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "LIST"

                , rownum: 0
                , paging: ""      // 페이징

                , list_1: []
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // 검색
                this.getSearchData(1);
            }

            // 검색
            , getSearchData: function (curPage) {

                // qs
                var qs = this.$GetQueryMap();

                // 폼
                var data = this.$FormToMap($("#searchForm"));
                data["gubun_page"] = this.gubun_page;
                data["curPage"] = curPage;

                // API 조회
                this.$SendPost("/vue_api/zadmin/ngd_edit_comp/list", data, this.searchDataReturn);

            }
            , searchDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.rownum = data.ROWNUM;
                        this.paging = data.PAGING;
                        this.list_1 = data.LIST_1;
                        
                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }

            // 문제 발급
            , publExam: function (no) {

                if (confirm("발급 처리하시겠습니까?")) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    // 폼
                    var data = {};
                    data["no"] = no;

                    // API 조회
                    this.$SendPost("/vue_api/zadmin/ngd_edit_comp/publ_exam", data, this.publExamReturn);
                }
            }
            , publExamReturn: function (data, err) {

                // 검색
                this.getSearchData(1);
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>