<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">
            <!-- 왼쪽 메뉴 layout(s) -->
                <div id="AdminNav" class="pu_LnbWrap">
                    <a  class="pu_openbtn" style="display: none;" onclick="openNav()" title="메뉴펼쳐보기"><i class="xi-bars xi-x2"></i></a>
                    <h1 class="pu_toggleN">㈜정P&C연구소 
                        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()" title="메뉴닫기"><i class="xi-dedent xi-x"></i></a>
                    </h1>
                    <nav>
                        <div class="pu_lnbmenu">
                            <ul>
                                <li class="pu_on"><a href="list01"><span class="xi-devices"></span><span>㈜정P&C연구소</span></a></li>
                                <li><a href="list02"><span class="xi-lightbulb-o"></span><span>연구/사업</span></a></li>
                                <li><a href="list03"><span class="xi-chart-pie"></span><span>분석센터</span></a></li>
                                <li><a href="list04"><span class="xi-browser-text"></span><span>양돈정보(유료)</span></a></li>
                                <li><a href="list05"><span class="xi-cart-o"></span><span>JPNC Mall</span></a></li>
                                <li><a href="list06"><span class="xi-pen"></span><span>홈페이지관리</span></a></li>
                            </ul>
                        </div>
                            <div class="pu_submenu">
                                    <ul class="pu_accordion-menu">
                                        <li class="pu_open"><div class="pu_dropdownlink">㈜정P&C연구소<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">회사소개</a></li>
                                                <li><a href="">주요사업 및 상품</a></li>
                                                <li><a href="">연구수행 및 과제</a></li>
                                                <li><a href="">주소 / 연락처</a></li>
                                                <li class="pu_on"><a href="">공지사항</a></li>
                                            </ul>
                                        </li>
                                        </ul>
                            <!-- submenu E -->
     
                        </div>
                    </nav>
                </div>
                <!--왼쪽 메뉴 layout(e) -->

                <!-- 컨텐츠 layout (s) -->
                <div id ="main" class="pu_ContentsWrap">
                    <div class="pu_ContentsDiv">
                        <!-- top title (s) -->
                        <section class="pu_locationSection">
                            <h2>공지사항</h2>
                            <div>
                                <ul class="pu_location">
                                    <li><a href="#"><i class="xi-home">홈</i></a></li>
                                    <li>㈜정P&C연구소 </li>
                                    <li>공지사항 </li>
                                </ul>
                                <div class="pu_logout"><a href="login.html" class="xi-unlock-o" title="로그아웃"><span>로그아웃</span></a></div>
                            </div>
                        </section>
                        <!-- top title (e) -->

                        

                        <!-- 컨텐츠영역 (s) -->
                        <section class="pu_contentsSection pu_pd15">
                            <!-- 테이블영역 (s)  -->
                            <div class="pu_listTable01">
                               
                                <div class="pu_TableY01">
                                    <table>
                                        <colgroup>
                                          <col width="15%">
                                          <col width="35%">
                                          <col width="15%">
                                          <col width="35%">
                                        </colgroup>
                                        <tbody>
                                          <tr>
                                            <th>제목</th>
                                            <td colspan="3">폭염에 전국 해수욕장·물놀이장 북적..마스크 벗고 '풍덩</td>
                                          </tr>
                                          <tr>
                                            <th>작성자</th>
                                            <td>관리자</td>
                                            <th>작성자ID</th>
                                            <td>admin_company</td>
                                          </tr>
                                          <tr>
                                            <th>조회수</th>
                                            <td>40</td>
                                            <th>작성일자</th>
                                            <td>22-08-21</td>
                                          </tr>
                                          <tr>
                                            <th>내용</th>
                                            <td colspan="3">
                                               <div>
                                                바다 한편은 서핑 물결..산·공원서 걷고 뛰며 이열치열도
                                                더위에는 바다가 최고…붐비는 속초해수욕장 (속초=연합뉴스) 이종건 기자 = 10일 개장 이틀째를 맞은 강원도 속초해수욕장을 찾은 피서객들이 더위를 식히고 있다.
                                                    2022.7.10 momo@yna.co.kr
                                                
                                                더위에는 바다가 최고…붐비는 속초해수욕장 (속초=연합뉴스) 이종건 기자 = 10일 개장 이틀째를 맞은 강원도 속초해수욕장을 찾은 피서객들이 더위를 식히고 있다. 2022.7.10 momo@yna.co.kr
                                                (전국종합=연합뉴스) 불볕더위가 기승을 부린 10일 전국 해수욕장과 물놀이장은 도심을 탈출한 행락객들의 발길이 끊임없이 이어졌다.
                                                
                                                부산 해운대 등 주요 해수욕장에는 나들이 인파가 몰려 시원한 바다에 몸을 담근 채 휴일을 즐겼다.
                                                
                                                서핑으로 명성을 얻고 있는 송정해수욕장과 다대포해수욕장에서는 더위도 잊은 채 서핑 연습에 열중하는 서핑 애호가들 모습이 눈에 띄었다.
                                                
                                                강원 동해안 해수욕장은 흐린 날씨에도 불구하고 피서객으로 인산인해를 이뤘다.
                                                
                                                마스크 의무 착용에서 해방된 피서객들은 마스크를 벗어 던지고 바닷물에 거침없이 몸을 던졌다.
                                                
                                                서핑 메카가 된 양양지역 해수욕장에서는 형형색색의 서프보드가 물결을 이뤘다.
                                                
                                                인천 영종도 을왕리·왕산해수욕장에서는 가족이나 연인 단위 방문객 수천 명이 몰려 수영을 하거나 바닷물에 발을 담그며 무더위를 식혔다.
                                               </div>
                                         </td>
                                          </tr>
                                          <tr>
                                            <th>첨부파일</th>
                                            <td colspan="3">
                                               <a href="" class="pu_attachFile">첨부파일명_20220710.hwp</a>
                                         </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                </div>
                            </div>
                            <!-- 테이블영역 (e)  -->
                            <!-- 버튼 스타일영역 (s)  -->
                            <div class="pu_btnDiv01 pu_over_hidden">
                                <article class="pu_f_left">
                                    <a href="" class="pu_btn_red"><i class="xi-trash"></i> 삭제</a>
                                </article>
                                <article class="pu_f_right">
                                    <a href="modify" class="pu_btn_blue"><i class="xi-eraser"></i> 수정</a>
                                    <a href="" class="pu_btn_dark"><i class="xi-bars"></i> 목록</a>
                                </article>
                            </div>
                            <!-- 버튼 스타일영역 (s)  -->
                        </section>
                        <!-- 컨텐츠영역 (e) -->

                    </div>
                    <div class="pu_pb20"></div>
                </div>
                <!-- 컨텐츠 layout  (e) -->
        </div>

    </div>
</template>
<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGIFORM"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

                , id: "" // 아이디
                , pw: "" // 비밀번호

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // enter 키 이벤트
                this.$SetEventEnter("id", function () { $("[name='pw']").focus(); });
                this.$SetEventEnter("pw", this.saveData);

                // dimm 숨기기
                this.$HideDimmLogo();

            }

            // 저장
            , saveData () {

                // 비밀번호
                var id = $("[name='id']");
                if (!id.val().trim()) {
                    alert("아이디를 입력해주세요");
                    id.focus();
                    return false;
                }

                // 비밀번호
                var pw = $("[name='pw']");
                if (!pw.val().trim()) {
                    alert("비밀번호를 입력해주세요");
                    pw.focus();
                    return false;
                }
                
                var qs = this.$GetQueryMap();
                var data = this.$FormToMap($("#regiForm"));
                data["gubun_page"] = this.gubun_page;

                console.log(pw);

                // API 조회
                this.$SendPost("/vue_api/zadmin/login", data, this.saveEventReturn);

            }

            // 저장 데이터
            , saveEventReturn (data, err) {

            }

        }

        , beforeCreate() {

        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>