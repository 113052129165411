<template>

    <div class="pu_loginWapper">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_zadmin_head />
        <!-------------------------- 인클루드(s) -------------------------->

        <section class="pu_loginBox">
            <!-- 로그인 박스S -->
            <form id="regiForm" action="">
                <div class="pu_login_div">
                    <div class="pu_loginL">
                        <div></div>
                    </div>
                    <div class="pu_loginR">                        
                        <h1 style="padding:1em; font-size:2em;">하이퍼 출제기</h1>
                        <p class="pu_txt">로그인 후 사용할 수 있습니다.</p>
                        <div>
                            <input name="id" type="text" placeholder="아이디 입력" maxlength="15" autocomplete="off" />
                        </div>
                        <div>
                            <input name="pw" type="password" placeholder="비밀번호 입력" maxlength="15" autocomplete="off" />
                        </div>
                        <div class="pu_LoginBtn" v-on:click="exec('LOGIN');">
                            <a><i class="xi-lock-o xi-x"></i> 로그인</a>
                        </div>
                    </div>
                </div>
            </form>
            <!-- 로그인 박스e -->

            <address>
                <p>COPYRIGHT ©하이퍼노마드. All rights reserved.</p>
            </address>
        </section>
    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGIFORM"

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                // 시작
                _this.exec("INIT");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };
                let isSend = true;

                switch (gubun) {

                    case "INIT":
                        {
                            isSend = false;

                            // enter 키 이벤트
                            this.$SetEventEnter("id", function () { $("[name='pw']").focus(); });
                            this.$SetEventEnter("pw", this.saveData);
                        }
                        break;

                    case "LOGIN":
                        {
                            // URL 설정
                            url = "/vue_api/zadmin/login";

                            // 비밀번호
                            var id = $("[name='id']");
                            if (!id.val().trim()) {
                                alert("아이디를 입력해주세요");
                                id.focus();
                                return false;
                            }

                            // 비밀번호
                            var pw = $("[name='pw']");
                            if (!pw.val().trim()) {
                                alert("비밀번호를 입력해주세요");
                                pw.focus();
                                return false;
                            }

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#regiForm")));
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                if (isSend) {

                    // dimm 보이기
                    _this.$ShowDimmLogo();

                    _this.$SendPost(url, data, _this.execReturn);
                }
                else {
                    // dimm 숨기기
                    _this.$HideDimmLogo();
                }
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "INIT":
                        {

                        }
                        break;

                    case "LOGIN":
                        {
                            if (data.VIEW_1.RST_YN == "Y") {
                                _this.$router.push(data.VIEW_1.URL);
                            }
                            else {
                                alert(data.VIEW_1.ALERT);
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {

        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>