<template>
    <!-- 왼쪽 메뉴 layout(s) -->
    <div id="AdminNav" class="pu_LnbWrap">

        <a class="pu_openbtn" style="display: none;" onclick="openNav()" title="메뉴펼쳐보기">
            <i class="xi-bars xi-x2"></i>
        </a>

        <h1 class="pu_toggleN">
            하이퍼 출제기
            <!--
            <a href="javascript:void(0)" class="closebtn" onclick="closeNav()"><i class="xi-dedent xi-x"></i></a>
            -->
        </h1>

        <nav>
            <div class="pu_lnbmenu">                
                <ul>
                    <li v-for="(d1, idx_d1) in getBase.arrAdminMenu_1d" v-bind:class="d1.ACTIVE"
                        v-bind:key="idx_d1">
                        <a href="javascript:;" v-on:click="$PageMove(d1.URL)">
                            <span></span>
                            <span v-html="d1.TITLE"></span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="pu_submenu">
                <ul class="pu_accordion-menu">
                    <li v-for="(d2, idx_d2) in getBase.arrAdminMenu_2d" v-bind:class="d2.ACTIVE"
                        v-bind:key="idx_d2">
                        <div class="pu_dropdownlink" v-html="d2.TITLE"></div>
                        <ul class="pu_submenuItems">
                            <li v-for="(d3, idx_d3) in getMenu3d(d2)" v-bind:class="d3.ACTIVE"
                                v-bind:key="idx_d3">
                                <a href="javascript:;" v-on:click="$PageMove(d3.URL)" v-html="d3.TITLE"></a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
    <!--왼쪽 메뉴 layout(e) -->
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {
        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                THIS_NAME: "INC ZADMIN LEFT",

                mapArrAdminMenu_3d: {} // 3뎁스 메뉴

            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // url code
                this.getMenu1d();
            }

            // 1뎁스 메뉴
            , getMenu1d() {

                // 데이터
                var data = {};
                data["url"] = this.getBase.pathCur; // 현재 url 경로
                data["qs"] = this.getBase.qsCur;    // 현재 url 쿼리스트링
                data["menu_code"] = this.getBase.adminMenuCode; // 기존 메뉴 코드

                //this.$PrintLog("1D 요청 : ");
                //this.$PrintLog(data);

                // API 조회
                this.$SendPost("/vue_api/common/menu/zadmin_1d", data, this.getMenu1dReturn);
            }

            // 1뎁스 메뉴 리턴
            , getMenu1dReturn(data, err) {

                if (data) {

                    this.setBase({ "adminLoginYn": data.ADMIN_LOGIN_YN });       // 로그인 여부
                    this.setBase({ "adminNo": data.ADMIN_NO });                  // 로그인 번호
                    
                    if (data.MENU_NAME_1D || this.getBase.arrAdminMenu_1d.length < 1) {
                        this.setBase({ "adminMenuCode": data.MENU_CODE });       // 메뉴코드
                        this.setBase({ "adminMenuName_1d": data.MENU_NAME_1D }); // 1뎁스 메뉴명
                        this.setBase({ "arrAdminMenu_1d": data.LIST_1 });        // 1뎁스 메뉴
                    }

                    // this.$PrintLog("1D 수신 : ");
                    // this.$PrintLog(data);
                    // this.$PrintLog(this.getBase.arrAdminMenu_1d);

                    // 2뎁스 메뉴
                    this.getMenu2d();
                }
            }

            // 2뎁스 메뉴
            , getMenu2d() {

                // 데이터
                var data = {};
                data["url"] = this.getBase.pathCur; // 현재 url 경로
                data["qs"] = this.getBase.qsCur;    // 현재 url 쿼리스트링
                data["menu_code"] = this.getBase.adminMenuCode; // 기존 메뉴 코드

                //this.$PrintLog("2D 요청 : ");
                //this.$PrintLog(data);

                // API 조회
                this.$SendPost("/vue_api/common/menu/zadmin_2d", data, this.getMenu2dReturn);
            }

            // 2뎁스 메뉴 리턴
            , getMenu2dReturn(data, err) {

                if (data) {

                    if (data.MENU_NAME_2D || this.getBase.arrAdminMenu_2d.length < 1) {                        
                        this.setBase({ "adminMenuName_2d": data.MENU_NAME_2D }); // 2뎁스 메뉴명
                        this.setBase({ "adminMenuName_3d": data.MENU_NAME_3D }); // 3뎁스 메뉴명
                        this.setBase({ "arrAdminMenu_2d": data.LIST_1 });        // 2뎁스 메뉴
                    }

                    this.mapArrAdminMenu_3d = data.MAP_LIST_1;                   // 3뎁스 메뉴

                    //this.$PrintLog("2D 수신 : ");
                    //this.$PrintLog(data);
                    //this.$PrintLog(this.getBase.arrAdminMenu_2d);
                    //this.$PrintLog(this.mapArrAdminMenu_3d);
                }
            }

            // 3뎁스 리턴
            , getMenu3d(d2) {

                //this.$PrintLog(d2.CODE);
                return this.mapArrAdminMenu_3d[d2.CODE] || []
            }
        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
            this.init();
        },

        mounted() {
            this.$PrintLog(this.THIS_NAME + " : mounted");
        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
