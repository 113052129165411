<template>

    <div class="pu_wd_def_min pu_wrapper_main">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(s) -------------------------->

        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_banner_top />
            <inc_user_left />
            <inc_user_title />
            <!-------------------------- 인클루드(s) -------------------------->

            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">

                <!-- 검색 (s) -->
                <div class="pu_listSearch">
                    <div class="pu_search01">
                        <legend>검색</legend>
                        <select name="" class="" title="검색어 분류를 선택하세요.">
                            <option value="">제목+내용</option>
                            <option value="" select="select">제목</option>
                            <option value="">내용</option>
                            <option value="">작성자</option>
                        </select>
                        <div class=" pu_input_wrap">
                            <label class="pu_blind" for="search">검색어</label>
                            <input type="text" name="search" id="search" value="" placeholder="검색어를 입력하세요"
                                   title="검색어를 입력하세요" />
                            <a class="pu_btnSerch01" href="javascript:;">검색</a>
                        </div>
                    </div>
                    <div class="pu_totalTxt">총 <span>26</span>page, <span>266</span>개 등록</div>
                </div>
                <!-- 검색 (e) -->
                <!-- 리스트 (s) -->
                <div>
                    <table class="pu_table_list">
                        <colgroup>
                            <col style="width:7%;">
                            <col style="width:61%;">
                            <col style="width:15%;">
                            <col style="width:10%;">
                            <col style="width:7%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">번호</th>
                                <th scope="col" class="list">제목</th>
                                <th scope="col">작성자</th>
                                <th scope="col">작성일</th>
                                <th scope="col">조회</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>20</td>
                                <td class="list">
                                    <a href="news_view.html">
                                        <span class="pu_notice">알림</span>종개협 등록
                                        칡소·제주흑우, 한우로 인정
                                    </a>
                                </td>
                                <td>관리자</td>
                                <td>2022-08-14</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list">
                                    <a href="news_view.html">
                                        <span class="pu_re">RE</span> 참치·연어 통조림
                                        GMO표시 전무…허술한 표시제도
                                    </a>
                                </td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list"><a href="news_view.html">지방 줄인 '슈퍼돼지'가 탄생하다 </a></td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list">유전자로 참깨 원산지 판별하는 기술 개발 </td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list"><a href="news_view.html">참치·연어 통조림 GMO표시 전무…허술한 표시제도 </a></td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list"><a href="news_view.html">지방 줄인 '슈퍼돼지'가 탄생하다 </a></td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list">유전자로 참깨 원산지 판별하는 기술 개발 </td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 리스트 (e) -->
                <!-- 페이징 (s) -->
                <div class="pu_pagination_2">
                    <a href="javascript:;"><i class="xi-angle-left"></i></a>
                    <a href="javascript:;">1</a>
                    <a href="javascript:;" class="active">2</a>
                    <a href="javascript:;">3</a>
                    <a href="javascript:;">4</a>
                    <a href="javascript:;">5</a>
                    <a href="javascript:;">6</a>
                    <a href="javascript:;">7</a>
                    <a href="javascript:;">8</a>
                    <a href="javascript:;">9</a>
                    <a href="javascript:;">10</a>
                    <a href="javascript:;"><i class="xi-angle-right"></i></a>
                </div>
                <!-- 페이징 (e) -->

            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 서브 실제 컨텐츠 영역 (e) -------------------------->

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                _this.exec("INIT");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "gubunProc": gubun };

                switch (gubun) {

                    case "INIT":
                        {
                            //url = "/vue_api/user/login/login";
                        }
                        break;

                    case "ALERT":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                console.log(data);

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "INIT":
                        {

                        }
                        break;

                    case "ALERT":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }
    }

</script>