<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <inc_zadmin_head />
            <inc_zadmin_left />

            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">

                    <inc_zadmin_title />

                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <div class="pu_TableY01">

                                <form id="regiForm" onsubmit="return false">

                                    <!--전달값-->
                                    <input type="hidden" name="no" v-model="view_1.NO" />
                                    <!--//전달값-->

                                    <table>
                                        <colgroup>
                                            <col width="20%">
                                            <col width="80%">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>제목*</th>
                                                <td>
                                                    <input type="text" name="title" v-model:value="view_1.TITLE" style="width:100%">
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>바닥글*</th>
                                                <td>
                                                    <textarea name="contents" class="pu_input_textarea" v-html="view_1.CONTENTS"></textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->

                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_right">
                                <a class="pu_btn_dark" href="javascript:;" v-on:click="$PageMove('./list')"><i class="xi-bars"></i> 목록</a>
                                <a class="pu_btn_blue" href="javascript:;" v-on:click="saveData()"><i class="xi-check"></i> 저장</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->

                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {}

        , data() {

            return {
                gubun_page: "REGI"

                , cnt_lim_pic: 5
                , view_1: {}
            }
        }

        , methods: {

            ...mapActions({
                getBase: "getBase"
            })

            // 진입점
            , init() {

                this.getSearchData(1);
            }

            // 데이터 가져오기
            , getSearchData: function (curPage) {

                // qs
                var qs = this.$GetQueryMap();

                // 폼
                var data = {};
                data["gubun_page"] = this.gubun_page;
                data["no"] = qs.no;
                
                // API 조회
                this.$SendPost("/vue_api/zadmin/my_foot/view", data, this.getSearchDataReturn);
            }

            , getSearchDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1; // 저장된 데이터

                        // dimm 숨기기
                        this.$HideDimmLogo();

                        // ck에디터 설정
                        this.setCkEditor();
                    }
                }
            }

            // 저장
            , saveData() {

                var title = $("[name='title']");
                if (!title.val().trim()) {
                    alert("제목을 입력해주세요");
                    title.focus();
                    return false;
                }

                // 내용
                CKEDITOR.instances.contents.updateElement();

                var contents = $("[name='contents']");
                if (!contents.val().trim()) {
                    alert("바닥글을 입력해주세요");
                    contents.focus();
                    return false;
                }

                if (confirm(INFO_CONF_SAVE)) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    var data = this.$FormToMap($('#regiForm'));
                    data["gubun_page"] = this.gubun_page;

                    this.$SendPost("/vue_api/zadmin/my_foot/save", data, this.saveEventReturn);
                }
            }

            // 저장 데이터
            , saveEventReturn(data, err) {

                // dimm 숨기기
                this.$HideDimmLogo();
            }

            // ck editor 세팅
            , setCkEditor: function () {

                setTimeout(() => {

                    try {
                        setCkEditorDefault("contents", "my_foot", this.cnt_lim_pic, ".jpeg,.jpg,.png");
                    } catch (err) {
                        this.setCkEditor();
                    }
                }, 100);
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>