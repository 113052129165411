<template>

    <!-- 서브 메뉴 (s) -->
    <div>

        <div class="pu_locationWrap">
            <div class="pu_wd_def_max pu_locationMenu">
                <a class="pu_home" href="javascript:;"><i class="xi-home-o"></i></a>
                <ul class="pu_lnbList">
                    <li>
                        <div class="pu_select_wrap">
                            <a class="pu_txtwrap"><span>{{ getBase.userMenuName_1d }}</span> <i class="xi-angle-down-min"></i></a>
                            <div class="pu_select_list">
                                <ul class="pu_select_menu">

                                    <li v-for="(d1, idx_d1) in getBase.mapUserMenu['000|000|000|']"
                                        v-bind:key="idx_d1">

                                        <a href="javascript:;"
                                           v-on:click="$PageMove(d1.URL)"
                                           v-html="d1.TITLE"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="pu_select_wrap">
                            <a class="pu_txtwrap"><span>{{ getBase.userMenuName_2d }}</span><i class="xi-angle-down-min"></i></a>
                            <div class="pu_select_list">
                                <ul class="pu_select_menu">

                                    <li v-for="(d2, idx_d2) in getBase.mapUserMenu[getBase.userMenuCode_1d.substring(0, 4) + '000|000|']"
                                        v-bind:key="idx_d2">

                                        <a href="javascript:;"
                                           v-on:click="$PageMove(d2.URL)"
                                           v-html="d2.TITLE"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="pu_select_wrap">
                            <a class="pu_txtwrap"><span>{{ getBase.userMenuName_3d }}</span><i class="xi-angle-down-min"></i></a>
                            <div class="pu_select_list">
                                <ul class="pu_select_menu">

                                    <li v-for="(d3, idx_d3) in getBase.mapUserMenu[getBase.userMenuCode_2d.substring(0, 8) + '000|']"
                                        v-bind:key="idx_d3">

                                        <a href="javascript:;"
                                           v-on:click="$PageMove(d3.URL)"
                                           v-html="d3.TITLE"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="pu_locationWrapLeft">
            <ul>
                <li>
                    <a>
                        <i class="xi-library-books-o"></i>
                        <span>뉴스속보</span>
                    </a>
                    <ul>
                        <li>
                            <a class="pu_on" href="javascript:;">
                                <i class="xi-angle-right-min"></i>
                                <span>국내뉴스</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                <i class="xi-angle-right-min"></i>
                                <span>해외뉴스</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li>
                    <a>
                        <i class="xi-library-books-o"></i>
                        <span>양돈 컨설팅</span>
                    </a>
                    <ul>
                        <li>
                            <a href="javascript:;">
                                <i class="xi-angle-right-min"></i>
                                <span>컨설팅 분야</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                <i class="xi-angle-right-min"></i>
                                <span>컨설팅 실적</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                <i class="xi-angle-right-min"></i>
                                <span>농업경영 컨설팅</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                <i class="xi-angle-right-min"></i>
                                <span>HACCP 컨설팅</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li>
                    <a>
                        <i class="xi-library-books-o"></i>
                        <span>종돈사업</span>
                    </a>
                    <ul>
                        <li>
                            <a href="javascript:;">
                                <i class="xi-angle-right-min"></i>
                                <span>종돈 수출입 소개</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                <i class="xi-angle-right-min"></i>
                                <span>해외 종돈장 소개</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;">
                                <i class="xi-angle-right-min"></i>
                                <span>육종기술정보</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

    </div>
    <!-- 서브 메뉴 (e) -->

</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        },

        data() {
            return {
                THIS_NAME: "INC USER LEFT"
            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

            }
        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
            this.init();
        },

        mounted() {
            this.$PrintLog(this.THIS_NAME + " : mounted");
        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
