<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <inc_zadmin_head />
            <inc_zadmin_left />

            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">

                    <inc_zadmin_title />

                    <!-- 검색조건 (s) -->
                    <section class="pu_searchSection">
                        <form id="searchForm" class="pu_searchBox" onsubmit="return false">
                            <dl>
                                <dt class="pu_wd90"><label for="">교육과정</label></dt>
                                <dd>
                                    <select class="pu_select-box" name="code_subject_1" v-model="code_subject_depth_1"
                                            v-on:change="getCodeSubject(2, '', $event)" title="과목을 선택하세요." style="width:200px">
                                        <option value="">- 과목을 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_subject_2" v-model="code_subject_depth_2"
                                            v-on:change="getCodeSubject(3, '', $event)" title="개정년도를 선택하세요." style="width:200px">
                                        <option value="">- 개정년도를 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_subject_3" v-model="code_subject_depth_3"
                                            v-on:change="getCodeSubject(4, '', $event)" title="교육과정을 선택하세요." style="width:200px">
                                        <option value="">- 교육과정을 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_3" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_subject_4" v-model="code_subject_depth_4"
                                            v-on:change="getCodeSubject(5, '', $event)" title="대단원을 선택하세요." style="width:200px">
                                        <option value="">- 대단원을 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_4" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_subject_5" v-model="code_subject_depth_5"
                                            v-on:change="getCodeSubject(6, '', $event)" title="중단원을 선택하세요." style="width:200px">
                                        <option value="">- 중단원을 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_5" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>
                                </dd>
                            </dl>

                            <dl>
                                <dt class="pu_wd90"><label for="">학교</label></dt>
                                <dd>
                                    <select class="pu_select-box" name="code_area_1" v-model="code_area_depth_1"
                                            v-on:change="getCodeArea(2, '', $event)" title="시도를 선택하세요." style="width:200px">
                                        <option value="">- 시도를 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_area_depth_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_area_2" v-model="code_area_depth_2"
                                            v-on:change="getCodeArea(3, '', $event)" title="구군을 선택하세요." style="width:200px">
                                        <option value="">- 구군을 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_area_depth_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_area_3" v-model="code_area_depth_3"
                                            v-on:change="getCodeArea(4, '', $event)" title="초중고를 선택하세요." style="width:200px">
                                        <option value="">- 초중고를 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_area_depth_3" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_area_4" v-model="code_area_depth_4"
                                            v-on:change="getCodeArea(5, '', $event)" title="학교명을 선택하세요." style="width:200px">
                                        <option value="">- 학교명을 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_area_depth_4" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>
                                </dd>
                            </dl>

                            <dl>
                                <dt class="pu_wd90"><label for="">타입</label></dt>
                                <dd>
                                    <select name="gubun_exam_type" class="dev_option pu_select-box"
                                            title="타입을 선택하세요." style="width:200px">
                                        <option value="">- 타입을 선택하세요</option>
                                        <option value="A">선택</option>
                                        <option value="B">서술</option>
                                    </select>
                                </dd>

                                <dt class="pu_wd90"><label for="" style="margin-left: 20px;">난이도</label></dt>
                                <dd>
                                    <select name="gubun_exam_diff" class="dev_option pu_select-box"
                                            title="난이도를 선택하세요." style="width:200px">
                                        <option value="">- 난이도를 선택하세요</option>
                                        <option value="1">하</option>
                                        <option value="2">중</option>
                                        <option value="3">상</option>
                                        <option value="4">킬러</option>
                                    </select>
                                </dd>
                                <dt class="pu_wd90"><label for="" style="margin-left: 20px;">년도</label></dt>
                                <dd>
                                    <select name="school_year" class="dev_option pu_select-box"
                                            title="년도를 선택하세요." style="width:200px">
                                        <option value="">- 년도를 선택하세요</option>
                                        <option v-for="(row, idx) in opt_school_year" v-bind:value="row.YEAR">{{ row.YEAR }}</option>
                                    </select>
                                </dd>

                                <dt class="pu_wd90"><label for="" style="margin-left: 20px;">학기</label></dt>
                                <dd>
                                    <select name="school_semester" class="dev_option pu_select-box"
                                            title="학기를 선택하세요." style="width:200px">
                                        <option value="">- 학기를 선택하세요</option>
                                        <option v-for="(row, idx) in opt_school_semester" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>
                                </dd>
                            </dl>

                            <dl>
                                <dt class="pu_wd90"><label for="" style="width:100px;display:block;">수식</label></dt>
                                <dd>
                                    <a href="javascript:;" v-on:click="getLatex()" class="pu_ss_btn_grey pu_minw55" style="width: 80px;">수식 추가</a>
                                </dd>
                                <dd style="width:100%">

                                    <!--라텍스 검색-->
                                    <ul class="dev_search_latex_ul"></ul>
                                    <!--//라텍스 검색-->

                                </dd>
                            </dl>

                            <dl>
                                <dt class="pu_wd90"><label for="">검색조건</label></dt>
                                <dd>
                                    <select name="search_gubun" class="pu_select-box" title="검색조건을 선택하세요." style="width:150px">
                                        <option value="cont_q" selected="selected">질문 내용</option>
                                    </select>
                                    <input type="text" name="search_text" placeholder="검색어를 입력해주세요." style="width:500px">
                                </dd>
                            </dl>
                        </form>
                        <div class="pu_searchBtn"><a href="javascript:" class="dev_btn_search xi-search"><span>검색</span></a></div>
                    </section>
                    <!-- 검색조건(e) -->

                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <p class="pu_listNum">
                                <i class="xi-paper-o"></i> 총 <strong>{{ rownum }}</strong>개의 게시물이 있습니다.
                            </p>
                            <div class="pu_TableX01">
                                <table>
                                    <colgroup>
                                        <col width="70px" />
                                        <col width="*" />
                                        <col width="120px" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">번호</th>
                                            <th scope="col">내용</th>
                                            <th scope="col">등록일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="dev_page_move_regi"
                                            v-for="(row, idx) in list_1">
                                            <td>
                                                <input type="hidden" class="dev_no" :value="row.NO" />
                                                {{ getRowNum(idx) }}
                                            </td>
                                            <td>
                                                <div class="pu_TableW01">
                                                    <table>
                                                        <colgroup>
                                                            <col width="10%" />
                                                            <col width="40%" />
                                                            <col width="10%" />
                                                            <col width="40%" />
                                                        </colgroup>
                                                        <tbody>
                                                            <tr>
                                                                <th>교육과정</th>
                                                                <td class="pu_leftTxt">{{ row.CODE_SUBJECT_NAME }}</td>
                                                                <th>타입 / 난이도</th>
                                                                <td class="pu_leftTxt">{{ row.GUBUN_EXAM_TYPE_NAME }} / {{ row.GUBUN_EXAM_DIFF_NAME }}</td>
                                                            </tr>
                                                            <tr v-if="row.CODE_AREA_NAME">
                                                                <th>학교</th>
                                                                <td class="pu_leftTxt">{{ row.CODE_AREA_NAME }}</td>
                                                                <th>년도 / 학기명</th>
                                                                <td class="pu_leftTxt">{{ row.SCHOOL_YEAR }} / {{ row.SCHOOL_SEMESTER_NAME }}</td>
                                                            </tr>
                                                            <tr>
                                                                <th colspan="2">질문 내용</th>
                                                                <th colspan="2">해설 내용</th>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2"><div v-html="$ConHtml(row.CONT_Q)"></div></td>
                                                                <td colspan="2"><div v-html="$ConHtml(row.CONT_A)"></div></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                            <td>
                                                {{ row.REGI_DATE }}
                                            </td>
                                        </tr>
                                        <tr v-if="list_1.length == 0">
                                            <td colspan="3">조회된 결과가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->

                        <div class="pu_sticky_bottom">
                            <!-- 버튼 스타일영역 (s)  -->
                            <div class="pu_btnDiv01 pu_over_hidden">

                                <article class="pu_f_left">
                                    <!--페이징-->
                                    <div class="pu_pagination" v-html="paging"></div>
                                    <!--//페이징-->
                                </article>

                                <article class="pu_f_right">
                                    <a class="pu_btn_blue" href="javascript:;" v-on:click="$PageMove('./regi')"><i class="xi-pen"></i> 신규등록</a>
                                </article>
                            </div>
                            <!-- 버튼 스타일영역 (s)  -->
                        </div>

                    </section>
                    <!-- 컨텐츠영역 (e) -->

                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {}

        , data() {

            return {
                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , isOkSearch: false // 검색가능
                , list_1: []

                , code_subject: "" // 검색 코드
                , code_area: ""    // 검색 코드

                , opt_code_subject_depth_1: [] // 과목 선택 옵션
                , opt_code_subject_depth_2: []
                , opt_code_subject_depth_3: []
                , opt_code_subject_depth_4: []
                , opt_code_subject_depth_5: []

                , code_subject_depth_1: ''     // 과목 선택 코드
                , code_subject_depth_2: ''
                , code_subject_depth_3: ''
                , code_subject_depth_4: ''
                , code_subject_depth_5: ''

                , opt_code_area_depth_1: [] // 학교 선택 옵션
                , opt_code_area_depth_2: []
                , opt_code_area_depth_3: []
                , opt_code_area_depth_4: []

                , code_area_depth_1: ''     // 학교 선택 코드
                , code_area_depth_2: ''
                , code_area_depth_3: ''
                , code_area_depth_4: ''

                , opt_school_year: [] // 년도

                , opt_school_semester: [] // 학기
            }
        }

        , methods: {

            ...mapActions({
                getBase: "getBase"
            })

            // 진입점
            , init() {

                let _this = this;

                // 과목코드 조회
                _this.getCodeSubject(1, "000|000|000|000|000|");

                // 학교코드 조회
                _this.getCodeArea(1, "000|000|000|000|");

                // 년도코드 조회
                _this.getCodeYear();

                // 학기코드 조회
                _this.getCodeSemester();

                // 검색 latex
                {
                    $(document).off("click", ".dev_search_latex_li");
                    $(document).on("click", ".dev_search_latex_li", function () {

                        if (confirm("수식을 삭제 하시겠습니까?")) {

                            // 삭제
                            $(this).remove();

                            // 검색
                            _this.getSearchData(1);
                        }
                    })
                }

                // 검색
                _this.getSearchData(1);
            }

            // 검색
            , getSearchData: function (curPage) {

                // qs
                var qs = this.$GetQueryMap();

                // 폼
                var data = this.$FormToMap($("#searchForm"));
                data["gubun_page"] = this.gubun_page;
                data["curPage"] = curPage;
                data["code_subject"] = this.code_subject;
                data["code_area"] = this.code_area;

                // 라텍스
                {
                    let arrLatex = [];
                    let $arrLi = $(".dev_search_latex_li");
                    for (let idx = 0; idx < $arrLi.length; idx++) {

                        let latex = $($arrLi[idx]).find("svg").attr("data-latex");
                        arrLatex.push(latex);
                    }

                    data["search_latex"] = arrLatex.join(",");
                }

                // API 조회
                this.$SendPost("/vue_api/zadmin/exam_make/list", data, this.searchDataReturn);

            }
            , searchDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.rownum = data.ROWNUM;
                        this.paging = data.PAGING;
                        this.list_1 = data.LIST_1;

                        this.isOkSearch = true; // 검색가능

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }

            // 과목코드 조회
            , getCodeSubject: function (depth, code, event) {

                // 폼
                var data = {};
                data["depth"] = depth;

                if (code) {
                    data["code"] = code;
                }
                else if (event) {
                    data["code"] = event.target.value;
                }

                this.$SendPost("/vue_api/zadmin/exam_make/code_subject", data, this.getCodeSubjectDataReturn);
            }
            , getCodeSubjectDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        var depth = data.HASH.DEPTH;
                        switch (depth) {
                            case 1:
                                this.opt_code_subject_depth_1 = data.LIST_1; this.code_subject_depth_1 = "";
                                this.opt_code_subject_depth_2 = []; this.code_subject_depth_2 = "";
                                this.opt_code_subject_depth_3 = []; this.code_subject_depth_3 = "";
                                this.opt_code_subject_depth_4 = []; this.code_subject_depth_4 = "";
                                this.opt_code_subject_depth_5 = []; this.code_subject_depth_5 = "";
                                break;

                            case 2:
                                this.opt_code_subject_depth_2 = data.LIST_1; this.code_subject_depth_2 = "";
                                this.opt_code_subject_depth_3 = []; this.code_subject_depth_3 = "";
                                this.opt_code_subject_depth_4 = []; this.code_subject_depth_4 = "";
                                this.opt_code_subject_depth_5 = []; this.code_subject_depth_5 = "";
                                break;

                            case 3:
                                this.opt_code_subject_depth_3 = data.LIST_1; this.code_subject_depth_3 = "";
                                this.opt_code_subject_depth_4 = []; this.code_subject_depth_4 = "";
                                this.opt_code_subject_depth_5 = []; this.code_subject_depth_5 = "";
                                break;

                            case 4:
                                this.opt_code_subject_depth_4 = data.LIST_1; this.code_subject_depth_4 = "";
                                this.opt_code_subject_depth_5 = []; this.code_subject_depth_5 = "";
                                break;

                            case 5:
                                this.opt_code_subject_depth_5 = data.LIST_1; this.code_subject_depth_5 = "";
                                break;
                        }

                        // 과정선택
                        {
                            this.code_subject =
                                this.code_subject_depth_5 ? this.code_subject_depth_5 :
                                    this.code_subject_depth_4 ? this.code_subject_depth_4 :
                                        this.code_subject_depth_3 ? this.code_subject_depth_3 :
                                            this.code_subject_depth_2 ? this.code_subject_depth_2 : this.code_subject_depth_1;
                        }

                        // 검색
                        if (true == this.isOkSearch) {                            
                            this.getSearchData(1);
                        }
                    }
                }
            }

            // 학교코드 조회
            , getCodeArea: function (depth, code, event) {

                // 폼
                var data = {};
                data["depth"] = depth;

                if (code) {
                    data["code"] = code;
                }
                else if (event) {
                    data["code"] = event.target.value;
                }

                this.$SendPost("/vue_api/zadmin/exam_make/code_area", data, this.getCodeAreaDataReturn);
            }

            , getCodeAreaDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        var depth = data.HASH.DEPTH;
                        switch (depth) {
                            case 1:
                                this.opt_code_area_depth_1 = data.LIST_1; this.code_area_depth_1 = "";
                                this.opt_code_area_depth_2 = []; this.code_area_depth_2 = "";
                                this.opt_code_area_depth_3 = []; this.code_area_depth_3 = "";
                                this.opt_code_area_depth_4 = []; this.code_area_depth_4 = "";
                                break;

                            case 2:
                                this.opt_code_area_depth_2 = data.LIST_1; this.code_area_depth_2 = "";
                                this.opt_code_area_depth_3 = []; this.code_area_depth_3 = "";
                                this.opt_code_area_depth_4 = []; this.code_area_depth_4 = "";
                                break;

                            case 3:
                                this.opt_code_area_depth_3 = data.LIST_1; this.code_area_depth_3 = "";
                                this.opt_code_area_depth_4 = []; this.code_area_depth_4 = "";
                                break;

                            case 4:
                                this.opt_code_area_depth_4 = data.LIST_1; this.code_area_depth_4 = "";
                                break;
                        }

                        // 과정선택
                        {
                            this.code_area =
                                this.code_area_depth_4 ? this.code_area_depth_4 :
                                    this.code_area_depth_3 ? this.code_area_depth_3 :
                                        this.code_area_depth_2 ? this.code_area_depth_2 : this.code_area_depth_1;
                        }

                        // 검색
                        if (true == this.isOkSearch) {
                            this.getSearchData(1);
                        }
                    }
                }
            }

            // 년도코드 조회
            , getCodeYear: function () {

                // 폼
                var data = {};

                this.$SendPost("/vue_api/zadmin/exam_make/code_year", data, this.getCodeYearDataReturn);
            }

            , getCodeYearDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.opt_school_year = data.LIST_1;
                    }
                }
            }

            // 학기코드 조회
            , getCodeSemester: function () {

                // 폼
                var data = {};

                this.$SendPost("/vue_api/zadmin/exam_make/code_semester", data, this.getCodeSemesterDataReturn);
            }

            , getCodeSemesterDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.opt_school_semester = data.LIST_1;
                    }
                }
            }

            // 라텍스 조회
            , getLatex: function () {

                window.removeEventListener("message", this.getLatexReturn, false);
                window.addEventListener("message", this.getLatexReturn);

                FrameLayer.open("https://js.hyper-nomad.com/module/matheditor/index.html", '720px', '520px');
            }
            , getLatexReturn: function (event) {

                let $span = $(event.data);
                let $svg = $span.find("svg");

                let val = $svg.attr("data-latex");
                if (val) {
                    let $li = $("<li class='dev_search_latex_li'></li>")
                    $li.append($svg);
                    $(".dev_search_latex_ul").append($li);
                }

                FrameLayer.close();

                // 검색
                this.getSearchData(1);
            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>