<template>

    <!--================ Head Menu Area =================-->

    <div></div>

    <!--================ //Head Menu Area =================-->

</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        },

        data() {
            return {
                THIS_NAME: "INC USER HEAD"
            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                // 시작
                _this.exec("INIT");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "INIT":
                        {
                            /*
                            window.location.hostname;   // => kftc.local
                            window.location.href;       // => http://kftc.local:8088/test.jsp
                            window.location.host;       // => kftc.local:8088
                            window.location.port;       // => 8088
                            window.location.pathname;   // => test.jsp
                            window.location.search;     // => ?gg=1 
                            window.location.protocol;   // => http: 
                            */

                            // 현재 경로 & 쿼리스트링
                            this.setBase({ "pathCur": window.location.pathname });
                            this.setBase({ "qsCur": window.location.search });

                            // data 설정
                            data["url"] = this.getBase.pathCur; // 현재 url 경로
                            data["qs"] = this.getBase.qsCur;    // 현재 url 쿼리스트링

                            // URL 설정
                            url = "/vue_api/common/menu/user_check_grade";
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "INIT":
                        {
                            console.log("[[HEAD]]-------------------------------");
                            console.log(data);
                            console.log("[[HEAD]]-------------------------------");

                            // 1. 기존 유저 정보와 비교해서 다르면 메뉴 초기화
                            if (this.getBase.userNo != data.USER_NO
                                || this.getBase.userGrade != data.USER_GRADE) {

                                this.setBase({ "mapUserMenu": [] });
                            }

                            // 2. 유저 정보 새로 설정
                            this.setBase({ "userNo": data.USER_NO });
                            this.setBase({ "userGrade": data.USER_GRADE });
                            this.setBase({ "userLoginYn": data.USER_LOGIN_YN });
                        }
                        break;

                    default:
                        {

                        };
                }
            }
        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
            this.init();
        },

        mounted() {
            this.$PrintLog(this.THIS_NAME + " : mounted");
        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
