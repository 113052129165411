<template>

    <!-- 서브 배너 (s) -->
    <div class="pu_subVisual pu_sub04">
        <dl>
            <dt>양돈정보</dt>
            <dd>정부 및 대 기업체의 양돈관련 연구 프로젝트를 수행 중에 있습니다.</dd>
        </dl>
    </div>
    <!-- 서브 배너 (e) -->

</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        },

        data() {
            return {
                THIS_NAME: "INC USER TITLE"
            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

            }
        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
            this.init();
        },

        mounted() {
            this.$PrintLog(this.THIS_NAME + " : mounted");
        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
