<template>

    <div class="pu_wd_def_min pu_wrapper_main">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(s) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_banner_top />
            <inc_user_left />
            <inc_user_title />
            <!-------------------------- 인클루드(s) -------------------------->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">

                <!-- 검색영역(s) -->
                <form id="searchForm" onsubmit="return false">
                    <div class="pu_listSearchMulti">
                        <div class="pu_wd_def_max">

                            <dl class="">
                                <dt><label>검색조건</label></dt>
                                <dd>
                                    <select name="search_gubun" class="" title="검색조건을 선택하세요.">
                                        <option value="title" selected="selected">제목</option>
                                    </select>
                                    <input type="text" name="search_text" placeholder="검색어를 입력해주세요." style="width: 400px;" />
                                </dd>
                                <dd>
                                    <a href="javascript:;" class="pu_btnSerch01">검색</a>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </form>
                <!-- 검색영역(e) -->
                <!-- 리스트(s) -->
                <div>
                    <table class="pu_table_list">
                        <colgroup>
                            <col width="5%">
                            <col width="*%">
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">번호</th>
                                <th scope="col" style="border-left:solid 1px #bebebe">내용</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="dev_page_move_regi"
                                v-for="(row, idx) in list_1">
                                <td>
                                    <input type="hidden" class="dev_no" :value="row.NO" />
                                    {{ getRowNum(idx) }}
                                </td>
                                <td style="border-left:solid 1px #bebebe">
                                    <table class="pu_table_inner">
                                        <colgroup>
                                            <col style="width:15%;" />
                                            <col style="width:*;" />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th class="">제목</th>
                                                <td class="">{{ row.TITLE }}</td>
                                            </tr>
                                            <tr>
                                                <td class="" colspan="2"><div v-html="$ConHtml(row.CONTENTS)"></div></td>
                                                
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr v-if="list_1.length == 0">
                                <td colspan="2">조회된 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 리스트(e) -->

                <div class="pu_divBtnMain">
                    <div class="pu_divLeft">
                        <a class="pu_btn_red" href="javascript:;" v-on:click="$PageMove('./regi')"><i class="xi-pen"></i> 신규등록</a>
                    </div>
                    <div class="pu_divCenter">
                        <!-- 페이징(s) -->
                        <div class="pu_pagination_2" v-html="paging"></div>
                        <!-- 페이징(e) -->
                    </div>
                    <div class="pu_divRight">
                    </div>
                </div>

            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 서브 실제 컨텐츠 영역 (e) -------------------------->
        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                // 검색
                _this.exec("SEARCH", 1);
            }

            // 실행
            , exec: function (gubun, num, code, event) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.$GetQueryMap();
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/my_head/list";

                            // 폼
                            data["gubun_page"] = _this.gubun_page;
                            data["curPage"] = num;

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "SEARCH":
                        {
                            _this.rownum = data.ROWNUM;
                            _this.paging = data.PAGING;
                            _this.list_1 = data.LIST_1;
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }
    }

</script>