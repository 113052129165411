<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">
            <inc_zadmin_head />
            <inc_zadmin_left />
            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">
                    <inc_zadmin_title />
                    <!-- 검색조건 (s) -->
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <div class="pu_TableY01">
                                <form id="regiForm" onsubmit="return false">
                                    <table>
                                        <colgroup>
                                            <col width="15%">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>제목*</th>
                                                <td><input name="title" type="text" v-model="view_1.TITLE" style="width: 100%" /></td>
                                            </tr>
                                            <tr>
                                                <th>내용</th>
                                                <td>
                                                    <textarea name="content" class="pu_input_textarea" v-html="view_1.CONTENT">설명</textarea>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>공지여부</th>
                                                <td>
                                                    <input type="radio" id="noticeyn1" name="notice_yn" value="Y" v-model="view_1.NOTICE_YN"><label for="noticeyn1">적용</label>
                                                    <input type="radio" id="noticeyn2" name="notice_yn" value="N" v-model="view_1.NOTICE_YN"><label for="noticeyn2">미적용</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>적용여부</th>
                                                <td>
                                                    <input type="radio" id="showyn1" name="show_yn" value="Y" v-model="view_1.SHOW_YN"><label for="showyn1">적용</label>
                                                    <input type="radio" id="showyn2" name="show_yn" value="N" v-model="view_1.SHOW_YN"><label for="showyn2">미적용</label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->
                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_right">
                                <a href="javascript:history.back()" class="pu_btn_dark"><i class="xi-close"></i> 취소</a>
                                <a href="javascript:" class="pu_btn_blue" v-on:click="saveData()"><i class="xi-check"></i> 저장</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: { }

        , data() {

            return {
                gubun_page: "board_notice_regi"
                , sitegubun: this.$route.query.sitegubun // 사이트 구분
                , rownum: 0    // 번호
                , paging: ""   // 페이징
                , cnt_lim_pic: 10
                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
                , imagefile: ""
            }
        }

        , methods: {

            ...mapActions({
                getBase: "getBase"
            })

            // 진입점
            , init() {
                // dimm 숨기기
                this.getSearchData(1);

                this.$HideDimmLogo();
            }
            , getSearchData: function (curPage) {

                // qs
                var qs = this.$GetQueryMap();

                // 폼
                var data = {};
                data["no"] = qs.no;
                data["sitegubun"] = this.sitegubun;

                // API 조회
                this.$SendPost("/vue_api/zadmin/board/notice/view", data, this.searchDataReturn);

            }
            , searchDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1;

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                    this.setCkEditor();
                }
            }
            , saveData() {

                // 아이디
                var id = $("[name='title']");
                if (!id.val().trim()) {
                    alert("제목를 입력해주세요");
                    id.focus();
                    return false;
                }
                CKEDITOR.instances.content.updateElement();
                if (confirm(INFO_CONF_SAVE)) {

                    var qs = this.$GetQueryMap();
                    // dimm 보이기
                    this.$ShowDimmLogo();

                    var data = this.$FormToMap($('#regiForm'));
                    data["no"] = qs.no;
                    data["sitegubun"] = this.sitegubun;

                    this.$SendPost("/vue_api/zadmin/board/notice/save", data, this.saveEventReturn);
                }
            }
            // 저장 데이터
            , saveEventReturn(data, err) {
                // dimm 숨기기
                this.$HideDimmLogo();
            }
            // ck editor 세팅
            , setCkEditor: function () {

                setTimeout(() => {

                    try {
                        setCkEditorDefault("content", "board_notice", this.cnt_lim_pic, ".jpeg,.jpg,.png");
                    } catch (err) {
                        this.setCkEditor();
                    }

                }, 100);
            }
        }

        , beforeCreate() {

        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>