<template>

    <!-------------------------- 상단메뉴부분 (s) -------------------------->
    <div class="dev_wrapper_top pu_wrapper_top">

        <!-- pc 메뉴 (s) -->
        <div class="dev_wrapper_top_web pu_wrapper_top_web">

            <!--  최상단 회원/검색 (s)-->
            <div class="dev_gnbTop pu_gnbTop">
                <div class="pu_wd_def_max">

                    <ul class="pu_members"
                        v-if="getBase.userLoginYn != 'Y'">
                        <li class="pu_login"><a href="javascript:;" v-on:click="$PageMove('/user/login/login')">로그인 <i class="xi-log-in"></i></a></li>
                        <!--<li class="pu_join"><a href="javascript:;" v-on:click="$PageMove('/user/login/join_1')">회원가입 <i class="xi-user-plus"></i></a></li>-->
                    </ul>

                    <ul class="pu_members"
                        v-if="getBase.userLoginYn == 'Y'">
                        <li class="pu_login"><a href="javascript:;" v-on:click="$PageMove('/user/login/logout')">로그아웃 <i class="xi-log-out"></i></a></li>
                        <li class="pu_join"><a href="javascript:;" v-on:click="$PageMove('/user/my_page/format/head/list')">MY페이지 <i class="xi-user"></i></a></li>
                    </ul>

                    <div class="pu_topSearch">
                        <label class="pu_blind" for="serch">검색</label>
                        <input type="text" placeholder="검색어를 입력해주세요.">
                        <input type="button" class="pu_btnSerch01" title="검색">
                    </div>
                </div>
            </div>
            <!--  최상단 회원/검색 (e)- -->

            <div class="pu_wd_def_max dev_wrapper_menu pu_wrapper_menu">
                <h1>
                    <a href="javascript:;"><img src="/common/img/gnb_ci.png" alt="정p&c연구소"></a>
                </h1>

                <div class="pu_gnb">

                    <ul class="pu_gnb_1depth">
                        <li class="pu_gnb_li"
                            v-for="(d1, idx_d1) in getBase.mapUserMenu['000|000|000|']"
                            v-bind:key="idx_d1">

                            <!--1뎁스-->
                            <a href="javascript:;"
                               v-on:click="$PageMove(d1.URL)"
                               v-html="d1.TITLE"></a>

                            <ul class="pu_gnb_2depth">
                                <li v-for="(d2, idx_d2) in getBase.mapUserMenu[d1.CODE.substring(0, 4) + '000|000|']"
                                    v-bind:key="idx_d2">

                                    <!--2뎁스-->
                                    <a href="javascript:;"
                                       v-on:click="$PageMove(d2.URL)"
                                       v-html="d2.TITLE"></a>

                                    <ul class="pu_gnb_3depth">
                                        <li v-for="(d3, idx_d3) in getBase.mapUserMenu[d2.CODE.substring(0, 8) + '000|']"
                                            v-bind:key="idx_d3">

                                            <!--3뎁스-->
                                            <a href="javascript:;"
                                               v-on:click="$PageMove(d3.URL)">
                                                <i class="xi-angle-right-min"></i>
                                                <span v-html="d3.TITLE"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <button class="dev_btn_snb pu_snb_btn"><i class="xi-bars xi-2x"></i></button>
            </div>
        </div>
        <!-- pc 메뉴 (e)-->
        <!-- 모바일 메뉴 (s)-->
        <div class="pu_snb_wrap">
            <div class="pu_snb_innerWrap">
                <div class="pu_snb">
                    <ul class="pu_gnb_1depth">

                        <li class="pu_gnb_li"
                            v-for="(d1, idx_d1) in getBase.mapUserMenu['000|000|000|']"
                            v-bind:key="idx_d1">

                            <!--1뎁스-->
                            <p v-html="d1.TITLE"></p>

                            <ul class="pu_gnb_2depth">

                                <li v-for="(d2, idx_d2) in getBase.mapUserMenu[d1.CODE.substring(0, 4) + '000|000|']"
                                    v-bind:key="idx_d2">

                                    <!--2뎁스-->
                                    <a href="javascript:;"
                                       v-on:click="$PageMove(d2.URL)"
                                       v-html="d2.TITLE"></a>

                                    <ul class="pu_gnb_3depth">

                                        <li v-for="(d3, idx_d3) in getBase.mapUserMenu[d2.CODE.substring(0, 8) + '000|']"
                                            v-bind:key="idx_d3">

                                            <!--3뎁스-->
                                            <a href="javascript:;"
                                               v-on:click="$PageMove(d3.URL)"
                                               v-html="d3.TITLE"></a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <button type="button" class="dev_btn_snb_close pu_snb_close_btn">닫기</button>
        </div>
        <div class="pu_mask"></div>
        <!-- 모바일 메뉴 (e)-->
    </div>
    <!-------------------------- 상단메뉴부분 (e) -------------------------->

</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        },

        data() {
            return {
                THIS_NAME: "INC USER TOP"
            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                // 시작
                _this.exec("MENU");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "MENU":
                        {
                            // data 설정
                            data["url"] = this.getBase.pathCur; // 현재 url 경로
                            data["qs"] = this.getBase.qsCur;    // 현재 url 쿼리스트링
                            data["menu_code"] = this.getBase.userMenuCode; // 기존 메뉴 코드

                            // URL 설정
                            url = "/vue_api/common/menu/user";
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "MENU":
                        {
                            if (data.MENU_NAME_1D || Object.keys(this.getBase.mapUserMenu).length < 1) {

                                this.setBase({ "userMenuCode": data.MENU_CODE });       // 메뉴코드
                                this.setBase({ "userMenuCode_1d": data.MENU_CODE_1D }); // 1뎁스 메뉴 코드
                                this.setBase({ "userMenuCode_2d": data.MENU_CODE_2D }); // 2뎁스 메뉴 코드
                                this.setBase({ "userMenuCode_3d": data.MENU_CODE_3D }); // 3뎁스 메뉴 코드
                                this.setBase({ "userMenuName_1d": data.MENU_NAME_1D }); // 1뎁스 메뉴 명
                                this.setBase({ "userMenuName_2d": data.MENU_NAME_2D }); // 2뎁스 메뉴 명
                                this.setBase({ "userMenuName_3d": data.MENU_NAME_3D }); // 3뎁스 메뉴 명

                                this.setBase({ "mapUserMenu": data.MAP_LIST_1 });       // 메뉴
                            }

                            // css
                            {
                                /* 1뎁스 메뉴(s) */
                                $(document).off("mouseenter", ".dev_wrapper_top");
                                $(document).on("mouseenter", ".dev_wrapper_top", function () {

                                    $("#dev_dimm_dark").show(); // dimm 보이기
                                    $(".dev_wrapper_top").css("z-index", "11");
                                    $(".dev_wrapper_top_web .dev_wrapper_menu").css("height", "250px");
                                });

                                $(document).off("mouseleave", ".dev_wrapper_top");
                                $(document).on("mouseleave", ".dev_wrapper_top", function () {

                                    $(".dev_wrapper_top_web .dev_wrapper_menu").css("height", "");
                                    $(".dev_wrapper_top").css("z-index", "10");
                                    $("#dev_dimm_dark").hide(); // dimm 가리기
                                });
                                /* 1뎁스 메뉴(e) */

                                /* 모바일 메뉴(s) */
                                $(document).off("click", ".dev_btn_snb");
                                $(document).on("click", ".dev_btn_snb", function () {
                                    $(".pu_snb_wrap").addClass("pu_snb_on");
                                    $(".pu_mask").fadeIn();
                                });

                                $(document).off("click", ".dev_btn_snb_close");
                                $(document).on("click", ".dev_btn_snb_close", function () {
                                    $(".pu_snb_wrap").removeClass("pu_snb_on");
                                    $(".pu_mask").fadeOut();
                                });
                                /* 모바일 메뉴(e) */

                                // fixed 아이템
                                $(window).off("scroll");
                                $(window).on("scroll", function () {
                                
                                    // top 고정
                                    let divMenuTop = $(".dev_wrapper_top");
                                    if (divMenuTop.length == 1) {
                                
                                        let pScr = $(document).scrollTop();
                                        if (pScr > 0) {
                                            divMenuTop.find(".dev_gnbTop").css("height", "0px");
                                            $(".dev_wrapper_middle").css("padding-top", "55px");
                                        }
                                        else {
                                            divMenuTop.find(".dev_gnbTop").css("height", "45px");
                                            $(".dev_wrapper_middle").css("padding-top", "100px");
                                        }
                                    }
                                
                                    // 검색 옵션 고정
                                    let divSearch = $(".pu_listSearchMulti");
                                    if (divSearch.length == 1) {
                                
                                        let pScr = $(document).scrollTop();
                                        let pDiv = divSearch.offset().top;
                                
                                        if (divSearch.css("position") == "relative" && pDiv > 100) {
                                            window.offsetDivSearch = pDiv;
                                        }
                                
                                        if (pScr > window.offsetDivSearch) {
                                            divSearch.addClass("pu_fixedTopSearch"); // 옵션 고정
                                            $(".dev_btn_opt_close").click();         // 옵션 높이 줄이기
                                        }
                                        else {
                                            divSearch.removeClass("pu_fixedTopSearch");
                                        }
                                    }
                                
                                });
                            }
                        }
                        break;

                    default:
                        {

                        };
                }
            }
        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
            this.init();
        },

        mounted() {
            this.$PrintLog(this.THIS_NAME + " : mounted");
        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
