<template>

    <div class="pu_wd_def_min pu_wrapper_main">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(s) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_banner_top />
            <inc_user_left />
            <inc_user_title />
            <!-------------------------- 인클루드(s) -------------------------->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">

                <!-- 검색영역(s) -->
                <form id="searchForm" onsubmit="return false">
                    <div class="pu_listSearchMulti">
                        <div class="pu_wd_def_max">
                            <dl class="">
                                <dt><label>교육과정</label></dt>
                                <dd>
                                    <select class="" name="code_subject_1" v-model="code_subject_depth_1"
                                            v-on:change="exec('SUBJECT', 2, '', $event)" title="과목을 선택하세요.">
                                        <option value="">- 과목 선택</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="" name="code_subject_2" v-model="code_subject_depth_2"
                                            v-on:change="exec('SUBJECT', 3, '', $event)" title="개정년도를 선택하세요.">
                                        <option value="">- 개정년도 선택</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="" name="code_subject_3" v-model="code_subject_depth_3"
                                            v-on:change="exec('SUBJECT', 4, '', $event)" title="교육과정을 선택하세요.">
                                        <option value="">- 교육과정 선택</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_3" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="" name="code_subject_4" v-model="code_subject_depth_4"
                                            v-on:change="exec('SUBJECT', 5, '', $event)" title="대단원을 선택하세요.">
                                        <option value="">- 대단원 선택</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_4" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="" name="code_subject_5" v-model="code_subject_depth_5"
                                            v-on:change="exec('SUBJECT', 6, '', $event)" title="중단원을 선택하세요.">
                                        <option value="">- 중단원 선택</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_5" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>
                                </dd>
                            </dl>

                            <dl class="pu_searchItemHide">
                                <dt><label>학교</label></dt>
                                <dd>
                                    <select class="" name="code_area_1" v-model="code_area_depth_1"
                                            v-on:change="exec('AREA', 2, '', $event)" title="시도를 선택하세요.">
                                        <option value="">- 시도 선택</option>
                                        <option v-for="(row, idx) in opt_code_area_depth_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="" name="code_area_2" v-model="code_area_depth_2"
                                            v-on:change="exec('AREA', 3, '', $event)" title="구군을 선택하세요.">
                                        <option value="">- 구군 선택</option>
                                        <option v-for="(row, idx) in opt_code_area_depth_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="" name="code_area_3" v-model="code_area_depth_3"
                                            v-on:change="exec('AREA', 4, '', $event)" title="초중고를 선택하세요.">
                                        <option value="">- 초중고 선택</option>
                                        <option v-for="(row, idx) in opt_code_area_depth_3" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="" name="code_area_4" v-model="code_area_depth_4"
                                            v-on:change="exec('AREA', 5, '', $event)" title="학교명을 선택하세요.">
                                        <option value="">- 학교 선택</option>
                                        <option v-for="(row, idx) in opt_code_area_depth_4" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>
                                </dd>
                            </dl>

                            <dl class="">
                                <dt><label>타입</label></dt>
                                <dd>
                                    <select name="gubun_exam_type" class="dev_option"
                                            title="타입을 선택하세요.">
                                        <option value="">- 타입 선택</option>
                                        <option value="A">선택</option>
                                        <option value="B">서술</option>
                                    </select>
                                </dd>

                                <dt class="pu_ml20"><label>난이도</label></dt>
                                <dd>
                                    <select name="gubun_exam_diff" class="dev_option"
                                            title="난이도를 선택하세요.">
                                        <option value="">- 난이도 선택</option>
                                        <option value="1">하</option>
                                        <option value="2">중</option>
                                        <option value="3">상</option>
                                        <option value="4">킬러</option>
                                    </select>
                                </dd>

                                <dt class="pu_ml20"><label>년도</label></dt>
                                <dd>
                                    <select name="school_year" class="dev_option"
                                            title="년도를 선택하세요.">
                                        <option value="">- 년도 선택</option>
                                        <option v-for="(row, idx) in opt_school_year" v-bind:value="row.YEAR">{{ row.YEAR }}</option>
                                    </select>
                                </dd>

                                <dt class="pu_ml20"><label>학기</label></dt>
                                <dd>
                                    <select name="school_semester" class="dev_option"
                                            title="학기를 선택하세요.">
                                        <option value="">- 학기 선택</option>
                                        <option v-for="(row, idx) in opt_school_semester" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>
                                </dd>
                            </dl>

                            <dl class="pu_searchItemHide">
                                <dt><label>수식</label></dt>
                                <dd>
                                    <a class="pu_ss_btn_grey" href="javascript:;" style="margin-top: 2px;"
                                       v-on:click="getLatex()">수식추가</a>
                                </dd>
                                <dd>

                                    <!--라텍스 검색-->
                                    <ul class="dev_search_latex_ul pu_search_latex_ul"></ul>
                                    <!--//라텍스 검색-->

                                </dd>
                            </dl>

                            <dl class="pu_searchItemHide">
                                <dt><label>검색조건</label></dt>
                                <dd>
                                    <select name="search_gubun" class="" title="검색조건을 선택하세요.">
                                        <option value="cont_q" selected="selected">질문 내용</option>
                                    </select>
                                    <input type="text" name="search_text" placeholder="검색어를 입력해주세요." style="width: 400px;" />
                                </dd>
                                <dd>
                                    <a href="javascript:;" class="pu_btnSerch01">검색</a>
                                </dd>
                            </dl>

                            <div class="pu_btn_wrap">
                                <a class="dev_btn_opt_open pu_btn_opt_open pu_ss_btn_defalut"><i class="xi-caret-down"></i> 옵션</a>
                                <a class="dev_btn_opt_close pu_btn_opt_close pu_ss_btn_grey"><i class="xi-caret-up"></i> 옵션</a>
                            </div>
                        </div>
                    </div>
                </form>
                <!-- 검색영역(e) -->
                <!-- 리스트(s) -->
                <div>
                    <div class="pu_flex">

                        <table id="dev_exam_from" class="pu_table_list" style="width:490px;">
                            <colgroup>
                                <col width="35px" />
                                <col width="50px" />
                                <col width="400px" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th style="border-right: solid 1px #bebebe;">
                                        <input type="checkbox" v-bind:id="'chk_each_' + 1" class="dev_checkbox_all" />
                                        <label v-bind:for="'chk_each_' + 1"
                                               style="padding: 0px 0px 0px 23px;"></label>
                                    </th>
                                    <th style="border-right: solid 1px #bebebe;">번호</th>
                                    <th>교육과정</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, idx) in list_1" class="dev_tr_checkbox">
                                    <td style="vertical-align: top; border-right: solid 1px #bebebe;">
                                        <input type="checkbox" v-bind:id="'chk_all_1_' + idx" class="dev_checkbox_each" :value="row.NO" />
                                        <label v-bind:for="'chk_all_1_' + idx"></label>
                                    </td>
                                    <td style="padding-top: 15px; vertical-align: top; border-right: solid 1px #bebebe;">
                                        {{ getRowNum(idx) }}
                                    </td>
                                    <td style="vertical-align: top;">

                                        <table class="pu_table_inner">
                                            <tbody>
                                                <tr>
                                                    <th style="padding-left: 7px; text-align: start;">
                                                        <span>{{ row.CODE_SUBJECT_NAME }}</span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th style="padding-left: 7px; text-align: start;">
                                                        <span v-if="row.CODE_AREA_NAME">{{ row.CODE_AREA_NAME }} ({{ row.SCHOOL_YEAR }} / {{ row.SCHOOL_SEMESTER_NAME }})</span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th style="padding-left: 7px; text-align: start;">
                                                        난이도 : {{ row.GUBUN_EXAM_DIFF_NAME }}
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div v-html="$ConHtml(row.CONT_Q)"
                                                             style="min-height: 150px; transform: scale(0.8); transform-origin:top left;"></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr v-if="list_1.length == 0">
                                    <td colspan="3">조회된 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>

                        <div style="width:100px !important; position:relative;">
                            <div style="position: sticky; top: 25%; text-align:center;">

                                <a class="pu_btn_tri_right_red" href="javascript:;" v-on:click="exec('ADDEXAMITEM')"
                                   style="width: 90px; min-width:unset; display:block; left:calc(50% - 45px);"><i class="xi-plus"></i> 추가</a>

                                <a class="pu_btn_tri_left_blue" href="javascript:;" v-on:click="exec('DELEXAMITEM')"
                                   style="width: 90px; min-width: unset; display: block; left: calc(50% - 45px); margin-top: 7px;"><i class="xi-minus"></i> 삭제</a>

                                <a class="pu_s_btn_dark" href="javascript:;" v-on:click="exec('SAVEPRINTITEM')"
                                   style="width: 90px; min-width: unset; height: 35px; line-height: 35px; margin-top: 15px; font-size: 13px;"> 문제 생성</a>
                            </div>
                        </div>

                        <table id="dev_exam_to" class="pu_table_list" style="width:490px;">
                            <colgroup>
                                <col width="35px" />
                                <col width="50px" />
                                <col width="400px" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th style="border-right: solid 1px #bebebe;">
                                        <input type="checkbox" v-bind:id="'chk_each_' + 2" class="dev_checkbox_all" />
                                        <label v-bind:for="'chk_each_' + 2"
                                               style="padding: 0px 0px 0px 23px;"></label>
                                    </th>
                                    <th style="border-right: solid 1px #bebebe;">번호</th>
                                    <th>교육과정</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, idx) in list_2" class="dev_tr_checkbox">
                                    <td style="vertical-align: top; border-right: solid 1px #bebebe;">
                                        <input type="checkbox" v-bind:id="'chk_all_2_' + idx" class="dev_checkbox_each" :value="row.NO" />
                                        <label v-bind:for="'chk_all_2_' + idx"></label>
                                    </td>
                                    <td style="padding-top: 15px; vertical-align: top; border-right: solid 1px #bebebe;">
                                        {{ list_2.length - idx }}
                                    </td>
                                    <td style="vertical-align: top;">

                                        <table class="pu_table_inner">
                                            <tbody>
                                                <tr>
                                                    <th style="padding-left: 7px; text-align: start;">
                                                        <span>{{ row.CODE_SUBJECT_NAME }}</span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th style="padding-left: 7px; text-align: start;">
                                                        <span v-if="row.CODE_AREA_NAME">{{ row.CODE_AREA_NAME }} ({{ row.SCHOOL_YEAR }} / {{ row.SCHOOL_SEMESTER_NAME }})</span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th style="padding-left: 7px; text-align: start;">
                                                        난이도 : {{ row.GUBUN_EXAM_DIFF_NAME }}
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div v-html="$ConHtml(row.CONT_Q)"
                                                             style="min-height: 150px; transform: scale(0.8); transform-origin: top left;"></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr v-if="list_2.length == 0">
                                    <td colspan="3" style="vertical-align: top;">조회된 결과가 없습니다.</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
                <!-- 리스트(e) -->

                <div class="pu_divBtnMain">
                    <div class="pu_divLeft">
                    </div>
                    <div class="pu_divCenter">
                    </div>
                    <div class="pu_divRight">
                    </div>
                </div>

            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 서브 실제 컨텐츠 영역 (e) -------------------------->
        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , isOkSearch: false // 검색가능
                , list_1: []
                , list_2: []

                , code_subject: "" // 검색 코드
                , code_area: ""    // 검색 코드

                , opt_code_subject_depth_1: [] // 과목 선택 옵션
                , opt_code_subject_depth_2: []
                , opt_code_subject_depth_3: []
                , opt_code_subject_depth_4: []
                , opt_code_subject_depth_5: []

                , code_subject_depth_1: ''     // 과목 선택 코드
                , code_subject_depth_2: ''
                , code_subject_depth_3: ''
                , code_subject_depth_4: ''
                , code_subject_depth_5: ''

                , opt_code_area_depth_1: [] // 학교 선택 옵션
                , opt_code_area_depth_2: []
                , opt_code_area_depth_3: []
                , opt_code_area_depth_4: []

                , code_area_depth_1: ''     // 학교 선택 코드
                , code_area_depth_2: ''
                , code_area_depth_3: ''
                , code_area_depth_4: ''

                , opt_school_year: []     // 년도

                , opt_school_semester: [] // 학기
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                // 검색 latex
                {
                    $(document).off("click", ".dev_search_latex_li");
                    $(document).on("click", ".dev_search_latex_li", function () {

                        if (confirm("수식을 삭제 하시겠습니까?")) {

                            // 삭제
                            $(this).remove();

                            // 검색
                            _this.exec("SEARCH", 1);
                        }
                    })
                }

                // 과목코드 조회
                _this.exec("SUBJECT", 1, "000|000|000|000|000|");

                // 학교코드 조회
                _this.exec("AREA", 1, "000|000|000|000|");

                // 년도코드 조회
                _this.exec("YEAR");

                // 학기코드 조회
                _this.exec("SEMESTER");

                // 검색
                _this.exec("SEARCH", 1);
            }

            // 실행
            , exec: function (gubun, num, code, event) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.$GetQueryMap();
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_select/list";

                            // 폼
                            data["gubun_page"] = _this.gubun_page;
                            data["curPage"] = num;
                            data["code_subject"] = _this.code_subject;
                            data["code_area"] = _this.code_area;

                            // 라텍스
                            {
                                let arrLatex = [];
                                let $arrLi = $(".dev_search_latex_li");
                                for (let idx = 0; idx < $arrLi.length; idx++) {

                                    let latex = $($arrLi[idx]).find("svg").attr("data-latex");
                                    arrLatex.push(latex);
                                }

                                data["search_latex"] = arrLatex.join(",");
                            }

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    case "SUBJECT":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_make/code_subject";

                            data["depth"] = num;

                            if (code) {
                                data["code"] = code;
                            }
                            else if (event) {
                                data["code"] = event.target.value;
                            }
                        }
                        break;

                    case "AREA":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_make/code_area";

                            data["depth"] = num;

                            if (code) {
                                data["code"] = code;
                            }
                            else if (event) {
                                data["code"] = event.target.value;
                            }
                        }
                        break;

                    case "YEAR":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_make/code_year";
                        }
                        break;

                    case "SEMESTER":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_make/code_semester";
                        }
                        break;

                    case "ADDEXAMITEM":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_select/point";
                        }
                        break;

                    case "DELEXAMITEM":
                        {
                        }
                        break;

                    case "SAVEPRINTITEM":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_select/save";

                            if (_this.list_2.length < 1) {
                                alert("추가된 시험문제가 없습니다.");
                                return;
                            }

                            if (confirm("추가된 시험문제를 저장 하시겠습니까?\n[ " + _this.list_2.length + " ]포인트가 차감됩니다.")) {

                                let title = prompt("시험지 제목을 입력해주세요.", "") || "";
                                if (!title.trim()) {

                                    alert("취소 되었습니다.");
                                    return;
                                }
                                else {

                                    // 문제 번호
                                    let arrNo = [];
                                    for (let idx = 0; idx < _this.list_2.length; idx++) {
                                        arrNo.push(_this.list_2[idx].NO);
                                    }

                                    // 폼
                                    data["title"] = title;
                                    data["strNo"] = arrNo.join(",");
                                }
                            }
                            else {
                                alert("취소 되었습니다.");
                                return;
                            }
                        }
                        break;

                    default:
                        {
                            
                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "SEARCH":
                        {
                            _this.rownum = data.LIST_1.length;
                            _this.paging = data.PAGING;
                            _this.list_1 = data.LIST_1;

                            _this.isOkSearch = true; // 검색가능
                        }
                        break;

                    case "SUBJECT":
                        {
                            var depth = data.HASH.DEPTH;
                            switch (depth) {
                                case 1:
                                    _this.opt_code_subject_depth_1 = data.LIST_1; _this.code_subject_depth_1 = "";
                                    _this.opt_code_subject_depth_2 = []; _this.code_subject_depth_2 = "";
                                    _this.opt_code_subject_depth_3 = []; _this.code_subject_depth_3 = "";
                                    _this.opt_code_subject_depth_4 = []; _this.code_subject_depth_4 = "";
                                    _this.opt_code_subject_depth_5 = []; _this.code_subject_depth_5 = "";
                                    break;

                                case 2:
                                    _this.opt_code_subject_depth_2 = data.LIST_1; _this.code_subject_depth_2 = "";
                                    _this.opt_code_subject_depth_3 = []; _this.code_subject_depth_3 = "";
                                    _this.opt_code_subject_depth_4 = []; _this.code_subject_depth_4 = "";
                                    _this.opt_code_subject_depth_5 = []; _this.code_subject_depth_5 = "";
                                    break;

                                case 3:
                                    _this.opt_code_subject_depth_3 = data.LIST_1; _this.code_subject_depth_3 = "";
                                    _this.opt_code_subject_depth_4 = []; _this.code_subject_depth_4 = "";
                                    _this.opt_code_subject_depth_5 = []; _this.code_subject_depth_5 = "";
                                    break;

                                case 4:
                                    _this.opt_code_subject_depth_4 = data.LIST_1; _this.code_subject_depth_4 = "";
                                    _this.opt_code_subject_depth_5 = []; _this.code_subject_depth_5 = "";
                                    break;

                                case 5:
                                    _this.opt_code_subject_depth_5 = data.LIST_1; _this.code_subject_depth_5 = "";
                                    break;
                            }

                            // 과정선택
                            {
                                _this.code_subject =
                                    _this.code_subject_depth_5 ? _this.code_subject_depth_5 :
                                        _this.code_subject_depth_4 ? _this.code_subject_depth_4 :
                                            _this.code_subject_depth_3 ? _this.code_subject_depth_3 :
                                                _this.code_subject_depth_2 ? _this.code_subject_depth_2 : _this.code_subject_depth_1;
                            }

                            // 검색
                            if (true == _this.isOkSearch) {
                                _this.exec("SEARCH", 1);
                            }
                        }
                        break;

                    case "AREA":
                        {
                            var depth = data.HASH.DEPTH;
                            switch (depth) {
                                case 1:
                                    _this.opt_code_area_depth_1 = data.LIST_1; _this.code_area_depth_1 = "";
                                    _this.opt_code_area_depth_2 = []; _this.code_area_depth_2 = "";
                                    _this.opt_code_area_depth_3 = []; _this.code_area_depth_3 = "";
                                    _this.opt_code_area_depth_4 = []; _this.code_area_depth_4 = "";
                                    break;

                                case 2:
                                    _this.opt_code_area_depth_2 = data.LIST_1; _this.code_area_depth_2 = "";
                                    _this.opt_code_area_depth_3 = []; _this.code_area_depth_3 = "";
                                    _this.opt_code_area_depth_4 = []; _this.code_area_depth_4 = "";
                                    break;

                                case 3:
                                    _this.opt_code_area_depth_3 = data.LIST_1; _this.code_area_depth_3 = "";
                                    _this.opt_code_area_depth_4 = []; _this.code_area_depth_4 = "";
                                    break;

                                case 4:
                                    _this.opt_code_area_depth_4 = data.LIST_1; _this.code_area_depth_4 = "";
                                    break;
                            }

                            // 과정선택
                            {
                                _this.code_area =
                                    _this.code_area_depth_4 ? _this.code_area_depth_4 :
                                        _this.code_area_depth_3 ? _this.code_area_depth_3 :
                                            _this.code_area_depth_2 ? _this.code_area_depth_2 : _this.code_area_depth_1;
                            }

                            // 검색
                            if (true == _this.isOkSearch) {
                                _this.exec("SEARCH", 1);
                            }
                        }
                        break;

                    case "YEAR":
                        {
                            _this.opt_school_year = data.LIST_1;
                        }
                        break;

                    case "SEMESTER":
                        {
                            _this.opt_school_semester = data.LIST_1;
                        }
                        break;

                    case "ADDEXAMITEM":
                        {
                            // 포인트
                            let isOver = false;
                            let point = data.HASH.POINT;

                            // 체크된 elm
                            let arrElmCh = [];
                            arrElmCh = $.merge(arrElmCh, $("#dev_exam_from").find(".dev_checkbox_each:checked"));

                            // 체크된 no 추출
                            let arrNoCh = [];
                            $.each(arrElmCh, function (idx, el) {

                                var value = el.value;
                                arrNoCh.push(value);
                            });

                            for (let idx_noCh = 0; idx_noCh < arrNoCh.length; idx_noCh++) {

                                // 선택된 번호
                                let noCh = arrNoCh[idx_noCh];

                                // list_2 에 이미 추가되었는지 체크
                                let isEx = false;
                                for (let idx_list_2 = 0; idx_list_2 < _this.list_2.length; idx_list_2++) {
                                    if (noCh == _this.list_2[idx_list_2].NO) {
                                        isEx = true; break;
                                    }
                                }

                                // 추가안되어 있으면 넣기
                                if (false == isEx) {

                                    let row;
                                    for (let idx_list_1 = 0; idx_list_1 < _this.list_1.length; idx_list_1++) {
                                        if (noCh == _this.list_1[idx_list_1].NO) {
                                            row = _this.list_1[idx_list_1]; break;
                                        }
                                    }

                                    if (row) {

                                        if (_this.list_2.length + 1 > point) {

                                            // 포인트 오버
                                            isOver = true;
                                        } else {

                                            // 문제 추가
                                            _this.list_2.push(row);
                                        }
                                    }
                                }
                            }

                            // 문제선택 해제
                            $(".dev_checkbox_all").prop("checked", false);
                            $(".dev_checkbox_each").prop("checked", false);

                            if (isOver == true) {
                                alert("포인트가 부족하여\n일부 문제가 추가되지 않았습니다.");
                            }
                        }
                        break;

                    case "DELEXAMITEM":
                        {
                            let arrEle = [];
                            let arrSel = [];
                            arrEle = $.merge(arrEle, $("#dev_exam_to").find(".dev_checkbox_each:checked"));

                            // value 추출
                            $.each(arrEle, function (idx, el) {

                                var value = el.value;
                                arrSel.push(value);
                            });

                            for (let idx_sel = 0; idx_sel < arrSel.length; idx_sel++) {

                                // 선택된 번호
                                let noSel = arrSel[idx_sel];

                                for (let idx_list_2 = 0; idx_list_2 < this.list_2.length; idx_list_2++) {
                                    if (noSel == this.list_2[idx_list_2].NO) {
                                        this.list_2.splice(idx_list_2, 1);
                                        break;
                                    }
                                }
                            }

                            // 문제선택 해제
                            $(".dev_checkbox_all").prop("checked", false);
                            $(".dev_checkbox_each").prop("checked", false);
                        }
                        break;

                    case "SAVEPRINTITEM":
                        {
                            let no = data.HASH.NO;
                            this.$PageMove('/user/exam_print/list?no=' + no);
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // 라텍스 조회
            , getLatex: function () {

                // this
                let _this = this;

                window.removeEventListener("message", _this.getLatexReturn, false);
                window.addEventListener("message", _this.getLatexReturn);

                FrameLayer.open("https://js.hyper-nomad.com/module/matheditor/index.html", '720px', '520px');
            }
            , getLatexReturn: function (event) {

                // this
                let _this = this;

                let $span = $(event.data);
                let $svg = $span.find("svg");

                let val = $svg.attr("data-latex");
                if (val) {
                    let $li = $("<li class='dev_search_latex_li pu_search_latex_li'></li>")
                    $li.append($svg);
                    $(".dev_search_latex_ul").append($li);
                }

                FrameLayer.close();

                // 검색
                _this.exec("SEARCH", 1);
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }
    }

</script>