<template>

    <div class="pu_wd_def_min pu_wrapper_main">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(s) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_banner_top />
            <inc_user_left />
            <inc_user_title />
            <!-------------------------- 인클루드(s) -------------------------->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <form id="regiForm" onsubmit="return false">

                <!--전달값-->
                <input type="hidden" name="no" v-model="view_1.NO" />
                <!--//전달값-->

                <div class="pu_wd_def_max pu_layoutContent">
                    <div>
                        <table class="pu_table_view_2">
                            <colgroup>
                                <col width="10%">
                                <col width="40%">
                                <col width="10%">
                                <col width="40%">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>교육과정<strong>*</strong></th>
                                    <td colspan="3">
                                        <select class="" name="code_subject_1" v-model="code_subject_depth_1"
                                                v-on:change="exec('SUBJECT', 2, '', $event)" title="과목을 선택하세요.">
                                            <option value="">- 과목 선택</option>
                                            <option v-for="(row, idx) in opt_code_subject_depth_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                        </select>

                                        <select class="" name="code_subject_2" v-model="code_subject_depth_2"
                                                v-on:change="exec('SUBJECT', 3, '', $event)" title="개정년도를 선택하세요.">
                                            <option value="">- 개정년도 선택</option>
                                            <option v-for="(row, idx) in opt_code_subject_depth_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                        </select>

                                        <select class="" name="code_subject_3" v-model="code_subject_depth_3"
                                                v-on:change="exec('SUBJECT', 4, '', $event)" title="교육과정을 선택하세요.">
                                            <option value="">- 교육과정 선택</option>
                                            <option v-for="(row, idx) in opt_code_subject_depth_3" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                        </select>

                                        <select class="" name="code_subject_4" v-model="code_subject_depth_4"
                                                v-on:change="exec('SUBJECT', 5, '', $event)" title="대단원을 선택하세요.">
                                            <option value="">- 대단원 선택</option>
                                            <option v-for="(row, idx) in opt_code_subject_depth_4" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                        </select>

                                        <select class="" name="code_subject_5" v-model="code_subject_depth_5"
                                                title="중단원을 선택하세요.">
                                            <option value="">- 중단원 선택</option>
                                            <option v-for="(row, idx) in opt_code_subject_depth_5" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                        </select>
                                    </td>
                                </tr>

                                <tr>
                                    <th>학교</th>
                                    <td colspan="3">
                                        <select class="" name="code_area_1" v-model="code_area_depth_1"
                                                v-on:change="exec('AREA', 2, '', $event)" title="시도를 선택하세요.">
                                            <option value="">- 시도 선택</option>
                                            <option v-for="(row, idx) in opt_code_area_depth_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                        </select>
                                        <select class="" name="code_area_2" v-model="code_area_depth_2"
                                                v-on:change="exec('AREA', 3, '', $event)" title="구군을 선택하세요.">
                                            <option value="">- 구군 선택</option>
                                            <option v-for="(row, idx) in opt_code_area_depth_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                        </select>
                                        <select class="" name="code_area_3" v-model="code_area_depth_3"
                                                v-on:change="exec('AREA', 4, '', $event)" title="초중고를 선택하세요.">
                                            <option value="">- 초중고 선택</option>
                                            <option v-for="(row, idx) in opt_code_area_depth_3" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                        </select>
                                        <select class="" name="code_area_4" v-model="code_area_depth_4"
                                                title="학교를 선택하세요.">
                                            <option value="">- 학교 선택</option>
                                            <option v-for="(row, idx) in opt_code_area_depth_4" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                        </select>
                                    </td>
                                </tr>

                                <tr>
                                    <th>타입<strong>*</strong></th>
                                    <td>
                                        <select class="" name="gubun_exam_type" v-model="view_1.GUBUN_EXAM_TYPE"
                                                title="타입을 선택하세요.">
                                            <option value="A">선택</option>
                                            <option value="B">서술</option>
                                        </select>
                                    </td>
                                    <th>난이도<strong>*</strong></th>
                                    <td>
                                        <select class="" name="gubun_exam_diff" v-model="view_1.GUBUN_EXAM_DIFF"
                                                title="난이도를 선택하세요.">
                                            <option value="1">하</option>
                                            <option value="2">중</option>
                                            <option value="3">상</option>
                                            <option value="4">킬러</option>
                                        </select>
                                    </td>
                                </tr>

                                <tr>
                                    <th>년도</th>
                                    <td>
                                        <select class="" name="school_year" v-model="view_1.SCHOOL_YEAR"
                                                title="년도를 선택하세요.">
                                            <option value="">- 년도 선택</option>
                                            <option v-for="(row, idx) in opt_school_year" v-bind:value="row.YEAR">{{ row.YEAR }}</option>
                                        </select>
                                    </td>
                                    <th>학기</th>
                                    <td>
                                        <select class="" name="school_semester" v-model="view_1.SCHOOL_SEMESTER"
                                                title="학기를 선택하세요.">
                                            <option value="">- 학기 선택</option>
                                            <option v-for="(row, idx) in opt_school_semester" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                        </select>
                                    </td>
                                </tr>

                                <tr>
                                    <th>(질문/해설) 내용<strong>*</strong></th>
                                    <td colspan="3">
                                        <textarea name="contents" class="" v-html="view_1.CONTENTS"></textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="pu_divBtnMain">
                        <div class="pu_divLeft">
                            <a class="pu_btn_dark" href="javascript:;" v-on:click="$PageMove('./list')"><i class="xi-bars"></i> 목록</a>
                            <a class="dev_btn_submit pu_btn_red" href="javascript:;"><i class="xi-pen"></i> 저장</a>
                        </div>
                        <div class="pu_divCenter">
                        </div>
                        <div class="pu_divRight">
                        </div>
                    </div>

                </div>
            </form>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 서브 실제 컨텐츠 영역 (e) -------------------------->
        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGI"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , cnt_lim_pic: 5

                , view_1: {}

                , opt_code_subject_depth_1: [] // 과목 선택 옵션
                , opt_code_subject_depth_2: []
                , opt_code_subject_depth_3: []
                , opt_code_subject_depth_4: []
                , opt_code_subject_depth_5: []

                , code_subject_depth_1: ''     // 과목 선택 코드
                , code_subject_depth_2: ''
                , code_subject_depth_3: ''
                , code_subject_depth_4: ''
                , code_subject_depth_5: ''

                , opt_code_area_depth_1: [] // 학교 선택 옵션
                , opt_code_area_depth_2: []
                , opt_code_area_depth_3: []
                , opt_code_area_depth_4: []

                , code_area_depth_1: ''     // 학교 선택 코드
                , code_area_depth_2: ''
                , code_area_depth_3: ''
                , code_area_depth_4: ''

                , opt_school_year: [] // 년도

                , opt_school_semester: [] // 학기
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                _this.exec("INIT");
            }

            // 실행
            , exec: function (gubun, num, code, event) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.$GetQueryMap();
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "INIT":
                        {
                            // url 설정
                            url = "/vue_api/user/ngd_edit/view";

                            // 데이터 설정
                            data["no"] = qs.no;
                            data["type"] = qs.type;
                        }
                        break;

                    case "SUBJECT":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_make/code_subject";

                            data["depth"] = num;

                            if (code) {
                                data["code"] = code;
                            }
                            else if (event) {
                                data["code"] = event.target.value;
                            }
                        }
                        break;

                    case "AREA":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_make/code_area";

                            data["depth"] = num;

                            if (code) {
                                data["code"] = code;
                            }
                            else if (event) {
                                data["code"] = event.target.value;
                            }
                        }
                        break;

                    case "SAVE":
                        {
                            // url 설정
                            url = "/vue_api/user/ngd_edit/save";

                            var code_subject_1 = $("[name='code_subject_1']");
                            if (!code_subject_1.val().trim()) {
                                alert("과목을 선택해주세요");
                                code_subject_1.focus();
                                return false;
                            }

                            var code_subject_2 = $("[name='code_subject_2']");
                            if (!code_subject_2.val().trim()) {
                                alert("개정년도를 선택해주세요");
                                code_subject_2.focus();
                                return false;
                            }

                            var code_subject_3 = $("[name='code_subject_3']");
                            if (!code_subject_3.val().trim()) {
                                alert("교육과정을 선택해주세요");
                                code_subject_3.focus();
                                return false;
                            }

                            var code_subject_4 = $("[name='code_subject_4']");
                            if (!code_subject_4.val().trim()) {
                                alert("대단원을 선택해주세요");
                                code_subject_4.focus();
                                return false;
                            }

                            var code_subject_5 = $("[name='code_subject_5']");
                            if (!code_subject_5.val().trim()) {
                                alert("중단원을 선택해주세요");
                                code_subject_5.focus();
                                return false;
                            }

                            // 내용
                            CKEDITOR.instances.contents.updateElement();
                            var contents = $("[name='contents']");
                            if (!contents.val().trim()) {
                                alert("내용을 입력해주세요");
                                contents.focus();
                                return false;
                            }

                            if (contents.val().indexOf("dev_math_item_svg_law") > -1) {
                                alert("붉은색으로 표시된 수식을 수정해주세요.");
                                return false;
                            }

                            if (!confirm(INFO_CONF_SAVE)) {
                                return;
                            }
                            else {

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#regiForm")));
                                data["type"] = qs.type;

                                // dimm 보이기
                                _this.$ShowDimmLogo();
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "INIT":
                        {
                            _this.view_1 = data.VIEW_1; // 저장된 데이터

                            _this.opt_code_subject_depth_1 = data.MAP_LIST_1.OPT_CODE_SUBJECT_DEPTH_1; // 과목코드 옵션
                            _this.opt_code_subject_depth_2 = data.MAP_LIST_1.OPT_CODE_SUBJECT_DEPTH_2;
                            _this.opt_code_subject_depth_3 = data.MAP_LIST_1.OPT_CODE_SUBJECT_DEPTH_3;
                            _this.opt_code_subject_depth_4 = data.MAP_LIST_1.OPT_CODE_SUBJECT_DEPTH_4;
                            _this.opt_code_subject_depth_5 = data.MAP_LIST_1.OPT_CODE_SUBJECT_DEPTH_5;

                            _this.code_subject_depth_1 = data.VIEW_1.CODE_SUBJECT_DEPTH_1;       // 선택된 과목코드
                            _this.code_subject_depth_2 = data.VIEW_1.CODE_SUBJECT_DEPTH_2;
                            _this.code_subject_depth_3 = data.VIEW_1.CODE_SUBJECT_DEPTH_3;
                            _this.code_subject_depth_4 = data.VIEW_1.CODE_SUBJECT_DEPTH_4;
                            _this.code_subject_depth_5 = data.VIEW_1.CODE_SUBJECT_DEPTH_5;

                            _this.opt_code_area_depth_1 = data.MAP_LIST_1.OPT_CODE_AREA_DEPTH_1; // 학교코드 옵션
                            _this.opt_code_area_depth_2 = data.MAP_LIST_1.OPT_CODE_AREA_DEPTH_2;
                            _this.opt_code_area_depth_3 = data.MAP_LIST_1.OPT_CODE_AREA_DEPTH_3;
                            _this.opt_code_area_depth_4 = data.MAP_LIST_1.OPT_CODE_AREA_DEPTH_4;
                            _this.opt_code_area_depth_5 = data.MAP_LIST_1.OPT_CODE_AREA_DEPTH_5;

                            _this.code_area_depth_1 = data.VIEW_1.CODE_AREA_DEPTH_1;       // 선택된 학교코드
                            _this.code_area_depth_2 = data.VIEW_1.CODE_AREA_DEPTH_2;
                            _this.code_area_depth_3 = data.VIEW_1.CODE_AREA_DEPTH_3;
                            _this.code_area_depth_4 = data.VIEW_1.CODE_AREA_DEPTH_4;
                            _this.code_area_depth_5 = data.VIEW_1.CODE_AREA_DEPTH_5;

                            _this.opt_school_year = data.MAP_LIST_1.OPT_SCHOOL_YEAR; // 년도

                            _this.opt_school_semester = data.MAP_LIST_1.OPT_SCHOOL_SEMESTER; // 학기

                            // 타입
                            if (!data.VIEW_1.GUBUN_EXAM_TYPE) {
                                data.VIEW_1.GUBUN_EXAM_TYPE = "A";
                            }

                            // 난이도
                            if (!data.VIEW_1.GUBUN_EXAM_DIFF) {
                                data.VIEW_1.GUBUN_EXAM_DIFF = "1";
                            }

                            // 년도
                            if (!data.VIEW_1.SCHOOL_YEAR) {
                                data.VIEW_1.SCHOOL_YEAR = "";
                            }

                            // 학기
                            if (!data.VIEW_1.SCHOOL_SEMESTER) {
                                data.VIEW_1.SCHOOL_SEMESTER = "";
                            }

                            // 저장된 데이터가 없으면 과목코드 옵션 조회
                            if (!_this.opt_code_subject_depth_1 || _this.opt_code_subject_depth_1.length == 0) {
                                _this.exec("SUBJECT", 1, "000|000|000|000|000|");
                            }

                            // 저장된 데이터가 없으면 학교코드 옵션 조회
                            if (!_this.opt_code_area_depth_1 || _this.opt_code_area_depth_1.length == 0) {
                                _this.exec("AREA", 1, "000|000|000|000|");
                            }

                            // ck에디터 설정
                            _this.setCkEditor();
                        }
                        break;

                    case "SUBJECT":
                        {
                            let depth = data.HASH.DEPTH;
                            switch (depth) {
                                case 1:
                                    _this.opt_code_subject_depth_1 = data.LIST_1; _this.code_subject_depth_1 = "";
                                    _this.opt_code_subject_depth_2 = []; _this.code_subject_depth_2 = "";
                                    _this.opt_code_subject_depth_3 = []; _this.code_subject_depth_3 = "";
                                    _this.opt_code_subject_depth_4 = []; _this.code_subject_depth_4 = "";
                                    _this.opt_code_subject_depth_5 = []; _this.code_subject_depth_5 = "";
                                    break;

                                case 2:
                                    _this.opt_code_subject_depth_2 = data.LIST_1; _this.code_subject_depth_2 = "";
                                    _this.opt_code_subject_depth_3 = []; _this.code_subject_depth_3 = "";
                                    _this.opt_code_subject_depth_4 = []; _this.code_subject_depth_4 = "";
                                    _this.opt_code_subject_depth_5 = []; _this.code_subject_depth_5 = "";
                                    break;

                                case 3:
                                    _this.opt_code_subject_depth_3 = data.LIST_1; _this.code_subject_depth_3 = "";
                                    _this.opt_code_subject_depth_4 = []; _this.code_subject_depth_4 = "";
                                    _this.opt_code_subject_depth_5 = []; _this.code_subject_depth_5 = "";
                                    break;

                                case 4:
                                    _this.opt_code_subject_depth_4 = data.LIST_1; _this.code_subject_depth_4 = "";
                                    _this.opt_code_subject_depth_5 = []; _this.code_subject_depth_5 = "";
                                    break;

                                case 5:
                                    _this.opt_code_subject_depth_5 = data.LIST_1; _this.code_subject_depth_5 = "";
                                    break;
                            }
                        }
                        break;

                    case "AREA":
                        {
                            let depth = data.HASH.DEPTH;
                            switch (depth) {
                                case 1:
                                    _this.opt_code_area_depth_1 = data.LIST_1; _this.code_area_depth_1 = "";
                                    _this.opt_code_area_depth_2 = []; _this.code_area_depth_2 = "";
                                    _this.opt_code_area_depth_3 = []; _this.code_area_depth_3 = "";
                                    _this.opt_code_area_depth_4 = []; _this.code_area_depth_4 = "";
                                    break;

                                case 2:
                                    _this.opt_code_area_depth_2 = data.LIST_1; _this.code_area_depth_2 = "";
                                    _this.opt_code_area_depth_3 = []; _this.code_area_depth_3 = "";
                                    _this.opt_code_area_depth_4 = []; _this.code_area_depth_4 = "";
                                    break;

                                case 3:
                                    _this.opt_code_area_depth_3 = data.LIST_1; _this.code_area_depth_3 = "";
                                    _this.opt_code_area_depth_4 = []; _this.code_area_depth_4 = "";
                                    break;

                                case 4:
                                    _this.opt_code_area_depth_4 = data.LIST_1; _this.code_area_depth_4 = "";
                                    break;
                            }
                        }
                        break;

                    case "SAVE":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // ck editor 세팅
            , setCkEditor: function () {

                // this
                let _this = this;

                setTimeout(() => {

                    try {
                        setCkEditorDefault("contents", "exam_make", this.cnt_lim_pic, ".jpeg,.jpg,.png");
                    } catch (err) {
                        _this.setCkEditor();
                    }
                }, 100);
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }
    }

</script>