<template>

    <div class="pu_wd_def_min pu_wrapper_main">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(s) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_banner_top />
            <inc_user_left />
            <inc_user_title />
            <!-------------------------- 인클루드(s) -------------------------->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <form id="regiForm" onsubmit="return false">

                <!--전달값-->
                <input type="hidden" name="no" v-model="view_1.NO" />
                <!--//전달값-->

                <div class="pu_wd_def_max pu_layoutContent">
                    <div>
                        <table class="pu_table_view_2">
                            <colgroup>
                                <col width="20%">
                                <col width="80%">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>제목<strong>*</strong></th>
                                    <td>
                                        <input type="text" name="title" v-model:value="view_1.TITLE" style="width:100%">
                                    </td>
                                </tr>

                                <tr>
                                    <th>바닥글<strong>*</strong></th>
                                    <td>
                                        <textarea name="contents" class="pu_input_textarea" v-html="view_1.CONTENTS"></textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="pu_divBtnMain">
                        <div class="pu_divLeft">
                            <a class="pu_btn_dark" href="javascript:;" v-on:click="$PageMove('./list')"><i class="xi-bars"></i> 목록</a>
                            <a class="dev_btn_submit pu_btn_red" href="javascript:;"><i class="xi-pen"></i> 저장</a>
                        </div>
                        <div class="pu_divCenter">
                        </div>
                        <div class="pu_divRight">
                        </div>
                    </div>

                </div>
            </form>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 서브 실제 컨텐츠 영역 (e) -------------------------->
        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGI"

                , cnt_lim_pic: 5
                , view_1: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                _this.exec("INIT");
            }

            // 실행
            , exec: function (gubun, depth, code, event) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.$GetQueryMap();
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "INIT":
                        {
                            // url 설정
                            url = "/vue_api/user/my_foot/view";

                            // 데이터 설정
                            data["no"] = qs.no;
                        }
                        break;

                    case "SAVE":
                        {
                            // url 설정
                            url = "/vue_api/user/my_foot/save";

                            var title = $("[name='title']");
                            if (!title.val().trim()) {
                                alert("제목을 입력해주세요");
                                title.focus();
                                return false;
                            }

                            // 내용
                            CKEDITOR.instances.contents.updateElement();

                            var contents = $("[name='contents']");
                            if (!contents.val().trim()) {
                                alert("바닥글을 입력해주세요");
                                contents.focus();
                                return false;
                            }

                            if (!confirm(INFO_CONF_SAVE)) {
                                return;
                            }
                            else {

                                // 데이터 복사
                                Object.assign(data, _this.$FormToMap($("#regiForm")));

                                // dimm 보이기
                                _this.$ShowDimmLogo();
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "INIT":
                        {
                            // 저장된 데이터
                            _this.view_1 = data.VIEW_1;

                            // ck에디터 설정
                            _this.setCkEditor();
                        }
                        break;

                    case "SAVE":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // ck editor 세팅
            , setCkEditor: function () {

                // this
                let _this = this;

                setTimeout(() => {

                    try {
                        setCkEditorDefault("contents", "my_foot", _this.cnt_lim_pic, ".jpeg,.jpg,.png");
                    } catch (err) {
                        _this.setCkEditor();
                    }
                }, 100);
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }
    }

</script>