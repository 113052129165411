<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">
            <inc_zadmin_head />
            <inc_zadmin_left />
            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">
                    <inc_zadmin_title />
                    <!-- 검색조건 (s) -->
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <div class="pu_TableY01">
                                <table>
                                    <colgroup>
                                        <col width="15%">
                                        <col width="*">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>제목</th>
                                            <td>{{ view_1.TITLE }}</td>
                                        </tr>
                                        <tr>
                                            <th>이미지</th>
                                            <td v-html="view_1.HTML_IMG_ITEM_VIEW"></td>
                                        </tr>
                                        <tr>
                                            <th>팝업크기</th>
                                            <td>가로 : {{ view_1.WIDTH }}px, 세로 : {{ view_1.HEIGHT }}px</td>
                                        </tr>
                                        <tr>
                                            <th>링크</th>
                                            <td><a v-show="view_1.LINK" v-bind:href="view_1.LINK">{{ view_1.LINK }}</a></td>
                                        </tr>
                                        <tr>
                                            <th>게시기간</th>
                                            <td>{{ view_1.SDATE }} ~ {{ view_1.EDATE }}</td>
                                        </tr>
                                        <tr>
                                            <th>적용여부</th>
                                            <td>{{ view_1.SHOW_YN == "Y" ? "적용" : "미적용" }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->
                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_right">
                                <a v-bind:href="`./board_mainpopup_regi?no=${view_1.NO}`" class="pu_btn_blue"><i class="xi-eraser"></i> 수정</a>
                                <a href="./board_mainpopup" class="pu_btn_dark"><i class="xi-bars"></i> 목록</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: { }

        , data() {

            return {
                gubun_page: "board_mainpopup_view"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
                , searchtypevalue: "id"
            }
        }

        , methods: {

            ...mapActions({
                getBase: "getBase"
            })

            // 진입점
            , init() {
                // dimm 숨기기
                this.getSearchData(1);

                this.$HideDimmLogo();
            }
            , getSearchData: function (curPage) {

                // qs
                var qs = this.$GetQueryMap();

                // 폼
                var data = {};
                data["no"] = qs.no;

                // API 조회
                this.$SendPost("/vue_api/zadmin/homepage/board_mainpopup/view", data, this.searchDataReturn);

            }
            , searchDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1;

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }
        }

        , beforeCreate() {

        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>