<template>

    <div class="pu_wd_def_min pu_wrapper_main">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(s) -------------------------->

        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_banner_top />
            <inc_user_left />
            <inc_user_title />
            <!-------------------------- 인클루드(s) -------------------------->

            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">
                <div>
                    <table class="pu_table_view">
                        <thead>
                            <tr>
                                <th scope="col">유전자로 참깨 원산지 판별하는 기술 개발</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <ul class="pu_newsTopInofo">
                                        <li>
                                            <p class="pu_subject"><span>작성일 : </span></p>
                                            <p class="pu_info">2022-09-11</p>
                                        </li>
                                        <li>
                                            <p class="pu_subject"><span>조회 : </span></p>
                                            <p class="pu_info"><i class="xi-eye-o"></i> 2305</p>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul class="pu_newsTopInofo">
                                        <li>
                                            <p class="pu_subject"><span>첨부파일 : </span></p>
                                            <p class="pu_info">
                                                <a class="pu_attachFile"
                                                   href="javascript:;">첨부파일명_20220710.hwp</a>
                                            </p>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="pu_contents">
                                        농관원, ‘과실생장촉진제 간편분석법’ 등 실용기술도
                                        <br><br>
                                        국립농산물품질관리원(원장 김대근)은 세계 최초로 ‘수입산ㆍ국내산 혼합 참깨 원산지 판별법’, ‘첨단 분석기기를 이용한 청국장
                                        원산지 판별법’, ‘과실 생장촉진제(에세폰) 신속분석법’ 등 농식품 품질ㆍ안전관리 첨단 실용기술을 개발했다고 최근 밝혔다

                                        <br><br>
                                        이번에 개발된 ‘유전자 분석을 이용한 참깨 원산지 판별법’은 현재 이화학검정법을 이용해 원산지판별을 하는 참깨를 유전자분석을 통해
                                        원산지 판별이 가능하도록 한 것이다. 종전의 이화학적 분석법으로는 수입ㆍ국내산의 혼합품 분석이 어려웠으나, 새로 개발된
                                        유전자분석법은 정확하게 판별이 가능해 참깨의 지능적인 부정유통을 원천적으로 차단할 수 있게 됐다
                                        <br><br>
                                        출처 : 전업농신문(http://www.palnews.co.kr)
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="pu_prenext">
                                        <dl>
                                            <dt><span>이전글</span> <i class="xi-angle-up"></i></dt>
                                            <dd><a href="javascript:;">중소벤처기업부 박영선 장관님 축사</a></dd>
                                        </dl>
                                        <p class="pu_date">2022.08.30</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="pu_prenext">
                                        <dl>
                                            <dt><span>다음글</span> <i class="xi-angle-down"></i></dt>
                                            <dd><a href="javascript:;">다음글 없음</a></dd>
                                        </dl>
                                        <p class="pu_date">2022.08.30</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="pu_btnDiv01">
                        <a class="pu_btn_red" href="news_list.html"><i class="xi-bars"></i> 목록</a>
                        <a class="pu_btn_dark" href="javascript:;"><i class="xi-print"></i> 인쇄</a>
                    </div>
                </div>
            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 서브 실제 컨텐츠 영역 (e) -------------------------->

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                _this.exec("INIT");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "gubunProc": gubun };

                switch (gubun) {

                    case "INIT":
                        {
                            //url = "/vue_api/user/login/login";
                        }
                        break;

                    case "ALERT":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                console.log(data);

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "INIT":
                        {

                        }
                        break;

                    case "ALERT":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }
    }

</script>