<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <inc_zadmin_head />
            <inc_zadmin_left />

            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">

                    <inc_zadmin_title />

                    <!-- 검색조건 (s) -->
                    <section class="pu_searchSection">
                        <form id="searchForm" class="pu_searchBox" onsubmit="return false">                            
                            <dl>
                                <dt class="pu_wd90"><label for="">검색조건</label></dt>
                                <dd>
                                    <select name="search_gubun" class="pu_select-box" title="조건을 선택하세요." style="width:150px">
                                        <option value="title" selected="selected">제목</option>                                        
                                    </select>
                                    <input type="text" name="search_text" placeholder="검색어를 입력해주세요." style="width:300px">
                                </dd>
                            </dl>
                        </form>
                        <div class="pu_searchBtn"><a href="javascript:" class="dev_btn_search xi-search"><span>검색</span></a></div>
                    </section>
                    <!-- 검색조건(e) -->

                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <p class="pu_listNum">
                                <i class="xi-user"></i> 머리글 <strong>{{ rownum }}</strong>개
                            </p>
                            <div class="pu_TableX01">
                                <table>
                                    <colgroup>
                                        <col width="5%">
                                        <col width="*%">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">번호</th>
                                            <th scope="col">내용</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="dev_page_move_regi"
                                            v-for="(row, idx) in list_1">
                                            <td>
                                                <input type="hidden" class="dev_no" :value="row.NO" />
                                                {{ getRowNum(idx) }}
                                            </td>
                                            <td class="pu_leftTxt">
                                                <span style="width: 100%; height: 30px; display: block; border-bottom: solid 1px #dddddd;">{{ row.TITLE }}</span>
                                                <div v-html="$ConHtml(row.CONTENTS)"></div>
                                            </td>
                                        </tr>
                                        <tr v-if="list_1.length == 0">
                                            <td colspan="2">조회된 결과가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->

                        <div class="pu_sticky_bottom">
                            <!-- 버튼 스타일영역 (s)  -->
                            <div class="pu_btnDiv01 pu_over_hidden">

                                <article class="pu_f_left">
                                    <!--페이징-->
                                    <div class="pu_pagination" v-html="paging"></div>
                                    <!--//페이징-->
                                </article>

                                <article class="pu_f_right">
                                    <a class="pu_btn_blue" href="javascript:;" v-on:click="$PageMove('./regi')"><i class="xi-plus"></i> 바닥글 추가</a>
                                </article>
                            </div>
                            <!-- 버튼 스타일영역 (s)  -->
                        </div>

                    </section>
                    <!-- 컨텐츠영역 (e) -->

                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: { }

        , data() {

            return {
                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                getBase: "getBase"
            })

            // 진입점
            , init() {
                
                this.getSearchData(1);

                // dimm 숨기기
                this.$HideDimmLogo();
            }

            // 검색
            , getSearchData: function (curPage) {

                // qs
                var qs = this.$GetQueryMap();

                // 폼
                var data = this.$FormToMap($("#searchForm"));
                data["gubun_page"] = this.gubun_page;
                data["curPage"] = curPage;

                // API 조회
                this.$SendPost("/vue_api/zadmin/my_foot/list", data, this.searchDataReturn);
            }
            , searchDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.rownum = data.ROWNUM;
                        this.paging = data.PAGING;
                        this.list_1 = data.LIST_1;

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>