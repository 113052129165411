<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <inc_zadmin_head />
            <inc_zadmin_left />

            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">

                    <inc_zadmin_title />

                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <p class="pu_listNum">
                                <i class="xi-paper-o"></i> 총 <strong>{{ rownum }}</strong>개의 게시물이 있습니다.
                            </p>
                            <div class="pu_TableX01">
                                <table>
                                    <colgroup>
                                        <col width="10px" />
                                        <col width="45%" />
                                        <col width="45%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">번호</th>
                                            <th scope="col">등록번호</th>
                                            <th scope="col">파일명</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="dev_page_move_regi"
                                            v-for="(row, idx) in list_1">
                                            <td>
                                                {{ getRowNum(idx) }}
                                            </td>
                                            <td>
                                                <a href="javascript:;" v-on:click="$PageMove('./regi?regiNo=' + row.등록번호 + '&fileName=' + row.파일명)">{{ row.등록번호 }}</a>
                                            </td>
                                            <td>
                                                <a href="javascript:;" v-on:click="$PageMove('./regi?regiNo=' + row.등록번호 + '&fileName=' + row.파일명)">{{ row.파일명 }}</a>
                                            </td>
                                        </tr>
                                        <tr v-if="list_1.length == 0">
                                            <td colspan="3">조회된 결과가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->

                        <div class="pu_sticky_bottom">
                            <!-- 버튼 스타일영역 (s)  -->
                            <div class="pu_btnDiv01 pu_over_hidden">

                                <article class="pu_f_left">
                                    <!--페이징-->
                                    <div class="pu_pagination" v-html="paging"></div>
                                    <!--//페이징-->
                                </article>

                                <article class="pu_f_right">
                                    <!--<a class="pu_btn_blue" href="javascript:;" v-on:click="$PageMove('./regi')"><i class="xi-pen"></i> 신규등록</a>-->
                                </article>
                            </div>
                            <!-- 버튼 스타일영역 (s)  -->
                        </div>

                    </section>
                    <!-- 컨텐츠영역 (e) -->

                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {

                gubun_page: "LIST"

                , rownum: 0
                , paging: ""

                , list_1: []
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                let _this = this;

                // 검색
                _this.exec("SEARCH", 1);
            }

            // 실행
            , exec: function (gubun, num) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.$GetQueryMap();
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/file_step_1/list";

                            // 데이터
                            data["curPage"] = num;

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "SEARCH":
                        {
                            _this.rownum = data.ROWNUM;
                            _this.paging = data.PAGING;
                            _this.list_1 = data.LIST_1;
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>