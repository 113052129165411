<template>

    <!--================ Head Menu Area =================-->

    <div></div>

    <!--================ //Head Menu Area =================-->

</template>

<script>

    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        },

        data() {
            return {

                THIS_NAME: "INC ZADMIN HEAD"

            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                // 시작
                _this.exec("INIT");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "INIT":
                        {
                            // 현재 경로 & 쿼리스트링
                            this.setBase({ "pathCur": window.location.pathname });
                            this.setBase({ "qsCur": window.location.search });

                            // URL 설정
                            url = "/vue_api/common/menu/zadmin_check_grade";

                            // data 설정
                            data["url"] = this.getBase.pathCur; // 현재 url 경로
                            data["qs"] = this.getBase.qsCur;    // 현재 url 쿼리스트링

                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "INIT":
                        {
                            console.log("[[HEAD]]-------------------------------");
                            console.log(data);
                            console.log("[[HEAD]]-------------------------------");

                            // 유저 및 권한 변경시 메뉴 초기화
                            if (this.getBase.adminNo != data.ADMIN_NO
                                || this.getBase.adminGrade != data.ADMIN_GRADE) {

                                this.setBase({ "mapAdminMenu": [] });
                            }

                            // 유저 로그인 여부
                            this.setBase({ "adminLoginYn": data.ADMIN_LOGIN_YN });
                            this.setBase({ "adminNo": data.ADMIN_NO });
                            this.setBase({ "adminGrade": data.ADMIN_GRADE });
                        }
                        break;

                    default:
                        {

                        };
                }
            }
        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
            this.init();
        },

        mounted() {
            this.$PrintLog(this.THIS_NAME + " : mounted");
        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
