<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">
            <!-- 왼쪽 메뉴 layout(s) -->
                <div id="AdminNav" class="pu_LnbWrap">
                    <a  class="pu_openbtn" style="display: none;" onclick="openNav()" title="메뉴펼쳐보기"><i class="xi-bars xi-x2"></i></a>
                    <h1 class="pu_toggleN">㈜정P&C연구소 
                        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()" title="메뉴닫기"><i class="xi-dedent xi-x"></i></a>
                    </h1>
                    <nav>
                        <div class="pu_lnbmenu">
                            <ul>
                                <li><a href="list01"><span class="xi-devices"></span><span>㈜정P&C연구소</span></a></li>
                                <li><a href="list02"><span class="xi-lightbulb-o"></span><span>연구/사업</span></a></li>
                                <li><a href="list03"><span class="xi-chart-pie"></span><span>분석센터</span></a></li>
                                <li><a href="list04"><span class="xi-browser-text"></span><span>양돈정보(유료)</span></a></li>
                                <li><a href="list05"><span class="xi-cart-o"></span><span>JPNC Mall</span></a></li>
                                <li class="pu_on"><a href="list06"><span class="xi-pen"></span><span>홈페이지관리</span></a></li>
                            </ul>
                        </div>
                            <div class="pu_submenu">
                                    <ul class="pu_accordion-menu">
                                        <li class="pu_open"><div class="pu_dropdownlink">배너관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li class="pu_on"><a href="">메인배너관리</a></li>
                                                <li><a href="">하단배너관리</a></li>
                                                <li><a href="">JPNC몰 배너관리</a></li>
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">팝업관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">메인팝업관리</a></li>                                            
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">회원관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">기업회원</a></li>
                                                <li><a href="">휴먼회원</a></li>
                                                <li><a href="">탈퇴회원</a></li>
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">관리자관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">관리자계정관리</a></li>
                                                <li><a href="">관리자접속이력</a></li>
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">메뉴관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">사용자 메뉴관리</a></li>
                                                <li><a href="">관리자 메뉴관리</a></li>
                                            </ul>
                                        </li>
                                        <li><div class="pu_dropdownlink">footer관리<i class="xi-play"></i></div>
                                            <ul class="pu_submenuItems">
                                                <li><a href="">회사관리</a></li>
                                                <li><a href="">이용약관</a></li>
                                                <li><a href="">개인정보취급방침</a></li>
                                            </ul>
                                        </li>
                                        </ul>
                            <!-- submenu E -->
     
                        </div>
                    </nav>
                </div>
                <!--왼쪽 메뉴 layout(e) -->

                <!-- 컨텐츠 layout (s) -->
                <div id ="main" class="pu_ContentsWrap">
                    <div class="pu_ContentsDiv">
                        <!-- top title (s) -->
                        <section class="pu_locationSection">
                            <h2>메인배너관리</h2>
                            <div>
                                <ul class="pu_location">
                                    <li><a href="#"><i class="xi-home">홈</i></a></li>
                                    <li>홈페이지관리</li>
                                    <li>배너관리 </li>
                                    <li>메인배너관리 </li>
                                </ul>
                                <div class="pu_logout"><a href="login.html" class="xi-unlock-o" title="로그아웃"><span>로그아웃</span></a></div>
                            </div>
                        </section>
                        <!-- top title (e) -->

                        <!-- 검색조건 (s) -->
                        <section class="pu_searchSection">
                            <div class="pu_searchBox">
                                <dl>
                                    <dt class="pu_wd90"><label for="">검색조건</label></dt>
                                    <dd>
                                        <select name="" class="pu_select-box" title="조건을선택하세요." style="width:150px">
                                            <option value="" selected>선택하세요</option>
                                            <option value="">제목</option>
                                            <option value="">내용</option>
                                        </select>
                                        <input type="text" placeholder="검색어를 입력해주세요." style="width:300px">
                                    </dd>
                                </dl>
                                <dl>
                                    <dt class="pu_wd90"><label for="">적용여부</label></dt>
                                    <dd>
                                        <div>
                                            <input type="radio" id="radio01" name="radioset01" checked=""><label for="radio01">전체</label>
                                            <input type="radio" id="radio02" name="radioset01"><label for="radio02">미적용</label>
                                            <input type="radio" id="radio03" name="radioset01"><label for="radio03">적용</label>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                            <div class="pu_searchBtn"><a href="#" class="xi-search"><span>검색</span></a></div>
                        </section>
                        <!-- 검색조건(e) -->

                        <!-- 컨텐츠영역 (s) -->
                        <section class="pu_contentsSection pu_pd15">
                            <!-- 테이블영역 (s)  -->
                            <div class="pu_listTable01">
                                <p class="pu_listNum"><i class="xi-paper-o"></i> 총 <strong>10</strong>개의 게시물이 있습니다</p>
                                <div class="pu_TableX01">
                                    <table>
                                        <colgroup>
                                            <col width="4%">
                                            <col width="5%">
                                            <col width="7%">
                                            <col width="*">
                                            <col width="5%">
                                            <col width="6%">
                                            <col width="15%">
                                            <col width="6%">
                                            <col width="6%">
                                            <col width="8%">
                                          </colgroup>
                                        <thead>
                                          <tr>
                                            <th scope="col"><input type="checkbox" name="" id="" class="pu_tableCheck"></th>
                                            <th scope="col">번호</th>
                                            <th scope="col">순서</th>
                                            <th scope="col">제목</th>
                                            <th scope="col">이미지</th>
                                            <th scope="col">링크</th>
                                            <th scope="col">게시기간</th>
                                            <th scope="col">적용여부</th>
                                            <th scope="col">등록자</th>
                                            <th scope="col">등록일</th>
                                          </tr>
                                        </thead>
                                          <tbody>
                                          <tr>
                                            <td><input type="checkbox" name="" id="" class="pu_tableCheck"></td>
                                            <td>10</td>
                                            <td>
                                                <div class="pu_updownDiv"><input type="image" src="/images/icon_dropup.png" title="위로">
                                                    <input type="image" src="/images/icon_dropdown.png" title="아래로">
                                                </div>
                                            </td>
                                            <td class="pu_leftTxt"><a href="">공지사항입니다. 감사합니다.</a></td>
                                            <td><a href=""><img src="/images/icon_image.png" alt="이미지"></a></td>
                                            <td><a href="" class="pu_ss_btn_dark'">바로가기</a></td>
                                            <td>2022-08-10~2022-10-28 </td>
                                            <td><span class="pu_txtBlue">적용</span></td>
                                            <td>홍길동</td>
                                            <td>2022-08-10</td>
                                          </tr>
                                          <tr>
                                            <td><input type="checkbox" name="" id="" class="pu_tableCheck"></td>
                                            <td>10</td>
                                            <td>
                                                <div class="pu_updownDiv"><input type="image" src="/images/icon_dropup.png" title="위로">
                                                    <input type="image" src="/images/icon_dropdown.png" title="아래로">
                                                </div>
                                            </td>
                                            <td class="pu_leftTxt"><a href="">공지사항입니다. 감사합니다.</a></td>
                                            <td><a href=""><img src="/images/icon_image.png" alt="이미지"></a></td>
                                            <td><a href="" class="pu_ss_btn_dark'">바로가기</a></td>
                                            <td>2022-08-10~2022-10-28 </td>
                                            <td><span class="pu_txtBlue">적용</span></td>
                                            <td>홍길동</td>
                                            <td>2022-08-10</td>
                                          </tr>
                                          <tr>
                                            <td><input type="checkbox" name="" id="" class="pu_tableCheck"></td>
                                            <td>10</td>
                                            <td>
                                                <div class="pu_updownDiv"><input type="image" src="/images/icon_dropup.png" title="위로">
                                                    <input type="image" src="/images/icon_dropdown.png" title="아래로">
                                                </div>
                                            </td>
                                            <td class="pu_leftTxt"><a href="">공지사항입니다. 감사합니다.</a></td>
                                            <td><a href=""><img src="/images/icon_image.png" alt="이미지"></a></td>
                                            <td></td>
                                            <td>2022-08-10~2022-10-28 </td>
                                            <td><span class="pu_txtGrey">미적용</span></td>
                                            <td>홍길동</td>
                                            <td>2022-08-10</td>
                                          </tr>
                                          <tr>
                                            <td><input type="checkbox" name="" id="" class="pu_tableCheck"></td>
                                            <td>10</td>
                                            <td>
                                                <div class="pu_updownDiv"><input type="image" src="/images/icon_dropup.png" title="위로">
                                                    <input type="image" src="/images/icon_dropdown.png" title="아래로">
                                                </div>
                                            </td>
                                            <td class="pu_leftTxt"><a href="">공지사항입니다. 감사합니다.</a></td>
                                            <td></td>
                                            <td></td>
                                            <td>2022-08-10~2022-10-28 </td>
                                            <td><span class="pu_txtGrey">미적용</span></td>
                                            <td>홍길동</td>
                                            <td>2022-08-10</td>
                                          </tr>
                                          <tr>
                                            <td><input type="checkbox" name="" id="" class="pu_tableCheck"></td>
                                            <td>10</td>
                                            <td>
                                                <div class="pu_updownDiv"><input type="image" src="/images/icon_dropup.png" title="위로">
                                                    <input type="image" src="/images/icon_dropdown.png" title="아래로">
                                                </div>
                                            </td>
                                            <td class="pu_leftTxt"><a href="">공지사항입니다. 감사합니다.</a></td>
                                            <td><a href=""><img src="/images/icon_image.png" alt="이미지"></a></td>
                                            <td></td>
                                            <td>2022-08-10~2022-10-28 </td>
                                            <td><span class="pu_txtGrey">미적용</span></td>
                                            <td>홍길동</td>
                                            <td>2022-08-10</td>
                                          </tr>
                                          <tr>
                                            <td><input type="checkbox" name="" id="" class="pu_tableCheck"></td>
                                            <td>10</td>
                                            <td>
                                                <div class="pu_updownDiv"><input type="image" src="/images/icon_dropup.png" title="위로">
                                                    <input type="image" src="/images/icon_dropdown.png" title="아래로">
                                                </div>
                                            </td>
                                            <td class="pu_leftTxt"><a href="">공지사항입니다. 감사합니다.</a></td>
                                            <td><a href=""><img src="/images/icon_image.png" alt="이미지"></a></td>
                                            <td><a href="" class="pu_ss_btn_dark'">바로가기</a></td>
                                            <td>2022-08-10~2022-10-28 </td>
                                            <td><span class="pu_txtBlue">적용</span></td>
                                            <td>홍길동</td>
                                            <td>2022-08-10</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                </div>
                            </div>
                            <!-- 테이블영역 (e)  -->
                            <!-- 하단 페이징영역 (s)  -->
                            <div class="pu_paging">
                                <div class="pu_pagination">
                                    <a href="" class="pu_first" title="처음페이지로 이동"><span>처음</span></a>
                                    <a href="" class="pu_prev" title="이전페이지로 이동"><span>이전</span></a>
                                    <a href="" class="pu_on">1</a>
                                    <a href="" class="">2</a>
                                    <a href="" class="">3</a>
                                    <a href="" class="">4</a>
                                    <a href="" class="">5</a>
                                    <a href="" class="">6</a>
                                    <a href="" class="">7</a>
                                    <a href="" class="">8</a>
                                    <a href="" class="">9</a>
                                    <a href="" class="">10</a>
                                    <a href="" class="pu_next" title="다음페이지로 이동"><span>다음</span></a>
                                    <a href="" class="pu_last" title="마지막페이지로 이동"><span>마지막</span></a>
                                  </div>
                            </div>
                            <!-- 하단 페이징영역 (d)  -->
                            <!-- 버튼 스타일영역 (s)  -->
                            <div class="pu_btnDiv01 pu_over_hidden">
                                <article class="pu_f_left">
                                    <a href="" class="pu_btn_red"><i class="xi-trash"></i> 선택삭제</a>
                                </article>
                                <article class="pu_f_right">
                                    <a href="" class="pu_btn_blue"><i class="xi-pen"></i> 신규등록</a>
                                </article>
                            </div>
                            <!-- 버튼 스타일영역 (s)  -->
                        </section>
                        <!-- 컨텐츠영역 (e) -->

                    </div>
                    <div class="pu_pb20"></div>
                </div>
                <!-- 컨텐츠 layout  (e) -->
        </div>

    </div>
</template>
<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "REGIFORM"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}

                , id: "" // 아이디
                , pw: "" // 비밀번호

            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // enter 키 이벤트
                this.$SetEventEnter("id", function () { $("[name='pw']").focus(); });
                this.$SetEventEnter("pw", this.saveData);

                // dimm 숨기기
                this.$HideDimmLogo();

            }

            // 저장
            , saveData () {

                // 비밀번호
                var id = $("[name='id']");
                if (!id.val().trim()) {
                    alert("아이디를 입력해주세요");
                    id.focus();
                    return false;
                }

                // 비밀번호
                var pw = $("[name='pw']");
                if (!pw.val().trim()) {
                    alert("비밀번호를 입력해주세요");
                    pw.focus();
                    return false;
                }
                
                var qs = this.$GetQueryMap();
                var data = this.$FormToMap($("#regiForm"));
                data["gubun_page"] = this.gubun_page;

                console.log(pw);

                // API 조회
                this.$SendPost("/vue_api/zadmin/login", data, this.saveEventReturn);

            }

            // 저장 데이터
            , saveEventReturn (data, err) {

            }

        }

        , beforeCreate() {

        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>