<template>

    <div class="pu_wd_def_min pu_wrapper_main">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(s) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_banner_top />
            <inc_user_left />
            <inc_user_title />
            <!-------------------------- 인클루드(s) -------------------------->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">

                <!-- 검색영역(s) -->
                <form id="searchForm" onsubmit="return false">
                    <div class="pu_listSearchMulti">
                        <div class="pu_wd_def_max">

                            <dl class="">
                                <dt style="width: 105px;"><label>용지 설정</label></dt>
                                <dd>
                                    <input type="radio" name="paperType" id="paperType_A4_V" value="A4_V" v-model="hash.PAPERTYPE" v-on:click="chagePaperSize('A4_V')" />
                                    <label for="paperType_A4_V">A4 세로</label>

                                    <input type="radio" name="paperType" id="paperType_A4_H" value="A4_H" v-model="hash.PAPERTYPE" v-on:click="chagePaperSize('A4_H')" />
                                    <label for="paperType_A4_H">A4 가로</label>

                                    <input type="radio" name="paperType" id="paperType_B4_V" value="B4_V" v-model="hash.PAPERTYPE" v-on:click="chagePaperSize('B4_V')" />
                                    <label for="paperType_B4_V">B4 세로</label>
                                </dd>
                                <dt style="margin-left: 25px; width: 120px;"><label>레이아웃 설정</label></dt>
                                <dd>
                                    <input type="radio" name="colType" id="colType_2" value="2" v-model="hash.COLTYPE" v-on:click="chagePaperCol(2)" />
                                    <label for="colType_2">양쪽</label>

                                    <input type="radio" name="colType" id="colType_1" value="1" v-model="hash.COLTYPE" v-on:click="chagePaperCol(1)" />
                                    <label for="colType_1">왼쪽</label>
                                </dd>
                            </dl>

                            <dl class="pu_searchItemHide">
                                <dt style="width: 105px;"><label>머리글 문구</label></dt>
                                <dd>
                                    <input type="text" name="head_left_1" placeholder="[[LEFT_1]]" style="">
                                    <input type="text" name="head_title_1" placeholder="[[TITLE_1]]" style="">
                                    <input type="text" name="head_right_1" placeholder="[[RIGHT_1]]" style="">
                                    <br />
                                    <input type="text" name="head_left_2" placeholder="[[LEFT_2]]" style="">
                                    <input type="text" name="head_title_2" placeholder="[[TITLE_2]]" style="">
                                    <input type="text" name="head_right_2" placeholder="[[RIGHT_2]]" style="">
                                </dd>
                            </dl>

                            <dl class="pu_searchItemHide">
                                <dt style="width: 105px;"><label>바닥글 문구</label></dt>
                                <dd>
                                    <input type="text" name="foot_left" placeholder="[[LEFT]]" style="">
                                    <input type="text" name="foot_right" placeholder="[[RIGHT]]" style="">
                                </dd>
                            </dl>

                            <dl class="pu_searchItemHide">
                                <dt style="width: 105px;"><label>머리글 양식</label></dt>
                                <dd>
                                    <ul class="pu_btn_item_ul">
                                        <li v-for="(row, idx) in list_head">
                                            <a v-bind:class="[ idx == head_idx ? 'pu_active' : '' ]"
                                               href="javascript:;" v-on:click="setHead(idx)">
                                                {{ row.TITLE }}
                                            </a>
                                        </li>
                                    </ul>
                                </dd>
                            </dl>

                            <dl class="pu_searchItemHide">
                                <dt style="width: 105px;"><label>바닥글 양식</label></dt>
                                <dd>
                                    <ul class="pu_btn_item_ul">
                                        <li v-for="(row, idx) in list_foot">
                                            <a v-bind:class="[ idx == foot_idx ? 'pu_active' : '' ]"
                                               href="javascript:;" v-on:click="setFoot(idx)">
                                                {{ row.TITLE }}
                                            </a>
                                        </li>
                                    </ul>
                                </dd>
                            </dl>

                            <dl class="">
                                <dt style="width: 105px;"><label>문제지</label></dt>
                                <dd>
                                    <ul class="pu_btn_item_ul">
                                        <li v-for="(row, idx) in list_print_item">
                                            <a v-bind:class="[ row.NO == print_item_no ? 'pu_active' : '' ]"
                                               href="javascript:;" v-on:click="exec('EXAMITEMLIST', row.NO)">
                                                {{ row.PRINT_TITLE }} <br />~ {{ row.PRINT_ED_DATE }}
                                            </a>
                                        </li>
                                    </ul>
                                </dd>
                            </dl>

                            <div class="pu_btn_wrap">
                                <a class="dev_btn_opt_open pu_btn_opt_open pu_ss_btn_defalut"><i class="xi-caret-down"></i> 옵션</a>
                                <a class="dev_btn_opt_close pu_btn_opt_close pu_ss_btn_grey"><i class="xi-caret-up"></i> 옵션</a>
                            </div>

                            <a class="pu_btn_print pu_btn_red" href="javascript:;" style="position: absolute; top: calc(50% - 37px); right: 10px; width: 143px; height: 70px; line-height: 70px; font-size: 21px; border-radius: 10px; cursor: pointer;"
                               v-on:click="printExamPaper()"><i class="xi-print"></i> 출력</a>
                        </div>
                    </div>
                </form>
                <!-- 검색영역(e) -->

                <!-- 리스트(s) -->
                <div id="dev_exam_paper_wrapper_main" class="pu_exam_paper_wrapper_main">

                    <!--문제 임시로 붙여넣는공간-->
                    <ul id="dev_exam_paper_wrapper_temp"></ul>
                    <!--//문제 임시로 붙여넣는공간-->
                    <!-- 시험지 템플릿 -->
                    <div class="dev_exam_paper pu_exam_paper
                                pu_exam_paper_title_show pu_exam_paper_A4_V">

                        <!--시험문제 머리글-->
                        <div class="dev_exam_paper_title pu_exam_paper_title">
                            저장된 머리글이 없습니다.
                        </div>
                        <!--//시험문제 머리글-->
                        <!--시험문제 바디-->
                        <div class="dev_exam_paper_body pu_exam_paper_body"></div>
                        <!--//시험문제 바디-->
                        <!--시험문제 바닥글-->
                        <div class="dev_exam_paper_foot pu_exam_paper_foot">
                            저장된 바닥글이 없습니다.
                        </div>
                        <!--//시험문제 바닥글-->

                    </div>
                    <!-- //시험지 템플릿  -->
                    <!--시험지 붙여넣는공간-->
                    <div id="dev_exam_paper_wrapper" class="pu_exam_paper_wrapper"></div>
                    <!--//시험지 붙여넣는공간-->

                </div>
                <!-- 리스트(e) -->

                <div class="pu_divBtnMain">
                    <div class="pu_divLeft">
                    </div>
                    <div class="pu_divCenter">
                    </div>
                    <div class="pu_divRight">
                    </div>
                </div>

            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 서브 실제 컨텐츠 영역 (e) -------------------------->
        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , paperColCnt: 2
                , paperClass: "pu_exam_paper_A4_V"
                , paperWidth: 1050   // 시험용지 가로
                , paperHeight: 1430  // 시험용지 세로
                , paperHead: 85      // 시험용지 머리글
                , paperFoot: 35      // 시험용지 바닥글
                , paperEtc: 20       // 기타

                , JQ_ExamPaper: {}  // 시험용지
                , JQ_ArrElmCol: []  // 문제 컬럼 배열
                , JQ_ArrElmItem: [] // 문제 아이템 배열

                , hash: {}

                , list_print_item: [] // 프린트 리스트
                , list_exam_item: []  // 시험문제 리스트
                , list_head: []       // 머리글 리스트
                , list_foot: []       // 바닥글 리스트

                , print_item_no: 0 // 프린트 번호
                , head_idx: 0      // 머리글 idx
                , foot_idx: 0      // 바닥글 idx

                , paperScale: 1    // 용지 스케일
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                // 시험용지 포멧
                _this.JQ_ExamPaper = $(".dev_exam_paper").detach();

                // 하단여백 조정 버튼 이벤트
                {
                    $(document).off("click", ".dev_btn_m");
                    $(document).on("click", ".dev_btn_m", function () {

                        // 여백 줄이기
                        let $li = $(this).closest(".dev_exam_paper_item");
                        $li.height(Math.max(150, $li.height() - 30));

                        // 높이에 맞춰 재설정
                        _this.setArrangeColItem();
                    });

                    $(document).off("click", ".dev_btn_p");
                    $(document).on("click", ".dev_btn_p", function () {

                        // 여백 늘리기
                        let $li = $(this).closest(".dev_exam_paper_item");
                        $li.height(Math.min((_this.paperHeight - _this.paperHead - _this.paperFoot - _this.paperEtc), $li.height() + 30));

                        // 높이에 맞춰 재설정
                        _this.setArrangeColItem();
                    });
                }

                // 머릿글 바닥글
                $(document).on(
                    "keyup",
                    "[name='head_left_1'], [name='head_title_1'], [name='head_right_1'], " +
                    "[name='head_left_2'], [name='head_title_2'], [name='head_right_2'], " +
                    "[name='foot_left'], [name='foot_right']",
                    function () {

                        _this.setHead(_this.head_idx);
                        _this.setFoot(_this.foot_idx);
                    });

                // 마우스휠
                {
                    window.addEventListener("mousewheel", _this.setPaperScale, { passive: false });
                    window.addEventListener("DOMMouseScroll", _this.setPaperScale, { passive: false });                    
                }

                // 머리글 조회
                _this.exec("HEADLIST");
                
                // 바닥글 조회
                _this.exec("FOOTLIST");

                // 프린트 아이템 조회
                _this.exec("PRINTITEMLIST");
            }

            // 시험지 스케일
            , setPaperScale: function (event) {

                if (event.ctrlKey == true) {

                    // 기본 이벤트 방지
                    event.preventDefault();

                    // this
                    let _this = this;

                    if (event.deltaY < 0) {
                        _this.paperScale += 0.1;
                    } else {
                        _this.paperScale -= 0.1;
                    }
                    
                    _this.paperScale = Math.floor(_this.paperScale * 100) / 100;
                    if (_this.paperScale < 0.2) {
                        _this.paperScale = 0.2
                    }
                    if (_this.paperScale > 1.0) {
                        _this.paperScale = 1.0
                    }
                    
                    $("#dev_exam_paper_wrapper").css("transform", "scale(" + _this.paperScale + ")");
                }
            }

            // 실행
            , exec: function (gubun, num, code, event) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.$GetQueryMap();
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "HEADLIST":
                        {
                            // url 설정
                            url = "/vue_api/user/my_head/print_list";
                        }
                        break;

                    case "FOOTLIST":
                        {
                            // url 설정
                            url = "/vue_api/user/my_foot/print_list";
                        }
                        break;

                    case "PRINTITEMLIST":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_print/list_print";
                        }
                        break;

                    case "EXAMITEMLIST":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_print/list_exam";

                            // 조회할 시험지 번호
                            _this.print_item_no = num;

                            data["no"] = num;
                        }
                        break;

                    default:
                        {
                            
                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "HEADLIST":
                        {
                            _this.list_head = data.LIST_1;

                            // 머리글 첫번째 설정
                            _this.setHead(0)                            
                        }
                        break;

                    case "FOOTLIST":
                        {
                            _this.list_foot = data.LIST_1;

                            // 바닥글 첫번째 설정
                            _this.setFoot(0)
                        }
                        break;

                    case "PRINTITEMLIST":
                        {
                            _this.hash = data.HASH;
                            _this.list_print_item = data.LIST_1;

                            if (!_this.list_print_item || _this.list_print_item.length < 1) {

                                alert("저장된 시험지가 없습니다.");
                            }
                            else {

                                // qs
                                var qs = _this.$GetQueryMap();

                                // 첫 문제지 번호 세팅
                                let no = qs.no || 0;
                                if (no < 1) {
                                    no = _this.list_print_item[0].NO;
                                }

                                // 문제 아이템 리스트 조회
                                _this.exec("EXAMITEMLIST", no);
                            }
                        }
                        break;

                    case "EXAMITEMLIST":
                        {
                            _this.list_exam_item = data.LIST_1;

                            // 커럼 및 아이템 초기 설정
                            _this.setDefaultColItem();
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // 컬럼 및 아이템 초기 설정
            , setDefaultColItem: function () {

                // this
                let _this = this;

                _this.JQ_ArrElmCol = [];
                _this.JQ_ArrElmItem = [];

                let arrItem = _this.list_exam_item;
                if (arrItem) {

                    // 임시영역에 문제 넣음
                    for (let idx_item = 0; idx_item < arrItem.length; idx_item++) {

                        // 번호 수정
                        let item = arrItem[idx_item];
                        item = item.CONT_Q.replace("[[번호]]", (idx_item + 1) + ". ");

                        // li 만들기
                        let JQ_item =
                            $("<li class='dev_exam_paper_item pu_exam_paper_item'>"
                                + item
                                + "<a href='javascript:;' class='dev_btn_m pu_btn_m pu_ss_btn_blue'>하단여백 (-)</a>"
                                + "<a href='javascript:;' class='dev_btn_p pu_btn_p pu_ss_btn_red' >하단여백 (+)</a>"
                                + "</li>");

                        // 아이템 추가
                        _this.JQ_ArrElmItem.push(JQ_item);
                        $("#dev_exam_paper_wrapper_temp").append(JQ_item);

                        JQ_item.height(JQ_item.height() + 60);
                    }

                    // 문제 컬럼 대량생성
                    let strUl = "<ul class='dev_exam_paper_col pu_exam_paper_col'></ul>";
                    for (let idx_col = 0; idx_col < 100; idx_col++) {

                        _this.JQ_ArrElmCol.push($(strUl));
                    }

                    // 문제 첫번째 컬럼에 문제 다 넣기
                    for (let idx_elmItem = 0; idx_elmItem < _this.JQ_ArrElmItem.length; idx_elmItem++) {

                        _this.JQ_ArrElmCol[0].append(_this.JQ_ArrElmItem[idx_elmItem]);
                    }
                }

                // 높이에 맞춰 재설정
                _this.setArrangeColItem();
            }

            // 높이에 맞춰 재설정
            , setArrangeColItem: function () {

                // this
                let _this = this;

                // 임시 컬럼
                let JQ_ArrElmColTemp = $("<ul></ul>");
                for (let idx_col = 0; idx_col < _this.JQ_ArrElmCol.length; idx_col++) {

                    let JQ_Elm = _this.JQ_ArrElmCol[idx_col].find(".dev_exam_paper_item");
                    for (let idx_elm = 0; idx_elm < JQ_Elm.length; idx_elm++) {

                        JQ_ArrElmColTemp.append($(JQ_Elm[idx_elm]));
                    }
                }


                let hSumElm = 0;                                                  // 누적 아이템 높이
                let hColLim = _this.paperHeight - _this.paperHead - _this.paperFoot - _this.paperEtc; // 기준높이 = (용지 높이 - 헤더 높이)
                let JQ_ArrElm = JQ_ArrElmColTemp.find(".dev_exam_paper_item");

                let idx_col = 0;
                for (let idx_elm = 0; idx_elm < JQ_ArrElm.length; idx_elm++) {

                    let JQ_Elm = $(JQ_ArrElm[idx_elm]);
                    let hElm = JQ_Elm.height();

                    // 높이가 넘을것 같으면 다음컬럼
                    if (hSumElm + hElm > hColLim) {

                        idx_col++;
                        hSumElm = 0;
                    }

                    _this.JQ_ArrElmCol[idx_col].append(JQ_Elm);
                    hSumElm += hElm;
                }

                // dimm 숨기기
                _this.$HideDimmLogo();

                // 시험지 만들기
                _this.setExamPaper();
            }

            // 시험지 만들기
            , setExamPaper: function () {

                // this
                let _this = this;

                // 시험지 초기화
                $("#dev_exam_paper_wrapper").empty();

                let cntCur = 0;
                let cntPaper = Math.ceil(_this.JQ_ArrElmCol.length / _this.paperColCnt);
                for (let idx_paper = 0; idx_paper < cntPaper; idx_paper++) {

                    let JQ_ExamPaper =
                        _this.JQ_ExamPaper.clone()
                            .removeClass("pu_exam_paper_A4_V")
                            .removeClass("pu_exam_paper_A4_H")
                            .removeClass("pu_exam_paper_B4_V")
                            .addClass(_this.paperClass);

                    let cntItem = 0;
                    for (let idx_col = 0; idx_col < _this.paperColCnt; idx_col++) {

                        if (cntCur < _this.JQ_ArrElmCol.length) {

                            // 컬럼수
                            let JQ_ElmCol = _this.JQ_ArrElmCol[cntCur];
                            JQ_ExamPaper.find(".dev_exam_paper_body").append(JQ_ElmCol);
                            cntCur++;

                            // 문제수
                            cntItem += JQ_ElmCol.find(".dev_exam_paper_item").length;
                        }
                    }

                    // 시험지 추가
                    if (cntItem > 0) {
                        $("#dev_exam_paper_wrapper").append(JQ_ExamPaper);
                    }
                }

                // 머리글 바닥글
                _this.setHead(_this.head_idx);
                _this.setFoot(_this.foot_idx);

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // 시험지 프린트
            , printExamPaper: function () {

                // this
                let _this = this;

                $('.dev_exam_paper').printThis({
                    debug: false,               // show the iframe for debugging
                    importCSS: true,            // import page CSS
                    importStyle: false,         // import style tags
                    printContainer: true,       // grab outer container as well as the contents of the selector
                    //loadCSS: "path/to/my.css",  // path to additional css file - us an array [] for multiple
                    pageTitle: "",              // add title to print page
                    removeInline: false,        // remove all inline styles from print elements
                    printDelay: 500,            // variable print delay
                    header: null,               // prefix to html
                    footer: null,               // postfix to html
                    base: false,                // preserve the BASE tag, or accept a string for the URL
                    formValues: true,           // preserve input/form values
                    canvas: true,               // copy canvas elements (experimental)
                    //doctypeString: '...',     // enter a different doctype for older markup
                    removeScripts: false,       // remove script tags from print content
                    copyTagClasses: false       // copy classes from the html & body tag
                });

            }

            // 용지 변환
            , chagePaperSize: function (size) {

                // this
                let _this = this;

                // 용지 클래스 설정
                _this.paperClass = "pu_exam_paper_" + size;

                // 용지 크기 설정
                if (size == "A4_V") {

                    _this.paperWidth = 1050;
                    _this.paperHeight = 1430;
                }

                if (size == "A4_H") {

                    _this.paperWidth = 1430;
                    _this.paperHeight = 930;
                }

                if (size == "B4_V") {

                    _this.paperWidth = 1260;
                    _this.paperHeight = 1720;
                }

                // 높이에 맞춰 재설정
                _this.setArrangeColItem();
            }

            // 컬럼 갯수 설정
            , chagePaperCol: function (colCnt) {

                // this
                let _this = this;

                // 갯수 변환
                _this.paperColCnt = colCnt;

                // 시험지 만들기
                _this.setExamPaper();
            }

            // 머리글 설정
            , setHead: function (idx) {

                // this
                let _this = this;

                _this.head_idx = idx;

                if (_this.list_head.length > 0) {

                    let cont = _this.list_head[idx]["CONTENTS"];

                    let left_1 = $("[name='head_left_1']").val().trim();
                    let title_1 = $("[name='head_title_1']").val().trim();
                    let right_1 = $("[name='head_right_1']").val().trim();
                    let left_2 = $("[name='head_left_2']").val().trim();
                    let title_2 = $("[name='head_title_2']").val().trim();
                    let right_2 = $("[name='head_right_2']").val().trim();

                    if (left_1) { cont = cont.replace("[[LEFT_1]]", left_1); }
                    if (title_1) { cont = cont.replace("[[TITLE_1]]", title_1); }
                    if (right_1) { cont = cont.replace("[[RIGHT_1]]", right_1); }
                    if (left_2) { cont = cont.replace("[[LEFT_2]]", left_2); }
                    if (title_2) { cont = cont.replace("[[TITLE_2]]", title_2); }
                    if (right_2) { cont = cont.replace("[[RIGHT_2]]", right_2); }

                    let JQ_Head = $(cont);
                    $(".dev_exam_paper_title").empty().append(JQ_Head);
                }
            }

            // 바닥글 설정
            , setFoot: function (idx) {

                // this
                let _this = this;

                _this.foot_idx = idx;

                if (_this.list_foot.length > 0) {

                    let cont = _this.list_foot[idx]["CONTENTS"];

                    let left = $("[name='foot_left']").val().trim();
                    let right = $("[name='foot_right']").val().trim();

                    if (left) { cont = cont.replace("[[LEFT]]", left); }
                    if (right) { cont = cont.replace("[[RIGHT]]", right); }

                    for (let idx = 0; idx < $(".dev_exam_paper_foot").length; idx++) {

                        let JQ_Paper = $($(".dev_exam_paper_foot")[idx]);
                        let _cont = cont.replace("[[NUM]]", "[ " + (idx + 1) + " ]");
                        let JQ_Foot = $(_cont);
                        JQ_Paper.empty().append(JQ_Foot);
                    }
                }
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {

            // this
            let _this = this;

            // 마우스 휠 이벤트 
            {
                window.removeEventListener("mousewheel", _this.setPaperScale);
                window.removeEventListener("DOMMouseScroll", _this.setPaperScale);
            }
        }

        , destroyed() {
        }
    }

</script>