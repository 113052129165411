<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <inc_zadmin_head />
            <inc_zadmin_left />

            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">

                    <inc_zadmin_title />

                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <div class="pu_TableY01">
                                <table>
                                    <colgroup>
                                        <col width="15%">
                                        <col width="*">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>아이디</th>
                                            <td>{{ view_1.ID }}</td>
                                        </tr>
                                        <tr>
                                            <th>권한</th>
                                            <td>
                                                {{ view_1.GRADE_NAME }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>이름</th>
                                            <td>{{ view_1.NAME }}</td>
                                        </tr>
                                        <tr>
                                            <th>부서</th>
                                            <td>{{ view_1.DEPARTMENT }}</td>
                                        </tr>
                                        <tr>
                                            <th>포인트</th>
                                            <td>{{ view_1.POINT }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->

                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_right">
                                <a class="pu_btn_blue" href="javascript:;" v-on:click="$PageMove('./regi?no=' + view_1.NO)"><i class="xi-eraser"></i> 수정</a>
                                <a class="pu_btn_dark" href="javascript:;" v-on:click="$PageMove('./list')"><i class="xi-bars"></i> 목록</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->

                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {}

        , data() {

            return {
                gubun_page: "VIEW"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                getBase: "getBase"
            })

            // 진입점
            , init() {
                
                this.getSearchData(1);

                // dimm 숨기기
                this.$HideDimmLogo();
            }

            , getSearchData: function (curPage) {

                // qs
                var qs = this.$GetQueryMap();

                var data = {};
                data["gubun_page"] = this.gubun_page;
                data["no"] = qs.no;

                // API 조회
                this.$SendPost("/vue_api/zadmin/member_admin/view", data, this.searchDataReturn);

            }
            , searchDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1;

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }
        }

        , beforeCreate() {

        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }
    }
</script>