<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <inc_zadmin_head />
            <inc_zadmin_left />

            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">

                    <inc_zadmin_title />

                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <div class="pu_TableY01">

                                <form id="regiForm" onsubmit="return false">

                                    <!--전달값-->
                                    <input type="hidden" name="no" v-model="view_1.NO" />
                                    <!--//전달값-->

                                    <table>
                                        <colgroup>
                                            <col width="10%">
                                            <col width="40%">
                                            <col width="10%">
                                            <col width="40%">
                                        </colgroup>
                                        <tbody>

                                            <tr>
                                                <th>교육과정*</th>
                                                <td colspan="3">
                                                    <select class="pu_select-box" name="code_subject_1" v-model="code_subject_depth_1"
                                                            v-on:change="getCodeSubject(2, '', $event)" title="과목을 선택하세요." style="width:200px">
                                                        <option value="">- 과목을 선택하세요.</option>
                                                        <option v-for="(row, idx) in opt_code_subject_depth_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>

                                                    <select class="pu_select-box" name="code_subject_2" v-model="code_subject_depth_2"
                                                            v-on:change="getCodeSubject(3, '', $event)" title="개정년도를 선택하세요." style="width:200px">
                                                        <option value="">- 개정년도를 선택하세요.</option>
                                                        <option v-for="(row, idx) in opt_code_subject_depth_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>

                                                    <select class="pu_select-box" name="code_subject_3" v-model="code_subject_depth_3"
                                                            v-on:change="getCodeSubject(4, '', $event)" title="교육과정을 선택하세요." style="width:200px">
                                                        <option value="">- 교육과정을 선택하세요.</option>
                                                        <option v-for="(row, idx) in opt_code_subject_depth_3" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>

                                                    <select class="pu_select-box" name="code_subject_4" v-model="code_subject_depth_4"
                                                            v-on:change="getCodeSubject(5, '', $event)" title="대단원을 선택하세요." style="width:200px">
                                                        <option value="">- 대단원을 선택하세요.</option>
                                                        <option v-for="(row, idx) in opt_code_subject_depth_4" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>

                                                    <select class="pu_select-box" name="code_subject_5" v-model="code_subject_depth_5"
                                                            title="중단원을 선택하세요." style="width:200px">
                                                        <option value="">- 중단원을 선택하세요.</option>
                                                        <option v-for="(row, idx) in opt_code_subject_depth_5" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>타입*</th>
                                                <td>
                                                    <select class="pu_select-box" name="gubun_exam_type" v-model="view_1.GUBUN_EXAM_TYPE"
                                                            title="타입을 선택하세요." style="width:200px">
                                                        <option value="A">객관식</option>
                                                        <option value="B">주관식</option>
                                                    </select>
                                                </td>
                                                <th>난이도*</th>
                                                <td>
                                                    <select class="pu_select-box" name="gubun_exam_diff" v-model="view_1.GUBUN_EXAM_DIFF"
                                                            title="난이도를 선택하세요." style="width:200px">
                                                        <option value="1">하</option>
                                                        <option value="2">중</option>
                                                        <option value="3">상</option>
                                                        <option value="4">킬러</option>
                                                    </select>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>학교</th>
                                                <td colspan="3">
                                                    <select class="pu_select-box" name="code_area_1" v-model="code_area_depth_1"
                                                            v-on:change="getCodeArea(2, '', $event)" title="시도를 선택하세요." style="width:200px">
                                                        <option value="">- 시도를 선택하세요.</option>
                                                        <option v-for="(row, idx) in opt_code_area_depth_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>
                                                    <select class="pu_select-box" name="code_area_2" v-model="code_area_depth_2"
                                                            v-on:change="getCodeArea(3, '', $event)" title="구군을 선택하세요." style="width:200px">
                                                        <option value="">- 구군을 선택하세요.</option>
                                                        <option v-for="(row, idx) in opt_code_area_depth_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>
                                                    <select class="pu_select-box" name="code_area_3" v-model="code_area_depth_3"
                                                            v-on:change="getCodeArea(4, '', $event)" title="초중고를 선택하세요." style="width:200px">
                                                        <option value="">- 초중고를 선택하세요.</option>
                                                        <option v-for="(row, idx) in opt_code_area_depth_3" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>
                                                    <select class="pu_select-box" name="code_area_4" v-model="code_area_depth_4"
                                                            title="학교명을 선택하세요." style="width:200px">
                                                        <option value="">- 학교명을 선택하세요.</option>
                                                        <option v-for="(row, idx) in opt_code_area_depth_4" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>년도</th>
                                                <td>
                                                    <select class="pu_select-box" name="school_year" v-model="view_1.SCHOOL_YEAR"
                                                            title="년도를 선택하세요." style="width:200px">
                                                        <option value="">- 년도를 선택하세요.</option>
                                                        <option v-for="(row, idx) in opt_school_year" v-bind:value="row.YEAR">{{ row.YEAR }}</option>
                                                    </select>
                                                </td>
                                                <th>학기</th>
                                                <td>
                                                    <select class="pu_select-box" name="school_semester" v-model="view_1.SCHOOL_SEMESTER"
                                                            title="학기를 선택하세요." style="width:200px">
                                                        <option value="">- 학기를 선택하세요.</option>
                                                        <option v-for="(row, idx) in opt_school_semester" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                                    </select>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>(질문/해설) 내용*</th>
                                                <td colspan="3">
                                                    <textarea name="contents" class="pu_input_textarea" v-html="view_1.CONTENTS"></textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->

                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_right">
                                <a class="pu_btn_dark" href="javascript:;" v-on:click="$PageMove('./list')"><i class="xi-bars"></i> 목록</a>
                                <a class="dev_btn_submit pu_btn_blue" href="javascript:;"><i class="xi-check"></i> 저장</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {}

        , data() {

            return {
                gubun_page: "REGI"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , cnt_lim_pic: 5

                , view_1: {}

                , opt_code_subject_depth_1: [] // 과목 선택 옵션
                , opt_code_subject_depth_2: []
                , opt_code_subject_depth_3: []
                , opt_code_subject_depth_4: []
                , opt_code_subject_depth_5: []

                , code_subject_depth_1: ''     // 과목 선택 코드
                , code_subject_depth_2: ''
                , code_subject_depth_3: ''
                , code_subject_depth_4: ''
                , code_subject_depth_5: ''

                , opt_code_area_depth_1: [] // 학교 선택 옵션
                , opt_code_area_depth_2: []
                , opt_code_area_depth_3: []
                , opt_code_area_depth_4: []

                , code_area_depth_1: ''     // 학교 선택 코드
                , code_area_depth_2: ''
                , code_area_depth_3: ''
                , code_area_depth_4: ''

                , opt_school_year: [] // 년도
                
                , opt_school_semester: [] // 학기
            }
        }

        , methods: {

            ...mapActions({
                getBase: "getBase"
            })

            // 진입점
            , init() {

                this.getSearchData(1);
            }

            // 데이터 가져오기
            , getSearchData: function (curPage) {

                // qs
                var qs = this.$GetQueryMap();

                // 폼
                var data = {};
                data["gubun_page"] = this.gubun_page;
                data["no"] = qs.no;
                data["type"] = qs.type;

                // API 조회
                this.$SendPost("/vue_api/zadmin/ngd_edit/view", data, this.searchDataReturn);
            }

            , searchDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1; // 저장된 데이터

                        this.opt_code_subject_depth_1 = data.MAP_LIST_1.OPT_CODE_SUBJECT_DEPTH_1; // 과목코드 옵션
                        this.opt_code_subject_depth_2 = data.MAP_LIST_1.OPT_CODE_SUBJECT_DEPTH_2;
                        this.opt_code_subject_depth_3 = data.MAP_LIST_1.OPT_CODE_SUBJECT_DEPTH_3;
                        this.opt_code_subject_depth_4 = data.MAP_LIST_1.OPT_CODE_SUBJECT_DEPTH_4;
                        this.opt_code_subject_depth_5 = data.MAP_LIST_1.OPT_CODE_SUBJECT_DEPTH_5;

                        this.code_subject_depth_1 = data.VIEW_1.CODE_SUBJECT_DEPTH_1;       // 선택된 과목코드
                        this.code_subject_depth_2 = data.VIEW_1.CODE_SUBJECT_DEPTH_2;
                        this.code_subject_depth_3 = data.VIEW_1.CODE_SUBJECT_DEPTH_3;
                        this.code_subject_depth_4 = data.VIEW_1.CODE_SUBJECT_DEPTH_4;
                        this.code_subject_depth_5 = data.VIEW_1.CODE_SUBJECT_DEPTH_5;

                        this.opt_code_area_depth_1 = data.MAP_LIST_1.OPT_CODE_AREA_DEPTH_1; // 학교코드 옵션
                        this.opt_code_area_depth_2 = data.MAP_LIST_1.OPT_CODE_AREA_DEPTH_2;
                        this.opt_code_area_depth_3 = data.MAP_LIST_1.OPT_CODE_AREA_DEPTH_3;
                        this.opt_code_area_depth_4 = data.MAP_LIST_1.OPT_CODE_AREA_DEPTH_4;
                        this.opt_code_area_depth_5 = data.MAP_LIST_1.OPT_CODE_AREA_DEPTH_5;

                        this.code_area_depth_1 = data.VIEW_1.CODE_AREA_DEPTH_1;       // 선택된 학교코드
                        this.code_area_depth_2 = data.VIEW_1.CODE_AREA_DEPTH_2;
                        this.code_area_depth_3 = data.VIEW_1.CODE_AREA_DEPTH_3;
                        this.code_area_depth_4 = data.VIEW_1.CODE_AREA_DEPTH_4;
                        this.code_area_depth_5 = data.VIEW_1.CODE_AREA_DEPTH_5;

                        this.opt_school_year = data.MAP_LIST_1.OPT_SCHOOL_YEAR; // 년도

                        this.opt_school_semester = data.MAP_LIST_1.OPT_SCHOOL_SEMESTER; // 학기

                        // 저장된 데이터가 없으면 과목코드 옵션 조회
                        if (!this.opt_code_subject_depth_1 || this.opt_code_subject_depth_1.length == 0) {
                            this.getCodeSubject(1, "000|000|000|000|000|");
                        }

                        // 저장된 데이터가 없으면 학교코드 옵션 조회
                        if (!this.opt_code_area_depth_1 || this.opt_code_area_depth_1.length == 0) {
                            this.getCodeArea(1, "000|000|000|000|");
                        }

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }

                    // ck에디터 설정
                    this.setCkEditor();
                }
            }

            // 과목코드 조회
            , getCodeSubject: function (depth, code, event) {

                // 폼
                var data = {};
                data["depth"] = depth;

                if (code) {
                    data["code"] = code;
                }
                else if (event) {
                    data["code"] = event.target.value;
                }

                this.$SendPost("/vue_api/zadmin/exam_make/code_subject", data, this.getCodeSubjectDataReturn);
            }
            , getCodeSubjectDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        var depth = data.HASH.DEPTH;
                        switch (depth) {
                            case 1:
                                this.opt_code_subject_depth_1 = data.LIST_1; this.code_subject_depth_1 = "";
                                this.opt_code_subject_depth_2 = []; this.code_subject_depth_2 = "";
                                this.opt_code_subject_depth_3 = []; this.code_subject_depth_3 = "";
                                this.opt_code_subject_depth_4 = []; this.code_subject_depth_4 = "";
                                this.opt_code_subject_depth_5 = []; this.code_subject_depth_5 = "";
                                break;

                            case 2:
                                this.opt_code_subject_depth_2 = data.LIST_1; this.code_subject_depth_2 = "";
                                this.opt_code_subject_depth_3 = []; this.code_subject_depth_3 = "";
                                this.opt_code_subject_depth_4 = []; this.code_subject_depth_4 = "";
                                this.opt_code_subject_depth_5 = []; this.code_subject_depth_5 = "";
                                break;

                            case 3:
                                this.opt_code_subject_depth_3 = data.LIST_1; this.code_subject_depth_3 = "";
                                this.opt_code_subject_depth_4 = []; this.code_subject_depth_4 = "";
                                this.opt_code_subject_depth_5 = []; this.code_subject_depth_5 = "";
                                break;

                            case 4:
                                this.opt_code_subject_depth_4 = data.LIST_1; this.code_subject_depth_4 = "";
                                this.opt_code_subject_depth_5 = []; this.code_subject_depth_5 = "";
                                break;

                            case 5:
                                this.opt_code_subject_depth_5 = data.LIST_1; this.code_subject_depth_5 = "";
                                break;
                        }
                    }
                }
            }

            // 학교코드 조회
            , getCodeArea: function (depth, code, event) {

                // 폼
                var data = {};
                data["depth"] = depth;

                if (code) {
                    data["code"] = code;
                }
                else if (event) {
                    data["code"] = event.target.value;
                }

                this.$SendPost("/vue_api/zadmin/exam_make/code_area", data, this.getCodeAreaDataReturn);
            }

            , getCodeAreaDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        var depth = data.HASH.DEPTH;
                        switch (depth) {
                            case 1:
                                this.opt_code_area_depth_1 = data.LIST_1; this.code_area_depth_1 = "";
                                this.opt_code_area_depth_2 = []; this.code_area_depth_2 = "";
                                this.opt_code_area_depth_3 = []; this.code_area_depth_3 = "";
                                this.opt_code_area_depth_4 = []; this.code_area_depth_4 = "";
                                break;

                            case 2:
                                this.opt_code_area_depth_2 = data.LIST_1; this.code_area_depth_2 = "";
                                this.opt_code_area_depth_3 = []; this.code_area_depth_3 = "";
                                this.opt_code_area_depth_4 = []; this.code_area_depth_4 = "";
                                break;

                            case 3:
                                this.opt_code_area_depth_3 = data.LIST_1; this.code_area_depth_3 = "";
                                this.opt_code_area_depth_4 = []; this.code_area_depth_4 = "";
                                break;

                            case 4:
                                this.opt_code_area_depth_4 = data.LIST_1; this.code_area_depth_4 = "";
                                break;
                        }
                    }
                }
            }

            // 저장
            , saveData() {

                var code_subject_1 = $("[name='code_subject_1']");
                if (!code_subject_1.val().trim()) {
                    alert("과목을 선택해주세요");
                    code_subject_1.focus();
                    return false;
                }

                var code_subject_2 = $("[name='code_subject_2']");
                if (!code_subject_2.val().trim()) {
                    alert("개정년도를 선택해주세요");
                    code_subject_2.focus();
                    return false;
                }

                var code_subject_3 = $("[name='code_subject_3']");
                if (!code_subject_3.val().trim()) {
                    alert("교육과정을 선택해주세요");
                    code_subject_3.focus();
                    return false;
                }

                var code_subject_4 = $("[name='code_subject_4']");
                if (!code_subject_4.val().trim()) {
                    alert("대단원을 선택해주세요");
                    code_subject_4.focus();
                    return false;
                }

                var code_subject_5 = $("[name='code_subject_5']");
                if (!code_subject_5.val().trim()) {
                    alert("중단원을 선택해주세요");
                    code_subject_5.focus();
                    return false;
                }

                // 내용
                CKEDITOR.instances.contents.updateElement();
                var contents = $("[name='contents']");
                if (!contents.val().trim()) {
                    alert("내용을 입력해주세요");
                    contents.focus();
                    return false;
                }

                if (contents.val().indexOf("dev_math_item_svg_law") > -1) {
                    alert("붉은색으로 표시된 수식을 수정해주세요.");
                    return false;
                }

                if (confirm(INFO_CONF_SAVE)) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    // qs
                    var qs = this.$GetQueryMap();

                    var data = this.$FormToMap($('#regiForm'));
                    data["gubun_page"] = this.gubun_page;
                    data["type"] = qs.type;

                    this.$SendPost("/vue_api/zadmin/ngd_edit/save", data, this.saveEventReturn);
                }
            }

            // 저장 데이터
            , saveEventReturn(data, err) {
                // dimm 숨기기
                this.$HideDimmLogo();
            }

            // ck editor 세팅
            , setCkEditor: function () {

                setTimeout(() => {

                    try {
                        setCkEditorDefault("contents", "exam_make", this.cnt_lim_pic, ".jpeg,.jpg,.png");
                    } catch (err) {
                        this.setCkEditor();
                    }

                }, 100);
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>