<template>

    <div class="pu_wd_def_min pu_wrapper_main">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(s) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_banner_top />
            <!--<inc_user_left />-->
            <inc_user_title />
            <!-------------------------- 인클루드(s) -------------------------->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <form id="regiForm" onsubmit="return false">
                <div class="pu_wd_def_max pu_layoutContent">
                    <div class="pu_loginDiv">
                        <ul>
                            <li class="pu_id">
                                <label class="pu_blind">아이디</label>
                                <input type="text" name="id" class="pu_loginput" placeholder="아이디 입력" maxlength="100" autocomplete="off">
                            </li>
                            <li class="pu_pw">
                                <label class="pu_blind">비밀번호</label>
                                <input type="password" name="pw" class="pu_loginput" placeholder="비밀번호 입력" maxlength="100">
                            </li>
                            <li>
                                <div class="pu_idched">
                                    <input type="checkbox" id="id_save" name="id_save" value="Y"><label for="id_save">아이디저장</label>
                                    <a class="idsearch" href="javascript:;">아이디/비밀번호 찾기</a>
                                </div>
                            </li>
                        </ul>
                        <div>
                            <a class="pu_loginbtn" v-on:click="exec('LOGIN');">로그인</a>
                            <a class="pu_newjoin" href="javascript:;"><i class="xi-border-color"></i> 신규 회원가입</a>
                        </div>
                    </div>
                </div>
            </form>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 서브 실제 컨텐츠 영역 (e) -------------------------->
        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                // 시작
                _this.exec("INIT");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "INIT":
                        {
                            // 로그인 체크
                            {
                                let loginYn = this.getBase.userLoginYn;
                                if (loginYn == "Y") {
                                    _this.$router.push('/user/home/home');
                                    return;
                                }
                            }

                            // enter 키 이벤트
                            {
                                _this.$SetEventEnter("id", function () { $("[name='pw']").focus(); });
                                _this.$SetEventEnter("pw", function () { _this.exec("LOGIN"); });
                            }

                            // 아이디 쿠키 설정
                            {
                                let id = _this.$GetCookie("login_id_save") || "";
                                if (id) {
                                    $("[name='id']").val(id);
                                    $("[name='id_save']").prop("checked", true);
                                }
                            }

                            $(".dev_inc_title").text("로그인");
                            $(".dev_inc_title_sub").text("로그인하시면 다양한 서비스를 이용하실 수 있습니다.");
                        }
                        break;

                    case "LOGIN":
                        {
                            // URL 설정
                            url = "/vue_api/user/login/login";

                            // 비밀번호
                            let id = $("[name='id']");
                            if (!id.val().trim()) {
                                alert("아이디를 입력해주세요");
                                id.focus();
                                return false;
                            }

                            // 비밀번호
                            let pw = $("[name='pw']");
                            if (!pw.val().trim()) {
                                alert("비밀번호를 입력해주세요");
                                pw.focus();
                                return false;
                            }

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#regiForm")));

                            // 쿠키 저장
                            if (data.id_save == "Y") {
                                _this.$SetCookie("login_id_save", data.id, 999);
                            }
                            else {
                                _this.$DelCookie("login_id_save");
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 보이기
                _this.$ShowDimmLogo();

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "INIT":
                        {

                        }
                        break;

                    case "LOGIN":
                        {
                            if (data.RESULT_YN == "Y") {
                                // 홈으로
                                _this.$router.push('/user/home/home');
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }
    }

</script>

<style>
    .pu_subVisual dl {
        display: none;
    }

    .pu_subVisual {
        height: 140px;
    }
</style>