<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">
            <inc_zadmin_head />
            <inc_zadmin_left />
            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">
                    <inc_zadmin_title />
                    <!-- 검색조건 (s) -->
                    <section class="pu_searchSection">
                        <form id="searchForm" class="pu_searchBox" onsubmit="return false">
                            <dl>
                                <dt class="pu_wd90"><label for="">검색조건</label></dt>
                                <dd>
                                    <select name="searchtype" class="pu_select-box" v-model="searchtypevalue" title="조건을 선택하세요." style="width:150px">
                                        <option value="id">아이디</option>
                                        <option value="name">이름</option>
                                    </select>
                                    <input name="searchtext" v-bind:class="[searchtypevalue == 'id' ? 'dev_idmail' : 'dev_koreng_5']" type="text" placeholder="아이디를 입력해주세요." style="width:300px">
                                </dd>
                                <dt class="pu_ml15 pu_wd140"><label for="">이메일 수신동의</label></dt>
                                <dd>
                                    <select name="receiveagreeemail" class="pu_select-box" title="조건을 선택하세요." style="width:150px">
                                        <option value="" selected>전체</option>
                                        <option value="1">동의</option>
                                        <option value="0">미동의</option>
                                    </select>
                                </dd>
                                <dt class="pu_ml15 pu_wd130"><label for="">SMS 수신동의</label></dt>
                                <dd>
                                    <select name="receiveagreesms" class="pu_select-box" title="조건을 선택하세요." style="width:150px">
                                        <option value="" selected>전체</option>
                                        <option value="1">동의</option>
                                        <option value="0">미동의</option>
                                    </select>
                                </dd>
                            </dl>
                            <dl>
                                <dt class="pu_wd90"><label for="">가입일자</label></dt>
                                <dd>
                                    <div class="pu_dateSelect">
                                        <ul class="pu_date_calendar" style="width:350px">
                                            <li>
                                                <input type="text" name="search_regi_date_start" v-model:value="search_regi_date_start">
                                                <input type="image" src="/images/icon_calendar.png" onclick="pjh_calendar(undefined, '[name=search_regi_date_start]', 'YYYY-MM-DD')">
                                            </li>
                                            <li>~</li>
                                            <li>
                                                <input type="text" name="search_regi_date_end" v-model:value="search_regi_date_end">
                                                <input type="image" src="/images/icon_calendar.png" onclick="pjh_calendar(undefined, '[name=search_regi_date_end]', 'YYYY-MM-DD')">
                                            </li>
                                        </ul>
                                        <ul class="pu_date_day">    <!-- 빠른선택 -->
                                            <li v-for="item in datebutton">
                                                <a href="javascript:" v-on:click="setSearchdate(item.startdate, item.enddate)">{{ item.title }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </dd>
                            </dl>
                        </form>
                        <div class="pu_searchBtn"><a href="javascript:" class="xi-search" v-on:click="getSearchData(1)"><span>검색</span></a></div>
                    </section>
                    <!-- 검색조건(e) -->
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <p class="pu_listNum">
                                <i class="xi-user"></i> 가입 회원 <strong>{{ rownum }}</strong>명
                            </p>
                            <div class="pu_TableX01">
                                <table>
                                    <colgroup>
                                        <col width="6%">
                                        <col width="10%">
                                        <col width="*">
                                        <col width="11%">
                                        <col width="10%">
                                        <col width="9%">
                                        <col width="9%">
                                        <col width="11%">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th scope="col">번호</th>
                                            <th scope="col">이름</th>
                                            <th scope="col">아이디(이메일)</th>
                                            <th scope="col">연락처</th>
                                            <th scope="col">가입일</th>
                                            <th scope="col">이메일 수신동의</th>
                                            <th scope="col">SMS 수신동의</th>
                                            <th scope="col">최근 접속일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row, idx) in list_1">
                                            <td>{{ getRowNum(idx) }}</td>
                                            <td>{{ row.NAME }}</td>
                                            <td>{{ row.EMAIL }}</td>
                                            <td>{{ row.PHONE }}</td>
                                            <td>{{ row.REGI_DATE }}</td>
                                            <td>{{ row.RECEIVEAGREEEMAIL == "1" ? "동의" : "미동의" }}</td>
                                            <td>{{ row.RECEIVEAGREESMS == "1" ? "동의" : "미동의" }}</td>
                                            <td>{{ row.LASTLOGIN_DATE }}</td>
                                        </tr>
                                        <tr v-if="list_1.length == 0">
                                            <td colspan="8">조회된 결과가 없습니다.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->
                        <!-- 하단 페이징영역 (s)  -->
                        <div class="pu_paging">
                            <div class="pu_pagination" v-html="paging">

                            </div>
                        </div>
                        <!-- 하단 페이징영역 (d)  -->

                    </section>
                    <!-- 컨텐츠영역 (e) -->

                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    function getStringDate(d) {
        return d.getFullYear() + "-" + ("00" + (d.getMonth() + 1)).slice(-2) + "-" + ("00" + d.getDate()).slice(-2);
    }

    // export
    export default {

        computed: { }
        
        , data() {
            var curDate = new Date();
            var firstDate = new Date(curDate.getFullYear(), curDate.getMonth(), 1);
            var lastDate = new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0);

            var arrDate = [];
            for (var i = 0; i <= 12; i++) {
                var firstIDate = new Date(firstDate.getFullYear(), firstDate.getMonth() - i, firstDate.getDate());
                var lastIDate = new Date(firstIDate.getFullYear(), firstIDate.getMonth() + 1, 0);
                var curIDate = new Date(firstIDate.getFullYear(), firstIDate.getMonth(), lastIDate.getDate() < curDate.getDate() ? lastIDate.getDate() : curDate.getDate());
                arrDate.push({
                    "first": firstIDate,
                    "cur": curIDate,
                    "last": lastIDate
                });
            }
            var datebutton = [
                {
                    "title": (arrDate[0]["cur"].getFullYear() + "년 ").slice("-4") + (arrDate[0]["cur"].getMonth() + 1) + "월",
                    "startdate": getStringDate(arrDate[0]["first"]),
                    "enddate": getStringDate(arrDate[0]["last"])
                },
                {
                    "title": (arrDate[1]["cur"].getFullYear() + "년 ").slice("-4") + (arrDate[1]["cur"].getMonth() + 1) + "월",
                    "startdate": getStringDate(arrDate[1]["first"]),
                    "enddate": getStringDate(arrDate[1]["last"])
                },
                {
                    "title": (arrDate[2]["cur"].getFullYear() + "년 ").slice("-4") + (arrDate[2]["cur"].getMonth() + 1) + "월",
                    "startdate": getStringDate(arrDate[2]["first"]),
                    "enddate": getStringDate(arrDate[2]["last"])
                },
                { "title": "최근 1개월", "startdate": getStringDate(arrDate[1]["cur"]), "enddate": getStringDate(arrDate[0]["cur"]) },
                { "title": "최근 6개월", "startdate": getStringDate(arrDate[6]["cur"]), "enddate": getStringDate(arrDate[0]["cur"]) },
                { "title": "최근 1년", "startdate": getStringDate(arrDate[12]["cur"]), "enddate": getStringDate(arrDate[0]["cur"]) }
            ];

            return {
                gubun_page: "user_list"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
                , search_regi_date_start: getStringDate(arrDate[12]["cur"])
                , search_regi_date_end: getStringDate(arrDate[0]["cur"])
                , datebutton: datebutton
                , searchtypevalue: "id"
            }
        }

        , methods: {

            ...mapActions({
                getBase: "getBase"
            })

            // 진입점
            , init() {
                // dimm 숨기기
                this.getSearchData(1);

                this.$HideDimmLogo();
            }
            , getSearchData: function (curPage) {

                // qs
                var qs = this.$GetQueryMap();

                // 폼
                var data = this.$FormToMap($("#searchForm"));
                data["gubun_page"] = this.gubun_page;
                data["curPage"] = curPage;

                // API 조회
                this.$SendPost("/vue_api/zadmin/homepage/user_list/list", data, this.searchDataReturn);

            }
            , searchDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.rownum = data.ROWNUM;
                        this.paging = data.PAGING;
                        this.list_1 = data.LIST_1;
                        this.list_2 = data.LIST_2;
                        this.list_3 = data.LIST_3;

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }
            , setSearchdate(startdate, enddate) {
                this.search_regi_date_start = startdate;
                this.search_regi_date_end = enddate;
            }
        }

        , beforeCreate() {

        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>