<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <inc_zadmin_head />
            <inc_zadmin_left />

            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">

                    <inc_zadmin_title />

                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <div class="pu_TableY01">

                                <form id="regiForm" onsubmit="return false">

                                    <table>
                                        <colgroup>
                                            <col width="10%">
                                            <col width="45%">
                                            <col width="45%">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>수정하는법 *</th>
                                                <td colspan="2">
                                                    1. STEP_1 은 문제의 레이아웃을 수정합니다.
                                                    <br />2. 〈〈A〉〉태그내용〈〈/A〉〉 이런 형식은 태그라고 합니다.
                                                    <br />3. HWP이미지 태그는 건들지 않습니다.
                                                    <br />4. HWP이미지 태그 > 질문 태그 > 이미지 태그 > 보기 태그 순으로 변경하거나
                                                    <br />보기 항목의 이미지를 참고하여 순서를 변경합니다.
                                                    <br />5. 순서를 변경할때는 태그를 포함하여 범위선택후 잘라내어 원하는 순서에 붙여넣기 합니다.
                                                    <br />6. 문제의 레이아웃 조정이 끝나면 저장합니다.
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>내용 *</th>
                                                <td colspan="2">
                                                    <textarea id="dev_editor" name="contents" style="height:350px;"
                                                              v-html="view_1.내용"></textarea>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>보기</th>
                                                <td>
                                                    <!--hwp 이미지-->
                                                    <img id="dev_img_hwp" src="" />
                                                </td>
                                                <td>
                                                    <!--문제 내용-->
                                                    <div id="dev_view_cont" style="width: 100%; height: 100%;">내용</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->

                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_right">
                                <a class="pu_btn_dark" href="javascript:;" v-on:click="$PageMove('./list')"><i class="xi-bars"></i> 목록</a>
                                <a class="dev_btn_submit pu_btn_blue" href="javascript:;"><i class="xi-check"></i> 저장</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {

                gubun_page: "REGI"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , view_1: {}

                , regiNo: ""
                , fileName: ""
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                let _this = this;

                // 이벤트
                {
                    $("#dev_editor").on("keyup", _this.funcConvertViewFromTextarea);
                }

                // 검색
                _this.exec("INIT");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.$GetQueryMap();
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "INIT":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/file_step_1/view";

                            // 데이터
                            data["regiNo"] = qs.regiNo;
                            data["fileName"] = qs.fileName;
                        }
                        break;

                    case "SAVE":
                        {
                            // url 설정
                            url = "/vue_api/zadmin/file_step_1/save";

                            // 데이터
                            data["regiNo"] = _this.regiNo;
                            data["fileName"] = _this.fileName;
                            data["cont"] = $("#dev_editor").val().replaceAll("\r\n", "").replaceAll("\n", "").replaceAll("<br/>", "");

                            if (!confirm(INFO_CONF_SAVE)) {
                                return;
                            }
                            else {

                                // dimm 보이기
                                _this.$ShowDimmLogo();
                            }
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "INIT":
                        {
                            _this.view_1 = data.VIEW_1; // 저장된 데이터

                            _this.regiNo = data.VIEW_1.등록번호;
                            _this.fileName = data.VIEW_1.파일명;

                            setTimeout(function () {

                                // textarea 내용을 변환해서 보여줌
                                _this.funcConvertViewFromTextarea();

                            }, 1000);
                        }
                        break;

                    case "SAVE":
                        {
                            alert("저장 되었습니다.");

                            _this.$PageMove("./list");
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // textarea 내용을 변환해서 보여줌
            , funcConvertViewFromTextarea: function () {

                let regexHwp이미지 = /<<HWP이미지>>(.*)<<.HWP이미지>>/;
                let regex이미지 = /<<이미지>>(.*)<<.이미지>>/;
                let regex질문 = /<<질문>>(.*)<<.질문>>/;
                let regex선택 = /<<선택>>(.*)<<.선택>>/;
                let regex줄바꿈 = /<<줄바꿈>>(.*)<<.줄바꿈>>/;

                let cont = $("#dev_editor").val();

                let tagHwp이미지 = cont.match(new RegExp(regexHwp이미지, "g")).join("") || "";
                let arrTag이미지 = cont.match(new RegExp(regex이미지, "g")) || [];

                // hwp 이미지
                let urlHwp이미지 = tagHwp이미지.replaceAll("<<HWP이미지>>", "").replaceAll("<</HWP이미지>>", "");

                // 이미지
                for (let idx = 0; idx < arrTag이미지.length; idx++) {

                    let tag이미지 = arrTag이미지[idx];
                    let url이미지 = tag이미지.replaceAll("<<이미지>>", "").replaceAll("<</이미지>>", "")

                    let tagImg = "<br/><img src='" + url이미지 + "'/><br/>";

                    cont = cont.replaceAll(tag이미지, tagImg);
                }

                // 질문
                cont = cont.replaceAll(tagHwp이미지, "").trim();
                cont =
                    cont
                    .replaceAll("<<질문>>", "<br/>").replaceAll("<</질문>>", "<br/>")                    
                    .replaceAll("<<선택>>", "<br/>").replaceAll("<</선택>>", "<br/>")
                    .replaceAll("<<이미지>>", "<br/>").replaceAll("<</이미지>>", "<br/>")
                    .replaceAll("<</줄바꿈>>", "<br/>")
                    .replaceAll("<<수식>>", "").replaceAll("<</수식>>", "")
                    .replaceAll("\r\n", "").replaceAll("\n", "");

                $("#dev_view_cont").html(cont);
                $("#dev_img_hwp").attr("src", urlHwp이미지);
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>