<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">
            <inc_zadmin_head />
            <inc_zadmin_left />
            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">
                    <inc_zadmin_title />
                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">

                        <div class="pu_pu_MenuSettingDiv">

                            <div class="pu_MenuSetting">
                                <div class="pu_MenuSettingTop">
                                    <h3 class="pu_f_left"><i class="xi-comment-o"></i> 대메뉴</h3>
                                    <div class="pu_f_right">
                                        <a href="javascript:" class="pu_ss_btn_dark" v-on:click="moveUpDw(list_1, 'U')">상단이동</a>
                                        <a href="javascript:" class="pu_ss_btn_dark" v-on:click="moveUpDw(list_1, 'D')" style="margin-left:3px;">하단이동</a>
                                    </div>
                                </div>
                                <div class="pu_TableX01">
                                    <table class="pu_zebra">
                                        <colgroup>
                                            <col width="*">
                                            <col span="2" width="18%">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">목록</th>
                                                <th scope="col">삭제</th>
                                                <th scope="col">선택</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, idx) in list_1">
                                                <td class="pu_leftTxt">
                                                    <input v-model="row.TITLE_NEW"
                                                           v-show="row.EDITABLE == '1'"
                                                           v-on:keyup.enter="editCodeName(1, row.CODE, row.TITLE_NEW)" v-on:blur="row.EDITABLE = '0'"
                                                           placeholder="입력 후 [Enter] 누르면 저장됩니다."
                                                           style="width:100%" />

                                                    <div style="width:100%" v-show="row.EDITABLE == 0" v-on:click="row.EDITABLE = '1'" v-html="row.TITLE"></div>
                                                </td>
                                                <td>
                                                    <input type="image"
                                                           v-on:click="deleteCodeName(1, row.CODE)"
                                                           src="/images/icon_del.png" title="삭제">
                                                </td>
                                                <td>
                                                    <input type="image"
                                                           v-on:click="selectCodeName(list_1, row)"
                                                           v-bind:src="row.PATH_IMG_SELECT" title="선택">
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="dev_code_name_depth_1" style="display:none;">
                                            <tr>
                                                <td class="pu_leftTxt">
                                                    <input v-model="codeName"
                                                           v-on:keyup.enter="addCodeName(1)" v-on:blur="clearCodeName(1)"
                                                           placeholder="입력 후 [Enter] 누르면 저장됩니다."
                                                           style="width:100%" />
                                                </td>
                                                <td>
                                                    <input type="image" src="/images/icon_del.png" title="삭제" v-on:click="hideCodeNameInput(1)">
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="pu_footBtn01">
                                    <button v-on:click="showCodeNameInput(1)"> 대메뉴 추가 시, 이곳을 클릭하여 주세요 <i class="xi-angle-right"></i></button>
                                </div>
                            </div>

                            <div class="pu_MenuSetting">
                                <div class="pu_MenuSettingTop">
                                    <h3 class="pu_f_left"><i class="xi-comment-o"></i> 중메뉴</h3>
                                    <div class="pu_f_right">
                                        <a href="javascript:" class="pu_ss_btn_dark" v-on:click="moveUpDw(list_2, 'U')">상단이동</a>
                                        <a href="javascript:" class="pu_ss_btn_dark" v-on:click="moveUpDw(list_2, 'D')" style="margin-left:3px;">하단이동</a>
                                    </div>
                                </div>
                                <div class="pu_TableX01">
                                    <table class="pu_zebra">
                                        <colgroup>
                                            <col width="*">
                                            <col span="2" width="18%">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">목록</th>
                                                <th scope="col">삭제</th>
                                                <th scope="col">선택</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, idx) in list_2">
                                                <td class="pu_leftTxt">
                                                    <input v-model="row.TITLE_NEW"
                                                           v-show="row.EDITABLE == '1'"
                                                           v-on:keyup.enter="editCodeName(2, row.CODE, row.TITLE_NEW)" v-on:blur="row.EDITABLE = '0'"
                                                           placeholder="입력 후 [Enter] 누르면 저장됩니다."
                                                           style="width:100%" />

                                                    <div style="width:100%" v-show="row.EDITABLE == 0" v-on:click="row.EDITABLE = '1'" v-html="row.TITLE"></div>
                                                </td>
                                                <td>
                                                    <input type="image"
                                                           v-on:click="deleteCodeName(2, row.CODE)"
                                                           src="/images/icon_del.png" title="삭제">
                                                </td>
                                                <td>
                                                    <input type="image"
                                                           v-on:click="selectCodeName(list_2, row)"
                                                           v-bind:src="row.PATH_IMG_SELECT" title="선택">
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="dev_code_name_depth_2" style="display:none;">
                                            <tr>
                                                <td class="pu_leftTxt">
                                                    <input v-model="codeName"
                                                           v-on:keyup.enter="addCodeName(2)" v-on:blur="clearCodeName(2)"
                                                           placeholder="입력 후 [Enter] 누르면 저장됩니다."
                                                           style="width:100%" />
                                                </td>
                                                <td>
                                                    <input type="image" src="/images/icon_del.png" title="삭제" v-on:click="hideCodeNameInput(2)">
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="pu_footBtn01">
                                    <button v-on:click="showCodeNameInput(2)"> 대메뉴 추가 시, 이곳을 클릭하여 주세요 <i class="xi-angle-right"></i></button>
                                </div>
                            </div>

                            <div class="pu_MenuSetting">
                                <div class="pu_MenuSettingTop">
                                    <h3 class="pu_f_left"><i class="xi-comment-o"></i> 소메뉴</h3>
                                    <div class="pu_f_right">
                                        <a href="javascript:" class="pu_ss_btn_dark" v-on:click="moveUpDw(list_3, 'U')">상단이동</a>
                                        <a href="javascript:" class="pu_ss_btn_dark" v-on:click="moveUpDw(list_3, 'D')" style="margin-left:3px;">하단이동</a>
                                    </div>
                                </div>
                                <div class="pu_TableX01">
                                    <table class="pu_zebra">
                                        <colgroup>
                                            <col width="*">
                                            <col span="3" width="16%">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">목록</th>
                                                <th scope="col">설정</th>
                                                <th scope="col">삭제</th>
                                                <th scope="col">선택</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, idx) in list_3">
                                                <td class="pu_leftTxt">
                                                    <input v-model="row.TITLE_NEW"
                                                           v-show="row.EDITABLE == '1'"
                                                           v-on:keyup.enter="editCodeName(3, row.CODE, row.TITLE_NEW)" v-on:blur="row.EDITABLE = '0'"
                                                           placeholder="입력 후 [Enter] 누르면 저장됩니다."
                                                           style="width:100%" />

                                                    <div style="width:100%" v-show="row.EDITABLE == 0" v-on:click="row.EDITABLE = '1'" v-html="row.TITLE"></div>
                                                </td>
                                                <td>
                                                    <input type="image"
                                                           v-on:click="showPopEdit(row)"
                                                           src="/images/icon_setting.png" title="설정">
                                                </td>
                                                <td>
                                                    <input type="image"
                                                           v-on:click="deleteCodeName(3, row.CODE)"
                                                           src="/images/icon_del.png" title="삭제">
                                                </td>
                                                <td>
                                                    <input type="image"
                                                           v-on:click="selectCodeName(list_3, row)"
                                                           v-bind:src="row.PATH_IMG_SELECT" title="선택">
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot class="dev_code_name_depth_3" style="display:none;">
                                            <tr>
                                                <td class="pu_leftTxt">
                                                    <input v-model="codeName"
                                                           v-on:keyup.enter="addCodeName(3)" v-on:blur="clearCodeName(3)"
                                                           placeholder="입력 후 [Enter] 누르면 저장됩니다."
                                                           style="width:100%" />
                                                </td>
                                                <td>
                                                </td>
                                                <td>
                                                    <input type="image" src="/images/icon_del.png" title="삭제" v-on:click="hideCodeNameInput(3)">
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div class="pu_footBtn01">
                                    <button v-on:click="showCodeNameInput(3)"> 대메뉴 추가 시, 이곳을 클릭하여 주세요 <i class="xi-angle-right"></i></button>
                                </div>
                            </div>
                        </div>

                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>

        <!--  팝업 s-->
        <div class="dev_pop_edit pu_mask"></div>

        <!-- 메뉴명 팝업 s-->
        <div class="dev_pop_edit pu_popup popup01" style="display:none;width:500px">
            <div class="pu_pop_title">
                <h2>메뉴 설정</h2>
                <button type="button" class="pu_pop_close" v-on:click="hidePopEdit()">닫기</button>
            </div>
            <div class="pu_pop_contents">
                <section>
                    <!-- 컨텐츠 테이블 s -->
                    <div class="pu_TableY01">
                        <form id="regiForm" onsubmit="return false">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>코드</th>
                                        <td>
                                            {{codeInfo.CODE}}
                                            <input type="hidden" name="code" v-model="codeInfo.CODE">
                                            <input type="hidden" name="depth" v-model="codeInfo.DEPTH">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>URL<br>(절대경로)</th>
                                        <td><input type="text" name="url" placeholder="Url을 입력하세요" style="width: 100%;" v-model="codeInfo.URL"></td>
                                    </tr>
                                    <tr>
                                        <th>URL_SUB<br>(절대경로)</th>
                                        <td><input type="text" name="url_sub" placeholder="SubUrl을 입력하세요" style="width: 100%;" v-model="codeInfo.URL_SUB"></td>
                                    </tr>
                                    <tr>
                                        <th>접근권한</th>
                                        <td>
                                            <div>
                                                <label>
                                                    <input type="checkbox" name="grade" value="A" v-model="codeInfo.GRADE"> S 관리자 계정
                                                </label>

                                                <label>
                                                    <input type="checkbox" name="grade" value="B" v-model="codeInfo.GRADE"> N 관리자 계정
                                                </label>

                                                <label>
                                                    <input type="checkbox" name="grade" value="W" v-model="codeInfo.GRADE"> 일반 계정
                                                </label>

                                                <label>
                                                    <input type="checkbox" name="grade" value="Z" v-model="codeInfo.GRADE"> 비로그인
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                    <!-- 컨텐츠 테이블 e -->
                </section>
            </div>
            <div class="pu_popup_btn">
                <a class="pu_s_btn_dark pu_pop_close" v-on:click="savePopEdit(undefined, codeInfo)">저장후 닫기</a>
            </div>
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {}

        , data() {

            return {
                gubun_page: "REGI"

                , gubun: "A"                  // A:관리자 U:유저

                , codeDefault: "000|000|000|" // 기본 코드
                , codeSelect: ""              // 선택 코드
                , codeName: ""                // 코드 명
                , codeInfo: {                 // 코드 정보
                    "CODE": "",
                    "DEPTH": 1,
                    "URL": "",
                    "URL_SUB": "",
                    "GRADE": [],
                }

                , pathImgCheck: "/images/icon_check.png"
                , pathImgMove: "/images/icon_move.png"

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                getBase: "getBase"
            })

            // 진입점
            , init() {

                this.getSitecode(this.codeDefault, 1);

                this.$HideDimmLogo();
            }

            // 사이트코드 조회
            , getSitecode: function (code, depth) {

                // qs
                var qs = this.$GetQueryMap();

                // 폼
                var data = {};
                data["gubun"] = this.gubun;
                data["code"] = code;
                data["depth"] = depth;

                // API 조회
                this.$SendPost("/vue_api/zadmin/code_site/list", data, this.searchDataReturn);

            }

            //
            , searchDataReturn: function (data, err) {

                if (data) {
                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        // 초기화
                        for (var idx = 0; idx < data.LIST_1.length; idx++) {
                            data.LIST_1[idx].EDITABLE = "0";  // 수정가능 여부
                            data.LIST_1[idx].SELECT_YN = "N"; // 선택 여부
                            data.LIST_1[idx].PATH_IMG_SELECT = this.pathImgMove; // 선택 이미지
                        }


                        var depth = data.VIEW_1["DEPTH"];
                        switch (depth) {
                            case 1:
                                this.list_1 = data.LIST_1;
                                this.list_2 = [];
                                this.list_3 = [];
                                break;

                            case 2:
                                this.list_2 = data.LIST_1;
                                this.list_3 = [];
                                break;

                            case 3:
                                this.list_3 = data.LIST_1;
                                break;
                        }

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }

            // 메뉴명 입력 보이기
            , showCodeNameInput: function (depth) {
                $(".dev_code_name_depth_" + depth).show();
            }

            // 메뉴명 입력 가리기
            , hideCodeNameInput: function (depth) {
                $(".dev_code_name_depth_" + depth).hide();
            }

            // 메뉴명 추가
            , addCodeName: function (depth) {

                // 폼
                var data = {};
                data["gubun"] = this.gubun;
                data["code"] = this.codeSelect || this.codeDefault;
                data["depth"] = depth;
                data["title"] = this.codeName.trim();

                if (!data["title"]) {
                    alert("코드명을 입력해주세요.");
                }
                else {
                    // API 조회
                    this.$SendPost("/vue_api/zadmin/code_site/addCodeName", data, this.addCodeNameDataReturn);
                }
            }

            // 메뉴명 수정
            , editCodeName: function (depth, code, title) {

                // 폼
                var data = {};
                data["gubun"] = this.gubun;
                data["depth"] = depth;
                data["code"] = code;
                data["title"] = title;

                if (!data["title"]) {
                    alert("코드명을 입력해주세요.");
                }
                else {
                    // API 조회
                    this.$SendPost("/vue_api/zadmin/code_site/editCodeName", data, this.addCodeNameDataReturn);
                }
            }

            // 메뉴명 삭제
            , deleteCodeName: function (depth, code) {

                // 폼
                var data = {};
                data["gubun"] = this.gubun;
                data["depth"] = depth;
                data["code"] = code;

                if (confirm("삭제 하시겠습니까?")) {
                    // API 조회
                    this.$SendPost("/vue_api/zadmin/code_site/deleteCodeName", data, this.addCodeNameDataReturn);
                }
            }

            , addCodeNameDataReturn: function (data, err) {

                if (data) {
                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        // 초기화
                        this.codeName = "";
                        this.hideCodeNameInput(data.VIEW_1["DEPTH"]);

                        // 목록 조회
                        this.getSitecode(data.VIEW_1["CODE"], data.VIEW_1["DEPTH"]);

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }

            // 메뉴명 취소
            , clearCodeName: function (depth) {

                // 초기화
                this.codeName = "";
                this.hideCodeNameInput(depth);
            }

            // 메뉴 선택
            , selectCodeName: function (curList, row) {

                // 초기화
                for (var idx = 0; idx < curList.length; idx++) {
                    curList[idx].SELECT_YN = "N";
                    curList[idx].PATH_IMG_SELECT = this.pathImgMove;
                }

                this.codeSelect = row.CODE;
                let depth = row.DEPTH + 1;
                row.SELECT_YN = "Y";
                row.PATH_IMG_SELECT = this.pathImgCheck;

                // 하위메뉴 조회
                this.getSitecode(this.codeSelect, depth);
            }

            // 메뉴 이동
            , moveUpDw: function (curList, ud) {

                let obSelect = {};
                for (let idx = 0; idx < curList.length; idx++) {
                    let selectYN = curList[idx].SELECT_YN
                    if (selectYN == "Y") {
                        obSelect = curList[idx];
                        break;
                    }
                }

                // 폼
                var data = {};
                data["gubun"] = this.gubun;
                data["depth"] = obSelect.DEPTH;
                data["code"] = obSelect.CODE;
                data["rank"] = obSelect.RANK;
                data["ud"] = ud;

                this.$SendPost("/vue_api/zadmin/code_site/moveCodeName", data, this.addCodeNameDataReturn);
            }

            // 메뉴 수정 보이기
            , showPopEdit: function (row) {

                this.codeInfo.CODE = row.CODE;
                this.codeInfo.DEPTH = row.DEPTH;
                this.codeInfo.URL = row.URL;
                this.codeInfo.URL_SUB = row.URL_SUB;
                this.codeInfo.GRADE = row.GRADE ? row.GRADE.split(",") : [];

                $(".dev_pop_edit").show();
            }

            // 메뉴 수정 숨기기
            , hidePopEdit: function () {
                $(".dev_pop_edit").hide();
            }

            , savePopEdit: function () {

                if (confirm("저장 하시겠습니까?")) {
                    // dimm 보이기
                    this.$ShowDimmLogo();

                    var data = this.$FormToMap($('#regiForm'));
                    data.gubun = this.gubun;

                    this.$SendPost("/vue_api/zadmin/code_site/savePopEdit", data, this.savePopEditDataReturn);
                }
            }

            , savePopEditDataReturn: function (data, err) {

                if (data) {
                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        // 메뉴 수정 숨기기
                        this.hidePopEdit();

                        // 목록 조회
                        this.getSitecode(data.VIEW_1["CODE"], data.VIEW_1["DEPTH"]);
                    }
                }
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>