export default {
    install(Vue) {
        //------------------------------------------[[ function ]]-------------------------------------------

        // 로그 출력
        Vue.prototype.$PrintLog = function (_log) {
            console.log(_log);
            return true;
        };

        // 페이지 이동
        Vue.prototype.$PageMove = function (_url) {

            // console.log(_url);

            // dimm 보임 처리
            this.$ShowDimmLogo(() => {

                var max = 99;
                var min = 10
                var ran = Math.floor(Math.random() * (max - min) + min);

                var arrURL = _url.split("?");

                var arrQS = [];
                if (arrURL.length > 1) {
                    arrQS = arrURL[1].split("&");
                }

                var url = arrURL[0] + "?";

                var sharp = '';
                for (var idx = 0; idx < arrQS.length; idx++) {
                    if (idx > 0) {
                        url += "&";
                    }

                    var _arrQS = arrQS[idx].split("#");
                    sharp = _arrQS[1];
                    url += _arrQS[0];
                }
                url += "&rd=" + ran;

                if (sharp) {
                    url += "#" + sharp;
                }

                url = url.replace("?&", "?");

                // alert(url);

                // 이동
                this.$router.push(url).catch(() => {
                    this.$HideDimmLogo();
                });

            });

            return true;
        };

        // dimm 보임 처리
        Vue.prototype.$ShowDimmLogo = function (_fnc) {
            let state = $("#dev_dimm_logo").css("display");
            if ("none" == state) {
                if (_fnc) {
                    $("#dev_dimm_logo").fadeIn(300, _fnc);
                } else {
                    $("#dev_dimm_logo").fadeIn(300, function () { });
                }
            }

            return true;
        };

        // dimm 숨김 처리
        Vue.prototype.$HideDimmLogo = function (_fnc) {
            let state = $("#dev_dimm_logo").css("display");
            if ("block" == state) {
                if (_fnc) {
                    $("#dev_dimm_logo").fadeOut(500, _fnc);
                } else {
                    $("#dev_dimm_logo").fadeOut(500, function () { });
                }
            }

            return true;
        };

        // 푸터 위치 조정
        Vue.prototype.$SetFooterLocation = function () {

            setTimeout(function () {

                var height_d = $(document).height();
                var height_f = $(".pu_foot").height();
                var _height = height_d - height_f;

                _height = Math.max(800, _height);

                $(".pu_foot")
                    .css("top", _height)
                    .css("opacity", 1);

            }, 1000);
        }

        // 필수입력 항목 표시
        Vue.prototype.$SetInputMustSign = function () {

            var arrName = [];
            var arrInputMust = $(".dev_input_must");

            for (var idx = 0; idx < arrInputMust.length; idx++) {
                var _name = $(arrInputMust[idx]).attr("name");
                if (arrName.indexOf(_name) < 0) {
                    arrName.push(_name);
                }
            }

            $(".pu_input_must_sign").remove();
            for (var idx = 0; idx < arrName.length; idx++) {
                $($("[name='" + arrName[idx] + "'].dev_input_must")[0]).before("<i class='pu_input_must_sign fas fa-caret-up'></i>");
            }

        }

        // 엔터
        Vue.prototype.$SetEventEnter = function (_name, _fnc) {

            $("[name='" + _name + "']").on("keydown", function (e) {

                if (e.keyCode === 13) {

                    e.preventDefault();

                    if (_fnc) {
                        _fnc();
                    }
                }
            });

            return true;
        };

        // URL 백그라운드 이미지
        Vue.prototype.$GetUrlBackImg = function ($obj) {
            var bg = $obj.css('background-image');
            return bg.replace('url(', '').replace(')', '').replace(/\"/gi, "");
        };

        // 모바일 여부 체크
        Vue.prototype.$IsMobile = function ($obj) {
            let check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
                    check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        };

        // value 적용 for vuejs
        Vue.prototype.$SetValueByName = function (name, val) {
            var _ob = document.getElementsByName(name)[0];
            if (_ob) {
                _ob.value = val;
                _ob.dispatchEvent(new Event('input'));
            }
        }

        // 쿼리스트링
        Vue.prototype.$GetQueryMap = function () {

            var params = {};
            window.location.search.replace(
                /[?&]+([^=&]+)=([^&]*)/gi,
                function (str, key, value) {
                    params[key] = decodeURI(value);
                }
            );
            return params;

        };

        // form to map 변환
        Vue.prototype.$FormToMap = function ($form) {

            var form = {}

            var $arrEle = [];

            // text
            $arrEle = $.merge($arrEle, $form.find("[type=text]"));

            // hidden
            $arrEle = $.merge($arrEle, $form.find("[type=hidden]"));

            // password
            $arrEle = $.merge($arrEle, $form.find("[type=password]"));

            // radio
            $arrEle = $.merge($arrEle, $form.find("[type=radio]:checked"));

            // checkbox
            $arrEle = $.merge($arrEle, $form.find("[type=checkbox]:checked"));

            // textarea
            $arrEle = $.merge($arrEle, $form.find("textarea"));

            // select
            $arrEle = $.merge($arrEle, $form.find("select"));

            // name 별 value 정리
            $.each($arrEle, function (idx, el) {

                var name = el.name;
                var value = el.value;

                var arr = form[name];
                if (!arr) {
                    arr = form[name] = [];
                }

                arr.push(value);
            });

            var arrKey = Object.keys(form);
            for (var idx = 0; idx < arrKey.length; idx++) {

                var arrVal = form[arrKey[idx]];
                form[arrKey[idx]] = arrVal.join(",")
            }

            return form;

            //return $form.serializeObject();
        };

        // AfterMounted
        Vue.prototype.$AfterMounted = function (Vue) {

            // 초기화
            {

            }

            // 스크롤 맨위로
            window.scrollTo(0, 0);

            // 검색옵션창 조절
            {
                $(document).off("click", ".dev_btn_opt_open");
                $(document).on("click", ".dev_btn_opt_open", function () {
                    $(".pu_listSearchMulti").removeClass("pu_fixedTopSearchMin");
                });

                $(document).off("click", ".dev_btn_opt_close");
                $(document).on("click", ".dev_btn_opt_close", function () {
                    $(".pu_listSearchMulti").addClass("pu_fixedTopSearchMin");
                });
            }

            // 페이징 function 세팅
            Vue.getRowNum = function (idx) {
                return Vue.rownum - idx;
            };

            // form 안의 enter 키 클릭시 검색
            $(document).off("keydown", "[name='search_text']");
            $(document).on("keydown", "[name='search_text']", function (e) {

                if (e.keyCode === 13) {

                    e.preventDefault();
                    Vue.exec("SEARCH", 1);
                }
            });

            // 리스트 체크박스
            $(document).off("click", ".dev_checkbox_all");
            $(document).on("click", ".dev_checkbox_all", function () {

                let isCheck = $(this).is(":checked");
                $(this).closest("table").find(".dev_checkbox_each").prop("checked", isCheck);
            });

            // 옵션 체크시 자동 검색
            $(document).off("click", ".dev_option :checkbox");
            $(document).on("click", ".dev_option :checkbox", function () {

                Vue.exec("SEARCH", 1);
            });

            // 옵션 체크시 자동 검색
            $(document).off("click", ".dev_option :radio");
            $(document).on("click", ".dev_option :radio", function () {

                Vue.exec("SEARCH", 1);
            });

            // 옵션 셀렉트시 자동 검색
            $(document).off("change", "select.dev_option");
            $(document).on("change", "select.dev_option", function () {

                Vue.exec("SEARCH", 1);
            });

            $(document).off("click", ".dev_click_paging");
            $(document).on("click", ".dev_click_paging", function () {

                var no = $(this).attr("data-paging");
                Vue.exec("SEARCH", no);
            });

            // 검색버튼 클릭시
            $(document).off("click", ".dev_btn_search");
            $(document).on("click", ".dev_btn_search", function () {

                Vue.exec("SEARCH", 1);
            });

            // 저장버튼 클릭시
            $(document).off("click", ".dev_btn_submit");
            $(document).on("click", ".dev_btn_submit", function () {

                Vue.exec("SAVE");
            });

            // 삭제버튼 클릭시
            $(document).off("click", ".dev_btn_delete");
            $(document).on("click", ".dev_btn_delete", function () {

                Vue.exec("DELETE");
            });

            // 숫자만
            $(document).off("keyup", ".dev_numnumnum");
            $(document).on("keyup", ".dev_numnumnum", function () {

                var _num = $(this).val().match(/\d+/g);
                Vue.$SetValueByName($(this).attr("name"), _num);
            });

            // 전화번호
            $(document).off("keyup", ".dev_phone");
            $(document).on("keyup", ".dev_phone", function () {

                var _num = $(this).val().replace(/[^0-9\.]/g, '').toString();
                _num = _num.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");

                Vue.$SetValueByName($(this).attr("name"), _num.substring(0, 14));
            });

            // 이름 - 한글 영문 5글자
            $(document).off("keyup", ".dev_koreng_5");
            $(document).on("keyup", ".dev_koreng_5", function () {

                var val = $(this).val().match(/[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]{1,5}/);
                Vue.$SetValueByName($(this).attr("name"), val);
            });

            // ID - 메일 겸용 영문숫자 _-@
            $(document).off("keyup", ".dev_idmail");
            $(document).on("keyup", ".dev_idmail", function () {

                var val = $(this).val().match(/[a-zA-Z0-9\-_@.]{1,30}/);
                Vue.$SetValueByName($(this).attr("name"), val);
            });

            // 이동 클릭시
            $(document).off("click", ".dev_page_move_view td");
            $(document).on("click", ".dev_page_move_view td", function () {

                var no = $(this).closest("tr").find(".dev_no").val();
                var cntCB = $(this).find(":checkbox").length;
                var cntRB = $(this).find(":radio").length;

                if (no && cntCB < 1 && cntRB < 1) {
                    Vue.$PageMove('./view?no=' + no);
                }
            });

            // 이동 클릭시
            $(document).off("click", ".dev_page_move_regi td");
            $(document).on("click", ".dev_page_move_regi td", function () {

                var no = $(this).closest("tr").find(".dev_no").val();
                var cntCB = $(this).find(":checkbox").length;
                var cntRB = $(this).find(":radio").length;

                if (no && cntCB < 1 && cntRB < 1) {
                    Vue.$PageMove('./regi?no=' + no);
                }
            });

            // row 체크박스
            $(document).off("click", ".dev_tr_checkbox");
            $(document).on("click", ".dev_tr_checkbox", function () {

                let $ob = $(this).find(":checkbox");
                $ob.prop("checked", !$ob.is(":checked"));
            });

            // 이미지 에디터
            if ($("#dev_img_editor").length > 0) {

                // 에디터 wrapper 추가후 editor 옮기기
                {
                    var html =
                        "<div class='pu_img_editor_wrapper'>" +
                        "  <div id='dev_img_editor_img' class='pu_img_editor_img'>" +
                        /* dev_img_editor */
                        "  </div>" +
                        "  <div class='pu_img_editor_btn'>" +
                        "    <a id='btn_new_img_thum' class='pu_btn pu_btn_regi' style='display:none;'>썸네일 변경</a>" +
                        "  </div>" +
                        "</div>";

                    $("#dev_img_editor").parent().append(html);
                    $("#dev_img_editor").detach().appendTo($("#dev_img_editor_img"));
                }
                
                // 에디터 객체
                var editor = Vue.$refs.tuiImageEditor;

                // 첨부파일 이미지 선택시
                $(document).off("click", ".dev_file_item_img_img");
                $(document).on("click", ".dev_file_item_img_img", function () {

                    // 이미지 캔버스 사이즈 0 초기화
                    $(".tui-image-editor-canvas-container .upper-canvas").css("width", "0px");
                    $(".tui-image-editor-canvas-container .lower-canvas").css("width", "0px");
                    $(".tui-image-editor-canvas-container .upper-canvas").css("height", "0px");
                    $(".tui-image-editor-canvas-container .lower-canvas").css("height", "0px");

                    // 선택된 이미지 url
                    var imgUrl = $(this).attr("src");
                    var arrUrl = imgUrl.split("/");

                    // 원본 이미지 파일 명
                    Vue.thum_ori_name = arrUrl[arrUrl.length - 1];

                    // 에디터 모드 설정
                    editor.invoke('loadImageFromURL', imgUrl, 'thum'); // 로딩
                    editor.invoke("startDrawingMode", "CROPPER");      // 자르기 모드

                    setTimeout(function () {

                        editor.invoke("setCropzoneRect", 1);           // 1:1 사이즈

                        // 가로세로 비율 조정
                        var _width = $(".tui-image-editor-canvas-container .upper-canvas").css("max-width");
                        var _height = $(".tui-image-editor-canvas-container .upper-canvas").css("max-height");
                        if (_height > _width) {

                            $(".tui-image-editor-canvas-container .upper-canvas").css("height", "100%");
                            $(".tui-image-editor-canvas-container .lower-canvas").css("height", "100%");

                            $(".tui-image-editor-canvas-container .upper-canvas").css("width", "auto");
                            $(".tui-image-editor-canvas-container .lower-canvas").css("width", "auto");
                        } else {

                            $(".tui-image-editor-canvas-container .upper-canvas").css("height", "auto");
                            $(".tui-image-editor-canvas-container .lower-canvas").css("height", "auto");

                            $(".tui-image-editor-canvas-container .upper-canvas").css("width", "100%");
                            $(".tui-image-editor-canvas-container .lower-canvas").css("width", "100%");
                        }

                        // 저장 버튼
                        $('#btn_new_img_thum').css("display", "block");

                    }, 500);

                });

                // 적용
                $('#btn_new_img_thum').on('click', function () {

                    // 저장 버튼
                    $('#btn_new_img_thum').css("display", "none");

                    // 이미지 자르기
                    var rect = editor.invoke("getCropzoneRect");
                    editor.invoke("crop", rect);
                    setTimeout(function () {

                        editor.invoke("stopDrawingMode");

                        if (confirm("썸네일을 적용 하시겠습니까?")) {

                            // dimm 보이기
                            Vue.$ShowDimmLogo();

                            // canvas to img
                            var canvas = $(".tui-image-editor-canvas-container .lower-canvas")[0];
                            var imgDataUrl = canvas.toDataURL("image/png");

                            var blobBin = atob(imgDataUrl.split(',')[1]);	// base64 데이터 디코딩
                            var array = [];
                            for (var i = 0; i < blobBin.length; i++) {
                                array.push(blobBin.charCodeAt(i));
                            }
                            var file = new Blob([new Uint8Array(array)], { type: 'image/png' });	// Blob 생성

                            // form 생성
                            var thumForm = new FormData();
                            thumForm.append("gubun", "thum");
                            thumForm.append("file", file);
                            thumForm.append("thum_ori_name", Vue.thum_ori_name);

                            // req 생성
                            var req = new XMLHttpRequest();
                            req.open('POST', "/vue_api/common/file_upload/upload", true);

                            req.onload = function (rst) {

                                if (req.status == 200 && req.responseText != "ERROR") {

                                    // 썸네일 이미지 변경
                                    Vue.url_img_item_thum = req.responseText;

                                    // dimm 숨기기
                                    Vue.$HideDimmLogo();

                                } else {

                                    alert("썸네일 적용에 실패하였습니다.");

                                    // dimm 숨기기
                                    Vue.$HideDimmLogo();

                                }
                            };

                            req.onerror = function () {

                                alert("썸네일 적용에 실패하였습니다.");

                                // dimm 숨기기
                                Vue.$HideDimmLogo();

                            };

                            req.send(thumForm);
                        }
                    }, 500);
                });
            }

            // 필수 항목 표시
            this.$SetInputMustSign();

            // 푸터 위치 조정
            this.$SetFooterLocation();

            // 첫 데이터 조회
            Vue.init(Vue);
        };

        // AfterUpdated
        Vue.prototype.$AfterUpdated = function (Vue) {

            // 첨부파일 확장자에 맞게 이미지 변경
            changeFileItemImg();

            // 푸터 위치 조정
            this.$SetFooterLocation();
        };

        //------------------------------------------[[ ajax ]]-------------------------------------------

        // Get
        Vue.prototype.$SendGet = function (_url, _func) {
            /*
            _url  : string
            _func : function(data, err)
            */

            if (!_url)
                _url = "/vue_api/common/common/default";

            $.ajax({
                url: _url,
                async: false, // false 일 경우 동기 요청으로 변경
                type: "GET",
                contentType: "application/x-www-form-urlencoded; charset=UTF-8", // 보내는 데이터 타입
                dataType: "text", // 받는 데이터 타입
                success: (json) => {

                    let _ob = this.$FromJson(json);
                    let data = this.$FromJson(this.$DecBase64(_ob.DATA));

                    // 에러
                    if (false == this.$IsEmpty(data.RESULT_EXCEPTION)) {
                        console.log(data.RESULT_EXCEPTION);
                    }

                    // 알림
                    if (false == this.$IsEmpty(data.RESULT_ALERT)) {
                        alert(data.RESULT_ALERT);
                    }

                    // 기능
                    if (false == this.$IsEmpty(data.RESULT_FUNC)) {
                        eval(data.RESULT_FUNC);
                    }

                    if (false == this.$IsEmpty(data.RESULT_URL)) {
                        // 이동
                        this.$router.push(data.RESULT_URL).catch(() => {
                            this.$HideDimmLogo();
                        });
                    } else {
                        // 리턴
                        _func(data, false);
                    }
                },
                error: (jqXHR, exception) => {
                    let msg = "";
                    if (jqXHR.status === 0) {
                        msg = "Not connect.\n Verify Network.";
                    } else if (jqXHR.status === 404) {
                        msg = "Requested page not found. [404]";
                    } else if (jqXHR.status === 500) {
                        msg = "Internal Server Error [500].";
                    } else if (exception === "parsererror") {
                        msg = "Requested JSON parse failed.";
                    } else if (exception === "timeout") {
                        msg = "Time out error.";
                    } else if (exception === "abort") {
                        msg = "Ajax request aborted.";
                    } else {
                        msg = "Uncaught Error.\n" + jqXHR.responseText;
                    }

                    console.log("Err : ");
                    console.log(" > " + msg);

                    // 리턴
                    _func(false, msg);
                },
            });

            return true;
        };

        // Post
        Vue.prototype.$SendPost = function (_url, _data, _func) {
            /*
            _url  : string
            _data : map
            _func : function(data, err)
            */

            if (!_url)
                _url = "/vue_api/common/common/default";

            $.ajax({
                url: _url,
                async: false, // false 일 경우 동기 요청으로 변경
                type: "POST",
                data: _data,
                contentType: "application/x-www-form-urlencoded; charset=UTF-8", // 보내는 데이터 타입
                dataType: "text", // 받는 데이터 타입
                success: (json) => {

                    let _ob = this.$FromJson(json);
                    let data = this.$FromJson(this.$DecBase64(_ob.DATA));

                    // 에러
                    if (false == this.$IsEmpty(data.RESULT_EXCEPTION)) {
                        console.log(data.RESULT_EXCEPTION);
                    }

                    // 알림
                    if (false == this.$IsEmpty(data.RESULT_ALERT)) {                        
                        alert(data.RESULT_ALERT);
                    }

                    // 기능
                    if (false == this.$IsEmpty(data.RESULT_FUNC)) {
                        eval(data.RESULT_FUNC);
                    }

                    if (false == this.$IsEmpty(data.RESULT_URL)) {
                        // 이동
                        this.$router.push(data.RESULT_URL).catch(() => {
                            this.$HideDimmLogo();
                        });
                    } else {
                        // 리턴
                        _func(data, false);
                    }
                },
                error: (jqXHR, exception) => {
                    let msg = "";
                    if (jqXHR.status === 0) {
                        msg = "Not connect.\n Verify Network.";
                    } else if (jqXHR.status === 404) {
                        msg = "Requested page not found. [404]";
                    } else if (jqXHR.status === 500) {
                        msg = "Internal Server Error [500].";
                    } else if (exception === "parsererror") {
                        msg = "Requested JSON parse failed.";
                    } else if (exception === "timeout") {
                        msg = "Time out error.";
                    } else if (exception === "abort") {
                        msg = "Ajax request aborted.";
                    } else {
                        msg = "Uncaught Error.\n" + jqXHR.responseText;
                    }

                    console.log("Err : ");
                    console.log(" > " + msg);

                    // 리턴
                    _func(false, msg);
                },
            });

            return true;
        };

        //------------------------------------------[[ conversion ]]-------------------------------------------

        // Empty
        Vue.prototype.$IsEmpty = function (_text_1) {

            if (_text_1 == null || typeof _text_1 == "undefined") {
                return true;
            }
            return _text_1.toString().replace(/\s/g, "").length < 1;
        };

        // IsContains
        Vue.prototype.$IsContains = function (_text_1, _text_2) {

            if (false == this.$IsEmpty(_text_1)
                && _text_1.indexOf(this.$ConString(_text_2)) > -1) {

                return true;
            }
            return false;
        };

        // IsStartWith
        Vue.prototype.$IsStartWith = function (_text_1, _text) {

            return _text_1.startsWith(_text);
        };

        // ConString
        Vue.prototype.$ConString = function (_text_1, _text_2) {

            if (this.$IsEmpty(_text_1)) {
                _text_1 = "";
            }

            if (this.$IsEmpty(_text_2)) {
                _text_2 = "";
            }

            if (!_text_1) {
                _text_1 = _text_2;
            }

            return _text_1.toString().trim();
        };

        // ConMoney
        Vue.prototype.$ConMoney = function (_text_1) {

            return this.$ConInt(_text_1).toLocaleString("ko-KR");
        };

        // ConInt
        Vue.prototype.$ConInt = function (_text_1) {

            let num = parseInt(this.$ConString(_text_1));
            if (isNaN(num)) {
                return 0;
            }
            return num;
        };

        // ConDouble
        Vue.prototype.$ConDouble = function (_text_1) {

            let num = parseFloat(this.$ConString(_text_1));
            if (isNaN(num)) {
                return 0;
            }
            return num;
        };

        // ConYN
        Vue.prototype.$ConYN = function (_text_1) {

            if (_text_1 == "Y" || _text_1 == 1) {
                return "예";
            } else if (_text_1 == "N" || _text_1 == 0) {
                return "아니오";
            } else {
                return "";
            }
        };

        // ConGender
        Vue.prototype.$ConGender = function (_text_1) {

            if (
                _text_1 == "M" ||
                _text_1 == 1 ||
                _text_1 == 3 ||
                _text_1 == 5 ||
                _text_1 == 7 ||
                _text_1 == 9
            ) {
                return "남";
            } else if (
                _text_1 == "F" ||
                _text_1 == 0 ||
                _text_1 == 2 ||
                _text_1 == 4 ||
                _text_1 == 6 ||
                _text_1 == 8
            ) {
                return "여";
            } else {
                return "";
            }
        };

        // ConHtml
        Vue.prototype.$ConHtml = function (_text_1) {

            let _textarea = document.createElement("textarea");
            if (_text_1) {
                _textarea.innerHTML = _text_1;
            }
            return _textarea.childNodes.length === 0
                ? ""
                : _textarea.childNodes[0].nodeValue;
        };

        // ToJson
        Vue.prototype.$ToJson = function (_ob_1) {

            return JSON.stringify(_ob_1);
        };

        // FromJson
        Vue.prototype.$FromJson = function (_text_1) {

            return JSON.parse(_text_1);
        };

        // EncBase64
        Vue.prototype.$EncBase64 = function (_text_1) {

            return Base64.encode(_text_1);
        };

        // DecBase64
        Vue.prototype.$DecBase64 = function (_text_1) {

            return Base64.decode(_text_1);
        };




        // SetCookie
        Vue.prototype.$SetCookie = function (name, value, expirehours, domain) {

            var today = new Date();
            today.setTime(today.getTime() + (60 * 60 * 1000 * expirehours));
            document.cookie = name + "=" + escape(value) + "; path=/; expires=" + today.toGMTString() + ";";
            if (domain) {
                document.cookie += "domain=" + domain + ";";
            }
        };

        // GetCookie
        Vue.prototype.$GetCookie = function (name) {

            var find_sw = false;
            var start, end;
            var i = 0;

            for (i = 0; i <= document.cookie.length; i++) {
                start = i;
                end = start + name.length;

                if (document.cookie.substring(start, end) == name) {
                    find_sw = true
                    break
                }
            }

            if (find_sw == true) {
                start = end + 1;
                end = document.cookie.indexOf(";", start);

                if (end < start)
                    end = document.cookie.length;

                return unescape(document.cookie.substring(start, end));
            }
            return "";
        };

        // DelCookie
        Vue.prototype.$DelCookie = function (name) {

            var today = new Date();

            today.setTime(today.getTime() - 1);
            var value = this.$GetCookie(name);
            if (value != "")
                document.cookie = name + "=" + value + "; path=/; expires=" + today.toGMTString();
        };
    },
};
