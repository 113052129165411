<template>
    <!-- top title (s) -->
    <section class="pu_locationSection">
        <h2>{{ this.getBase.adminMenuName_3d }}</h2>
        <div>
            <ul class="pu_location">
                <li><a href="/zadmin/exam_select/list"><i class="xi-home">홈</i></a></li>
                <li v-html="this.getBase.adminMenuName_1d"></li>
                <li v-html="this.getBase.adminMenuName_2d"></li>
                <li v-html="this.getBase.adminMenuName_3d"></li>
            </ul>
            <div class="pu_logout"><a href="#" class="xi-unlock-o" title="로그아웃"><span>로그아웃</span></a></div>
        </div>
    </section>
    <!-- top title (e) -->
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase",
            }),
            
        }

        ,data() {
            return {
                THIS_NAME: "INC ZADMIN TITLE"
            };
        },

        methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {
            },

        },

        beforeCreate() {
        },

        created() {
        },

        beforeMount() {
            this.$PrintLog(this.THIS_NAME + " : beforeMount");
            this.init();
        },

        mounted() {
            this.$PrintLog(this.THIS_NAME + " : mounted");
        },

        beforeUpdate() {
            this.$PrintLog(this.THIS_NAME + " : beforeUpdate");
        },

        updated() {
            this.$PrintLog(this.THIS_NAME + " : updated");
        },

        beforeDestroy() {
        },

        destroyed() {
        },
    };
</script>
