<template>

    <div class="pu_wd_def_min pu_wrapper_main">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(s) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-------------------------- 인클루드(s) -------------------------->
            <inc_user_banner_top />
            <!-------------------------- 인클루드(s) -------------------------->

            <div class="pu_wrapper_body pu_wd_def_max">

                <div class="pu_wrapper_body_left_menu">
                    <!-------------------------- 인클루드(s) -------------------------->
                    <inc_user_left />
                    <!-------------------------- 인클루드(s) -------------------------->
                </div>

                <div class="pu_wrapper_body_right_cont">
                    <!-------------------------- 인클루드(s) -------------------------->
                    <inc_user_title />
                    <!-------------------------- 인클루드(s) -------------------------->
                    <!-- 서브 실제 컨텐츠 영역 (s) -->
                    <div class="pu_wd_def_max pu_layoutContent">

                        <!-- 검색영역(s) -->
                        <form id="searchForm" onsubmit="return false">
                            <div class="pu_listSearchMulti">
                                <div class="pu_wd_def_max">
                                    <dl class="">
                                        <dt><label>교육과정</label></dt>
                                        <dd>
                                            <select class="" name="code_subject_1" v-model="code_subject_depth_1"
                                                    v-on:change="exec('SUBJECT', 2, '', $event)" title="과목을 선택하세요.">
                                                <option value="">- 과목 선택</option>
                                                <option v-for="(row, idx) in opt_code_subject_depth_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                            </select>

                                            <select class="" name="code_subject_2" v-model="code_subject_depth_2"
                                                    v-on:change="exec('SUBJECT', 3, '', $event)" title="개정년도를 선택하세요.">
                                                <option value="">- 개정년도 선택</option>
                                                <option v-for="(row, idx) in opt_code_subject_depth_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                            </select>

                                            <select class="" name="code_subject_3" v-model="code_subject_depth_3"
                                                    v-on:change="exec('SUBJECT', 4, '', $event)" title="교육과정을 선택하세요.">
                                                <option value="">- 교육과정 선택</option>
                                                <option v-for="(row, idx) in opt_code_subject_depth_3" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                            </select>

                                            <select class="" name="code_subject_4" v-model="code_subject_depth_4"
                                                    v-on:change="exec('SUBJECT', 5, '', $event)" title="대단원을 선택하세요.">
                                                <option value="">- 대단원 선택</option>
                                                <option v-for="(row, idx) in opt_code_subject_depth_4" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                            </select>

                                            <select class="" name="code_subject_5" v-model="code_subject_depth_5"
                                                    v-on:change="exec('SUBJECT', 6, '', $event)" title="중단원을 선택하세요.">
                                                <option value="">- 중단원 선택</option>
                                                <option v-for="(row, idx) in opt_code_subject_depth_5" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                            </select>
                                        </dd>
                                    </dl>

                                    <dl class="pu_searchItemHide">
                                        <dt><label>학교</label></dt>
                                        <dd>
                                            <select class="" name="code_area_1" v-model="code_area_depth_1"
                                                    v-on:change="exec('AREA', 2, '', $event)" title="시도를 선택하세요.">
                                                <option value="">- 시도 선택</option>
                                                <option v-for="(row, idx) in opt_code_area_depth_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                            </select>

                                            <select class="" name="code_area_2" v-model="code_area_depth_2"
                                                    v-on:change="exec('AREA', 3, '', $event)" title="구군을 선택하세요.">
                                                <option value="">- 구군 선택</option>
                                                <option v-for="(row, idx) in opt_code_area_depth_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                            </select>

                                            <select class="" name="code_area_3" v-model="code_area_depth_3"
                                                    v-on:change="exec('AREA', 4, '', $event)" title="초중고를 선택하세요.">
                                                <option value="">- 초중고 선택</option>
                                                <option v-for="(row, idx) in opt_code_area_depth_3" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                            </select>

                                            <select class="" name="code_area_4" v-model="code_area_depth_4"
                                                    v-on:change="exec('AREA', 5, '', $event)" title="학교명을 선택하세요.">
                                                <option value="">- 학교 선택</option>
                                                <option v-for="(row, idx) in opt_code_area_depth_4" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                            </select>
                                        </dd>
                                    </dl>

                                    <dl class="">
                                        <dt><label>타입</label></dt>
                                        <dd>
                                            <select name="gubun_exam_type" class="dev_option"
                                                    title="타입을 선택하세요.">
                                                <option value="">- 타입 선택</option>
                                                <option value="A">선택</option>
                                                <option value="B">서술</option>
                                            </select>
                                        </dd>

                                        <dt class="pu_ml20"><label>난이도</label></dt>
                                        <dd>
                                            <select name="gubun_exam_diff" class="dev_option"
                                                    title="난이도를 선택하세요.">
                                                <option value="">- 난이도 선택</option>
                                                <option value="1">하</option>
                                                <option value="2">중</option>
                                                <option value="3">상</option>
                                                <option value="4">킬러</option>
                                            </select>
                                        </dd>

                                        <dt class="pu_ml20"><label>년도</label></dt>
                                        <dd>
                                            <select name="school_year" class="dev_option"
                                                    title="년도를 선택하세요.">
                                                <option value="">- 년도 선택</option>
                                                <option v-for="(row, idx) in opt_school_year" v-bind:value="row.YEAR">{{ row.YEAR }}</option>
                                            </select>
                                        </dd>

                                        <dt class="pu_ml20"><label>학기</label></dt>
                                        <dd>
                                            <select name="school_semester" class="dev_option"
                                                    title="학기를 선택하세요.">
                                                <option value="">- 학기 선택</option>
                                                <option v-for="(row, idx) in opt_school_semester" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                            </select>
                                        </dd>
                                    </dl>

                                    <dl class="pu_searchItemHide">
                                        <dt><label>수식</label></dt>
                                        <dd>
                                            <a class="pu_ss_btn_grey" href="javascript:;" style="margin-top: 2px;"
                                               v-on:click="getLatex()">수식추가</a>
                                        </dd>
                                        <dd>

                                            <!--라텍스 검색-->
                                            <ul class="dev_search_latex_ul pu_search_latex_ul"></ul>
                                            <!--//라텍스 검색-->

                                        </dd>
                                    </dl>

                                    <dl class="pu_searchItemHide">
                                        <dt><label>검색조건</label></dt>
                                        <dd>
                                            <select name="search_gubun" class="" title="검색조건을 선택하세요.">
                                                <option value="cont_q" selected="selected">질문 내용</option>
                                            </select>
                                            <input type="text" name="search_text" placeholder="검색어를 입력해주세요." style="width: 400px;" />
                                        </dd>
                                        <dd>
                                            <a href="javascript:;" class="pu_btnSerch01">검색</a>
                                        </dd>
                                    </dl>

                                    <div class="pu_btn_wrap">
                                        <a class="dev_btn_opt_open pu_btn_opt_open pu_ss_btn_defalut"><i class="xi-caret-down"></i> 옵션</a>
                                        <a class="dev_btn_opt_close pu_btn_opt_close pu_ss_btn_grey"><i class="xi-caret-up"></i> 옵션</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- 검색영역(e) -->
                        <!-- 리스트(s) -->
                        <div>
                            <table class="pu_table_list">
                                <colgroup>
                                    <col width="70px" />
                                    <col width="*" />
                                    <col width="100px" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col" style="border-right: solid 1px #bebebe;">번호</th>
                                        <th scope="col" style="border-right: solid 1px #bebebe;">내용</th>
                                        <th scope="col">등록일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="dev_page_move_regi"
                                        v-for="(row, idx) in list_1">
                                        <td style="border-right: solid 1px #bebebe;">
                                            <input type="hidden" class="dev_no" :value="row.NO" />
                                            {{ getRowNum(idx) }}
                                        </td>
                                        <td style="border-right: solid 1px #bebebe;">
                                            <div>
                                                <table class="pu_table_inner">
                                                    <colgroup>
                                                        <col style="width:10%;" />
                                                        <col style="width:40%;" />
                                                        <col style="width:10%;" />
                                                        <col style="width:40%;" />
                                                    </colgroup>
                                                    <tbody>
                                                        <tr>
                                                            <th class="">교육과정</th>
                                                            <td class="">{{ row.CODE_SUBJECT_NAME }}</td>
                                                            <th class="">타입 / 난이도</th>
                                                            <td class="">{{ row.GUBUN_EXAM_TYPE_NAME }} / {{ row.GUBUN_EXAM_DIFF_NAME }}</td>
                                                        </tr>
                                                        <tr v-if="row.CODE_AREA_NAME">
                                                            <th class="">학교</th>
                                                            <td class="">{{ row.CODE_AREA_NAME }}</td>
                                                            <th class="">년도 / 학기명</th>
                                                            <td class="">{{ row.SCHOOL_YEAR }} / {{ row.SCHOOL_SEMESTER_NAME }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="2" class="">질문 내용</th>
                                                            <th colspan="2" class="">해설 내용</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" class="" style="vertical-align:top;">
                                                                <div v-html="$ConHtml(row.CONT_Q)" style="transform: scale(0.9); transform-origin: top;"></div>
                                                            </td>
                                                            <td colspan="2" class="" style="vertical-align:top;">
                                                                <div v-html="$ConHtml(row.CONT_A)" style="transform: scale(0.9); transform-origin: top;"></div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                        <td>
                                            {{ row.REGI_DATE }}
                                        </td>
                                    </tr>
                                    <tr v-if="list_1.length == 0">
                                        <td colspan="3">조회된 결과가 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- 리스트(e) -->

                        <div class="pu_divBtnMain">
                            <div class="pu_divLeft">
                                <a class="pu_btn_red" href="javascript:;" v-on:click="$PageMove('./regi')"><i class="xi-pen"></i> 신규등록</a>
                            </div>
                            <div class="pu_divCenter">
                                <!-- 페이징(s) -->
                                <div class="pu_pagination_2" v-html="paging"></div>
                                <!-- 페이징(e) -->
                            </div>
                            <div class="pu_divRight">
                            </div>
                        </div>

                    </div>
                    <!-- 서브 실제 컨텐츠 영역 (e) -->

                </div>
                <!-------------------------- 서브 실제 컨텐츠 영역 (e) -------------------------->
            </div>
        </div>

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , isOkSearch: false // 검색가능
                , list_1: []

                , code_subject: "" // 검색 코드
                , code_area: ""    // 검색 코드

                , opt_code_subject_depth_1: [] // 과목 선택 옵션
                , opt_code_subject_depth_2: []
                , opt_code_subject_depth_3: []
                , opt_code_subject_depth_4: []
                , opt_code_subject_depth_5: []

                , code_subject_depth_1: ''     // 과목 선택 코드
                , code_subject_depth_2: ''
                , code_subject_depth_3: ''
                , code_subject_depth_4: ''
                , code_subject_depth_5: ''

                , opt_code_area_depth_1: [] // 학교 선택 옵션
                , opt_code_area_depth_2: []
                , opt_code_area_depth_3: []
                , opt_code_area_depth_4: []

                , code_area_depth_1: ''     // 학교 선택 코드
                , code_area_depth_2: ''
                , code_area_depth_3: ''
                , code_area_depth_4: ''

                , opt_school_year: []     // 년도

                , opt_school_semester: [] // 학기
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                // 검색 latex
                {
                    $(document).off("click", ".dev_search_latex_li");
                    $(document).on("click", ".dev_search_latex_li", function () {

                        if (confirm("수식을 삭제 하시겠습니까?")) {

                            // 삭제
                            $(this).remove();

                            // 검색
                            _this.exec("SEARCH", 1);
                        }
                    })
                }

                // 과목코드 조회
                _this.exec("SUBJECT", 1, "000|000|000|000|000|");

                // 학교코드 조회
                _this.exec("AREA", 1, "000|000|000|000|");

                // 년도코드 조회
                _this.exec("YEAR");

                // 학기코드 조회
                _this.exec("SEMESTER");

                // 검색
                _this.exec("SEARCH", 1);
            }

            // 실행
            , exec: function (gubun, num, code, event) {

                // this
                let _this = this;
                let url = "";
                let qs = _this.$GetQueryMap();
                let data = { "gubunProc": gubun, "gubun_page": _this.gubun_page };

                switch (gubun) {

                    case "SEARCH":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_make/list";

                            // 폼
                            data["gubun_page"] = _this.gubun_page;
                            data["curPage"] = num;
                            data["code_subject"] = _this.code_subject;
                            data["code_area"] = _this.code_area;

                            // 라텍스
                            {
                                let arrLatex = [];
                                let $arrLi = $(".dev_search_latex_li");
                                for (let idx = 0; idx < $arrLi.length; idx++) {

                                    let latex = $($arrLi[idx]).find("svg").attr("data-latex");
                                    arrLatex.push(latex);
                                }

                                data["search_latex"] = arrLatex.join(",");
                            }

                            // 데이터 복사
                            Object.assign(data, _this.$FormToMap($("#searchForm")));
                        }
                        break;

                    case "SUBJECT":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_make/code_subject";

                            data["depth"] = num;

                            if (code) {
                                data["code"] = code;
                            }
                            else if (event) {
                                data["code"] = event.target.value;
                            }
                        }
                        break;

                    case "AREA":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_make/code_area";

                            data["depth"] = num;

                            if (code) {
                                data["code"] = code;
                            }
                            else if (event) {
                                data["code"] = event.target.value;
                            }
                        }
                        break;

                    case "YEAR":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_make/code_year";
                        }
                        break;

                    case "SEMESTER":
                        {
                            // url 설정
                            url = "/vue_api/user/exam_make/code_semester";
                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "SEARCH":
                        {
                            _this.rownum = data.ROWNUM;
                            _this.paging = data.PAGING;
                            _this.list_1 = data.LIST_1;

                            _this.isOkSearch = true; // 검색가능
                        }
                        break;

                    case "SUBJECT":
                        {
                            var depth = data.HASH.DEPTH;
                            switch (depth) {
                                case 1:
                                    _this.opt_code_subject_depth_1 = data.LIST_1; _this.code_subject_depth_1 = "";
                                    _this.opt_code_subject_depth_2 = []; _this.code_subject_depth_2 = "";
                                    _this.opt_code_subject_depth_3 = []; _this.code_subject_depth_3 = "";
                                    _this.opt_code_subject_depth_4 = []; _this.code_subject_depth_4 = "";
                                    _this.opt_code_subject_depth_5 = []; _this.code_subject_depth_5 = "";
                                    break;

                                case 2:
                                    _this.opt_code_subject_depth_2 = data.LIST_1; _this.code_subject_depth_2 = "";
                                    _this.opt_code_subject_depth_3 = []; _this.code_subject_depth_3 = "";
                                    _this.opt_code_subject_depth_4 = []; _this.code_subject_depth_4 = "";
                                    _this.opt_code_subject_depth_5 = []; _this.code_subject_depth_5 = "";
                                    break;

                                case 3:
                                    _this.opt_code_subject_depth_3 = data.LIST_1; _this.code_subject_depth_3 = "";
                                    _this.opt_code_subject_depth_4 = []; _this.code_subject_depth_4 = "";
                                    _this.opt_code_subject_depth_5 = []; _this.code_subject_depth_5 = "";
                                    break;

                                case 4:
                                    _this.opt_code_subject_depth_4 = data.LIST_1; _this.code_subject_depth_4 = "";
                                    _this.opt_code_subject_depth_5 = []; _this.code_subject_depth_5 = "";
                                    break;

                                case 5:
                                    _this.opt_code_subject_depth_5 = data.LIST_1; _this.code_subject_depth_5 = "";
                                    break;
                            }

                            // 과정선택
                            {
                                _this.code_subject =
                                    _this.code_subject_depth_5 ? _this.code_subject_depth_5 :
                                        _this.code_subject_depth_4 ? _this.code_subject_depth_4 :
                                            _this.code_subject_depth_3 ? _this.code_subject_depth_3 :
                                                _this.code_subject_depth_2 ? _this.code_subject_depth_2 : _this.code_subject_depth_1;
                            }

                            // 검색
                            if (true == _this.isOkSearch) {
                                _this.exec("SEARCH", 1);
                            }
                        }
                        break;

                    case "AREA":
                        {
                            var depth = data.HASH.DEPTH;
                            switch (depth) {
                                case 1:
                                    _this.opt_code_area_depth_1 = data.LIST_1; _this.code_area_depth_1 = "";
                                    _this.opt_code_area_depth_2 = []; _this.code_area_depth_2 = "";
                                    _this.opt_code_area_depth_3 = []; _this.code_area_depth_3 = "";
                                    _this.opt_code_area_depth_4 = []; _this.code_area_depth_4 = "";
                                    break;

                                case 2:
                                    _this.opt_code_area_depth_2 = data.LIST_1; _this.code_area_depth_2 = "";
                                    _this.opt_code_area_depth_3 = []; _this.code_area_depth_3 = "";
                                    _this.opt_code_area_depth_4 = []; _this.code_area_depth_4 = "";
                                    break;

                                case 3:
                                    _this.opt_code_area_depth_3 = data.LIST_1; _this.code_area_depth_3 = "";
                                    _this.opt_code_area_depth_4 = []; _this.code_area_depth_4 = "";
                                    break;

                                case 4:
                                    _this.opt_code_area_depth_4 = data.LIST_1; _this.code_area_depth_4 = "";
                                    break;
                            }

                            // 과정선택
                            {
                                _this.code_area =
                                    _this.code_area_depth_4 ? _this.code_area_depth_4 :
                                        _this.code_area_depth_3 ? _this.code_area_depth_3 :
                                            _this.code_area_depth_2 ? _this.code_area_depth_2 : _this.code_area_depth_1;
                            }

                            // 검색
                            if (true == _this.isOkSearch) {
                                _this.exec("SEARCH", 1);
                            }
                        }
                        break;

                    case "YEAR":
                        {
                            _this.opt_school_year = data.LIST_1;
                        }
                        break;

                    case "SEMESTER":
                        {
                            _this.opt_school_semester = data.LIST_1;
                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }

            // 라텍스 조회
            , getLatex: function () {

                // this
                let _this = this;

                window.removeEventListener("message", _this.getLatexReturn, false);
                window.addEventListener("message", _this.getLatexReturn);

                FrameLayer.open("https://js.hyper-nomad.com/module/matheditor/index.html", '720px', '520px');
            }
            , getLatexReturn: function (event) {

                // this
                let _this = this;

                let $span = $(event.data);
                let $svg = $span.find("svg");

                let val = $svg.attr("data-latex");
                if (val) {
                    let $li = $("<li class='dev_search_latex_li pu_search_latex_li'></li>")
                    $li.append($svg);
                    $(".dev_search_latex_ul").append($li);
                }

                FrameLayer.close();

                // 검색
                _this.exec("SEARCH", 1);
            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }
    }

</script>