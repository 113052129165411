<template>

    <div class="pu_wd_def_min pu_wrapper_main">

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_head />
        <inc_user_top />
        <!-------------------------- 인클루드(s) -------------------------->

        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_home">

            <!-- 메인비쥬얼 상단영역 s -->
            <section class="pu_mainTopVisual">

                <!-- 메인 슬라이딩  s -->
                <div class="pu_slide_box">
                    <ul class="pu_slide_pic">
                        <li style="background:url('/common/img/main_visual01.png') no-repeat;background-size:cover;">
                            <div class="pu_wd_def_max">
                                <p class="pu_txt01">Real time Information Pork Industry</p>
                                <p class="pu_title01"><strong class="pu_txtWhite">양돈산업전문</strong> 종합컨설팅</p>
                                <p class="pu_linkbtn"> <a href="javascript:;">자세히보기</a></p>
                            </div>
                        </li>
                        <li style="background:url('/common/img/main_visual02.png') no-repeat;background-size:cover;">
                            <div class="pu_wd_def_max">
                                <p class="pu_txt01">Welcome to Jung P&C Laboratory</p>
                                <p class="pu_title01"><strong class="pu_txtWhite">건강,선진적</strong> 식문화사회 실천</p>
                                <p class="pu_linkbtn"> <a href="javascript:;">자세히보기</a></p>
                            </div>
                        </li>
                        <li style="background:url('/common/img/main_visual03.png') no-repeat;background-size:cover;">
                            <div class="pu_wd_def_max">
                                <p class="pu_txt01 pu_letter">최신 기술과 시설을 갖춘 공식 식품 유전자 분석 기관</p>
                                <p class="pu_title01">기능성유전자 <strong class="pu_txtWhite">GMO분석센터</strong></p>
                                <p class="pu_linkbtn"> <a href="javascript:;">자세히보기</a></p>
                            </div>
                        </li>
                        <li style="background:url('/common/img/main_visual04.png') no-repeat;background-size:cover;">
                            <div class="pu_wd_def_max">
                                <p class="pu_txt01">Real time Information Pork Industry</p>
                                <p class="pu_title01"><strong class="pu_txtWhite">종돈산업전문</strong> 종합컨설팅</p>
                                <p class="pu_linkbtn"> <a href="javascript:;">자세히보기</a></p>
                            </div>
                        </li>
                    </ul>
                    <ul class="pu_slide_tab"></ul>
                    <a class="pu_slide_nav pu_slide_prev" href="javascript:">&lt;</a>
                    <a class="pu_slide_nav pu_slide_next" href="javascript:">&gt;</a>
                </div>
                <!-- 메인슬라이딩 e -->
                <!--  gmo분석센터 / 통합건색영역 (s)-->
                <div class="pu_wd_def_max pu_jpncW pu_gmoSection">

                    <!-- gmo분석센터s  -->
                    <div class="pu_mainGmo">
                        <article>
                            <div class="pu_title">
                                <h2><span>GMO</span> 분석센터</h2>
                                <p>식품위생전문 검사기관</p>
                            </div>
                            <ul>
                                <li><a href="javascript:;">검사절차</a> </li>
                                <li><a href="javascript:;">분석의뢰서</a> </li>
                                <li><a href="javascript:;">분석조회</a> </li>
                            </ul>
                        </article>
                    </div>
                    <!-- gmo분석센터e  -->
                    <!-- 통합건색영역s  -->
                    <div class="pu_mainSearch">

                        <label for="">통합검색</label>
                        <input type="text" placeholder="검색어를 입력해주세요.">
                        <input type="button" class="pu_btnSerch" title="검색">

                        <article>
                            <a href="javascript:;">#양돈산업</a>
                            <a href="javascript:;">#항생제</a>
                            <a href="javascript:;">#유전자검사</a>
                            <a href="javascript:;">#항체생성</a>
                            <a href="javascript:;">#질병</a>
                        </article>
                    </div>
                    <!-- 통합건색영역e  -->
                </div>
                <!--  gmo분석센터 / 통합건색영역 (e)-->

            </section>
            <!-- 메인비쥬얼 상단영역 e -->
            <!-- 소식및이벤트 영역 (s) -->
            <section class="pu_wd_def_max pu_jpncW pu_mainIssue">
                <!-- 뉴스영역s -->
                <div class="pu_mainNews">
                    <div class="pu_tabTitle">
                        <h2>정P&C<span>소식</span></h2>
                        <ul>
                            <li class="pu_on"><span>최신</span></li>
                            <li><span>공지사항</span></li>
                            <li><span>양돈정보</span></li>
                            <li><span>양돈뉴스</span></li>
                            <li><span>국내뉴스</span></li>
                        </ul>
                    </div>
                    <div class="pu_MainNewsList">
                        <ul>
                            <li>
                                <dl>
                                    <dt>
                                        <p>양돈뉴스 <span class="pu_new">N</span></p>
                                    </dt>
                                    <dd class="pu_subject">
                                        <a href="javascript:;">
                                            창립 50주년 앞둔 선진, ‘지속 가능한 축산업’ 위한 미래비전
                                            제시하다
                                        </a>
                                    </dd>
                                    <dd class="pu_date">2022.07.20</dd>
                                </dl>
                            </li>
                            <li>
                                <dl>
                                    <dt>
                                        <p>양돈뉴스</p>
                                    </dt>
                                    <dd class="pu_subject"><a href="javascript:;">중국 2위 양돈업체, 사료공급 중단</a></dd>
                                    <dd class="pu_date">2022.07.20</dd>
                                </dl>
                            </li>
                        </ul> <!-- // 1단 -->
                        <ul>
                            <li>
                                <dl>
                                    <dt>
                                        <p>양돈정보 <span class="pu_pay">유</span></p>
                                    </dt>
                                    <dd class="pu_subject">
                                        <a href="javascript:;">
                                            본격적인 휴가철로 인한 돈가의 강보합세 유지세 양돈시장의 변화를
                                            꾀하다.
                                        </a>
                                    </dd>
                                    <dd class="pu_date">2022.07.20</dd>
                                </dl>
                            </li>
                            <li>
                                <dl>
                                    <dt>
                                        <p>공지사항</p>
                                    </dt>
                                    <dd class="pu_subject">
                                        <a href="javascript:;">
                                            DNS 서버 변경으로 인해 잠시 홈페이지가 열리지 않을 수
                                            있습니다.
                                        </a>
                                    </dd>
                                    <dd class="pu_date">2022.07.20</dd>
                                </dl>
                            </li>
                        </ul> <!-- // 2단 -->
                        <ul>
                            <li>
                                <dl>
                                    <dt>
                                        <p>양돈정보</p>
                                    </dt>
                                    <dd class="pu_subject">
                                        <a href="javascript:;">
                                            우크라 곡물수출 협상 타결‥흑해 항로 재개, 22일 서명식
                                            준비
                                        </a>
                                    </dd>
                                    <dd class="pu_date">2022.07.20</dd>
                                </dl>
                            </li>
                            <li>
                                <dl>
                                    <dt>
                                        <p>양돈정보 <span class="pu_pay">유</span></p>
                                    </dt>
                                    <dd class="pu_subject">
                                        <a href="javascript:;">
                                            우크라 곡물수출 협상 타결‥흑해 항로 재개, 22일 서명식 준비DNS 서버 변경으로 인해
                                            잠시 홈페이지가 열리지 않을 수 있습니다.
                                        </a>
                                    </dd>
                                    <dd class="pu_date">2022.07.20</dd>
                                </dl>
                            </li>
                        </ul> <!-- // 3단 -->
                    </div>
                </div>
                <!-- 뉴스영역e -->
                <!-- 이벤트배너 영역s -->
                <div class="pu_mainEvent">
                    <div id="pu_MBanner">
                        <div class="title">
                            <h2>정P&C<span>이슈</span></h2>
                        </div>
                        <div class="pu_jpnc-slider">
                            <div>
                                <a class="pu_bannerLinks" href="javascript:;">
                                    <p class="title">제목 : 환절기 주의 이벤트</p>
                                    <img src="/common/img/main_banner01.png" alt="">
                                </a>
                            </div>
                            <div>
                                <a class="pu_bannerLinks" href="javascript:;">
                                    <p class="title">제목 : 서버점검 안내</p>
                                    <img src="/common/img/main_banner02.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="pu_mainIconLink">
                        <ul>
                            <li>
                                <a href="javascript:;">
                                    <span class="pu_icons">
                                        <i class="xi-book-o xi-2x"></i>
                                    </span><br><span class="pu_txt">정P&C 매거진</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;">
                                    <span class="pu_icons"><i class="xi-bell-o xi-2x"></i></span>
                                    <br><span class="pu_txt">알림 서비스 신청</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
                <!-- 이벤트배너 영역e -->

            </section>
            <!-- 소식및이벤트 영역 (s) -->
            <!-- 분석센터 영역 (s) -->
            <section class="pu_wd_def_max pu_jpncW pu_BusinessSection">
                <div class="pu_mainBusiness">
                    <div class="pu_PContent">
                        <div class="pu_BusinessTitle">
                            <h2><span>분석</span>CENTER</h2>
                            <p>정피앤씨연구소는 국내최고의 공인검사법으로 신뢰있는 검사를 합니다.</p>
                        </div>
                        <div class="pu_jpnc-slider">
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business01.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>한우 확인검사</dt>
                                        <dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business02.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>GMO 검사</dt>
                                        <dd>유전자를 다른 생물체의 유전자와 결합시켜 특정한 목적에 맞도록 유전자 일부를 변형</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business03.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>백수오(식품) 검사</dt>
                                        <dd>백수오 검사를 합니다.설명글 입력예정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business04.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>쌀/현미 품종 검사</dt>
                                        <dd>쌀·현미품종검정은 국립농산물품질관리원의 「SNP 유전자분석법」을 이용한 분석법입니다.</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business05.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>살모넬라 검사</dt>
                                        <dd>살모넬라 검사</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business06.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>A2 Milk</dt>
                                        <dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business07.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>항생제 검사</dt>
                                        <dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                        </div>
                    </div>

                    <!--------------------------  mobile버전 배너(s) -------------------------->
                    <div class="pu_MContent">
                        <div class="pu_wd_def_max pu_jpncW pu_BusinessTitle">
                            <h2><span>분석</span>CENTER</h2>
                            <p>국내최고의 공인검사법으로 신뢰있는 검사를 합니다.</p>
                        </div>
                        <div class="pu_jpnc-slider">
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business01.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>한우 확인검사</dt>
                                        <dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business02.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>GMO 검사</dt>
                                        <dd>유전자를 다른 생물체의 유전자와 결합시켜 특정한 목적에 맞도록 유전자 일부를 변형</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business03.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>백수오(식품) 검사</dt>
                                        <dd>백수오 검사를 합니다.설명글 입력예정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business04.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>쌀/현미 품종 검사</dt>
                                        <dd>쌀·현미품종검정은 국립농산물품질관리원의 「SNP 유전자분석법」을 이용한 분석법입니다.</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business05.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>살모넬라 검사</dt>
                                        <dd>살모넬라 검사</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business06.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>A2 Milk</dt>
                                        <dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business07.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>항생제 검사</dt>
                                        <dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                        </div>
                    </div>
                    <!-------------------------- mobile버전 배너(e) -------------------------->
                </div>
            </section>
            <!-- 분석센터 영역 (e) -->
            <!-- 퀵링크 배너 (s) -->
            <section class="pu_wd_def_max pu_jpncW pu_mainQuick">
                <ul>
                    <li>
                        <a href="javascript:;">
                            <p class="pu_Quick01"></p>
                            <span>신기술 / 신정보</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <p class="pu_Quick02"></p>
                            <span>주요사업 및 상품</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <p class="pu_Quick03"></p>
                            <span>국내시장정보</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <p class="pu_Quick04"></p>
                            <span>해외시장정보</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <p class="pu_Quick05"></p>
                            <span>질병정보</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <p class="pu_Quick06"></p>
                            <span>연구수행 및 과제</span>
                        </a>
                    </li>
                </ul>
            </section>
            <!-- 퀵링크 배너 (e) -->
            <!-- 찾아오시는 길(s) -->
            <section class="pu_mainMapSection">
                <div class="pu_wd_def_max">
                    <div class="pu_mainCompay">
                        <div class="pu_mainCompayInfo">
                            <div class="pu_title">
                                <h2>정P&C<span>연구소</span></h2>
                                <p>경기도 용인시 기흥구 흥덕중앙로 20 U-Tower 1504 호</p>
                            </div>
                            <ul>
                                <li><a href="javascript:;"><span>회사소개</span> <i class="xi-city"></i></a></li>
                                <li><a href="javascript:;"><span>오시는길</span> <i class="xi-maker"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="pu_mainMap"></div>
                </div>
            </section>
            <!-- 찾아오시는 길(e) -->

        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->

        <!-------------------------- 인클루드(s) -------------------------->
        <inc_user_foot />
        <!-------------------------- 인클루드(e) -------------------------->

    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                _this.exec("INIT");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "gubunProc": gubun };

                switch (gubun) {

                    case "INIT":
                        {
                            //url = "/vue_api/user/login/login";
                        }
                        break;

                    case "ALERT":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                console.log(data);

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "INIT":
                        {

                        }
                        break;

                    case "ALERT":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }
    }

</script>