<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <inc_zadmin_head />
            <inc_zadmin_left />

            <div id="main" class="pu_ContentsWrap sterr">
                <div class="pu_ContentsDiv">

                    <inc_zadmin_title />

                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">
                        <!-- 테이블영역 (s)  -->
                        <div class="pu_listTable01">
                            <div class="pu_TableY01">

                                <form id="regiForm" onsubmit="return false">

                                    <!--전달값-->
                                    <input type="hidden" name="no" v-model="view_1.NO" />
                                    <!--//전달값-->

                                    <table>
                                        <colgroup>
                                            <col width="15%">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th>아이디*</th>
                                                <td><input type="text" name="id" v-model:value="view_1.ID" style="width:80%"></td>
                                            </tr>
                                            <tr>
                                                <th>비밀번호*</th>
                                                <td><input type="password" name="pw" style="width:80%"></td>
                                            </tr>
                                            <tr>
                                                <th>권한*</th>
                                                <td>
                                                    <div>
                                                        <label>
                                                            <input type="radio" name="grade" value="D" v-model="view_1.GRADE"> 활성계정
                                                        </label>
                                                        <label>
                                                            <input type="radio" name="grade" value="X" v-model="view_1.GRADE"> 휴면계정
                                                        </label>
                                                        <label>
                                                            <input type="radio" name="grade" value="Y" v-model="view_1.GRADE"> 탈퇴계정
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>이름*</th>
                                                <td><input type="text" name="name" v-model:value="view_1.NAME" style="width:80%"></td>
                                            </tr>
                                            <tr>
                                                <th>부서*</th>
                                                <td><input type="text" name="department" v-model:value="view_1.DEPARTMENT" style="width:80%"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>

                            </div>
                        </div>
                        <!-- 테이블영역 (e)  -->

                        <!-- 버튼 스타일영역 (s)  -->
                        <div class="pu_btnDiv01 pu_over_hidden">
                            <article class="pu_f_right">
                                <a class="pu_btn_dark" href="javascript:;" v-on:click="$PageMove('./list')"><i class="xi-bars"></i> 목록</a>
                                <a class="dev_btn_submit pu_btn_blue" href="javascript:;"><i class="xi-check"></i> 저장</a>
                            </article>
                        </div>
                        <!-- 버튼 스타일영역 (s)  -->
                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: { }

        , data() {

            return {
                gubun_page: "REGI"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                getBase: "getBase"
            })

            // 진입점
            , init() {
                
                this.getSearchData(1);

                // dimm 숨기기
                this.$HideDimmLogo();
            }

            , getSearchData: function (curPage) {

                // qs
                var qs = this.$GetQueryMap();

                var data = {};
                data["gubun_page"] = this.gubun_page;
                data["no"] = qs.no;

                // API 조회
                this.$SendPost("/vue_api/zadmin/member_user/view", data, this.searchDataReturn);

            }

            , searchDataReturn: function (data, err) {

                if (data) {
                
                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.view_1 = data.VIEW_1;

                        // 등급
                        if (!this.view_1.GRADE) {
                            this.view_1.GRADE = "D";
                        }

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }

            // 저장
            , saveData() {

                // 아이디
                var id = $("[name='id']");
                if (!id.val().trim()) {
                    alert("아이디를 입력해주세요");
                    id.focus();
                    return false;
                }

                // 비밀번호
                var no = $("[name='no']").val();
                var pw = $("[name='pw']");
                if (!no && !pw.val().trim()) {
                    alert("비밀번호를 입력해주세요");
                    pw.focus();
                    return false;
                }

                // 권한
                var show_yn = $("[name='grade']:checked");
                if (show_yn.length < 1) {
                    alert("권한을 선택해주세요");
                    $("[name='grade']")[0].focus();
                    return false;
                }

                // 이름
                var name = $("[name='name']");
                if (!name.val().trim()) {
                    alert("이름을 입력해주세요");
                    name.focus();
                    return false;
                }

                // 부서
                var department = $("[name='department']");
                if (!department.val().trim()) {
                    alert("부서를 입력해주세요");
                    department.focus();
                    return false;
                }

                if (confirm(INFO_CONF_SAVE)) {

                    // dimm 보이기
                    this.$ShowDimmLogo();

                    var data = this.$FormToMap($('#regiForm'));
                    data["gubun_page"] = this.gubun_page;

                    // API 조회
                    this.$SendPost("/vue_api/zadmin/member_user/save", data, this.saveEventReturn);
                }
            }

            // 저장 데이터
            , saveEventReturn(data, err) {

                // dimm 숨기기
                this.$HideDimmLogo();

            }

        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>