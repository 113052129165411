<template>

    <div class="pu_wd_def_min pu_wrapper_main">

        <!-------------------------- 상단메뉴부분 (s) -------------------------->
        <div class="pu_wrapper_top">

            <!-- pc 메뉴 (s) -->
            <div class="dev_wrapper_top_web pu_wrapper_top_web">

                <!--  최상단 회원/검색 (s)-->
                <div class="dev_gnbTop pu_gnbTop">
                    <div class="pu_wd_def_max">
                        <ul class="pu_members">
                            <li class="pu_login"><a href="javascript:;">로그인</a></li>
                            <li class="pu_join"><a href="javascript:;">회원가입</a></li>
                        </ul>
                        <div class="pu_topSearch">
                            <label class="pu_blind" for="serch">검색</label>
                            <input type="text" placeholder="검색어를 입력해주세요.">
                            <input type="button" class="pu_btnSerch01" title="검색">
                        </div>
                    </div>
                </div>
                <!--  최상단 회원/검색 (e)- -->
                <div class="pu_wd_def_max pu_wrapper_menu">
                    <h1><a href="javascript:;"><img src="/common/img/gnb_ci.png" alt="정p&c연구소"></a></h1>

                    <div class="pu_gnb">
                        <ul class="pu_gnb_1depth">
                            <li class="pu_gnb_li1">
                                <a href="javascript:;">㈜정P&C연구소</a>
                                <ul class="pu_gnb_2depth">
                                    <li><a href="javascript:;">회사소개</a></li>
                                    <li><a href="javascript:;">주요사업 및 상품</a></li>
                                    <li><a href="javascript:;">연구수행 및 과제</a></li>
                                    <li><a href="javascript:;">주소 및 연락처</a></li>
                                    <li><a href="javascript:;">공지사항</a></li>
                                </ul>
                            </li>
                            <li class="pu_gnb_li2">
                                <a href="javascript:;">연구·사업</a>
                                <ul class="pu_gnb_2depth">
                                    <li>
                                        <a href="javascript:;">양돈컨설팅</a>
                                        <ul class="pu_gnb_3depth">
                                            <li><a href="javascript:;">컨설팅 분야</a></li>
                                            <li><a href="javascript:;">컨설팅 실적</a></li>
                                            <li><a href="javascript:;">농업경영 컨설팅</a></li>
                                            <li><a href="javascript:;">HACCP 컨설팅</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="javascript:;">종돈사업</a>
                                        <ul class="pu_gnb_3depth">
                                            <li><a href="javascript:;">종돈 수입사업 소개</a></li>
                                            <li><a href="javascript:;">해외 종돈장 소개</a></li>
                                            <li><a href="javascript:;">육종기술정보</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li class="pu_gnb_li3">
                                <a href="javascript:;">분석센터</a>
                                <ul class="pu_gnb_2depth">
                                    <li>
                                        <a href="javascript:;">분석센터</a>
                                        <ul class="pu_gnb_3depth">
                                            <li><a href="javascript:;">GMO 검사</a></li>
                                            <li><a href="javascript:;">한우 확인검사</a></li>
                                            <li><a href="javascript:;">쌀/현미 품종 검사</a></li>
                                            <li><a href="javascript:;">백수오(식품) 검사</a></li>
                                            <li><a href="javascript:;">식품원료 진위판별검사</a></li>
                                            <li><a href="javascript:;">살모넬라 검사</a></li>
                                            <li><a href="javascript:;">항생제 검사</a></li>
                                            <li><a href="javascript:;">동물 관련 유전자 검사</a></li>
                                            <li><a href="javascript:;">A2 Milk</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="javascript:;">검사신청</a></li>
                                </ul>
                            </li>
                            <li class="pu_gnb_li4">
                                <a href="javascript:;">양돈정보</a>
                                <ul class="pu_gnb_2depth">
                                    <li>
                                        <a href="javascript:;">양돈시세</a>
                                        <ul class="pu_gnb_3depth">
                                            <li><a href="javascript:;">비돈육</a></li>
                                            <li><a href="javascript:;">모돈</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="javascript:;">시장정보</a>
                                        <ul class="pu_gnb_3depth">
                                            <li><a href="javascript:;">국내뉴스</a></li>
                                            <li><a href="javascript:;">국내시장정보</a></li>
                                            <li><a href="javascript:;">해외뉴스</a></li>
                                            <li><a href="javascript:;">해외시장정보</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="javascript:;">질병정보</a></li>
                                    <li><a href="javascript:;">세계 사료곡물 동향</a></li>
                                    <li><a href="javascript:;">신기술/신정보</a></li>
                                    <li>
                                        <a href="javascript:;">실시간 서비스</a>
                                        <ul class="pu_gnb_3depth">
                                            <li><a href="javascript:;">정P&C 매거진</a></li>
                                            <li><a href="javascript:;">알림서비스신청</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <!-- <li class="pu_gnb_li5"><a href="javascript:;">JPNC몰</a></li> -->
                        </ul>
                    </div>

                    <button class="pu_snb_btn"><i class="xi-bars xi-2x"></i></button>
                </div>
            </div>
            <!-- pc 메뉴 (e)-->
            <!-- 모바일 메뉴 (s)-->
            <div class="pu_snb_wrap">
                <div class="pu_snb_innerWrap">
                    <div class="pu_snb">
                        <ul class="pu_gnb_1depth">
                            <li class="pu_gnb_li1">
                                <p>㈜정P&C연구소</p>
                                <ul class="pu_gnb_2depth">
                                    <li><a href="javascript:;">회사소개</a></li>
                                    <li><a href="javascript:;">주요사업 및 상품</a></li>
                                    <li><a href="javascript:;">연구수행 및 과제</a></li>
                                    <li><a href="javascript:;">주소 및 연락처</a></li>
                                    <li><a href="javascript:;">공지사항</a></li>
                                </ul>
                            </li>
                            <li class="pu_gnb_li2">
                                <p>연구·사업</p>
                                <ul class="pu_gnb_2depth">
                                    <li>
                                        <a href="javascript:;">양돈컨설팅</a>
                                        <ul class="pu_gnb_3depth">
                                            <li><a href="javascript:;">컨설팅 분야</a></li>
                                            <li><a href="javascript:;">컨설팅 실적</a></li>
                                            <li><a href="javascript:;">농업경영 컨설팅</a></li>
                                            <li><a href="javascript:;">HACCP 컨설팅</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="javascript:;">종돈사업</a>
                                        <ul class="pu_gnb_3depth">
                                            <li><a href="javascript:;">종돈 수입사업 소개</a></li>
                                            <li><a href="javascript:;">해외 종돈장 소개</a></li>
                                            <li><a href="javascript:;">육종기술정보</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li class="pu_gnb_li3">
                                <p>분석센터</p>
                                <ul class="pu_gnb_2depth">
                                    <li>
                                        <a href="javascript:;">분석센터</a>
                                        <ul class="pu_gnb_3depth">
                                            <li><a href="javascript:;">GMO 검사</a></li>
                                            <li><a href="javascript:;">한우 확인검사</a></li>
                                            <li><a href="javascript:;">쌀/현미 품종 검사</a></li>
                                            <li><a href="javascript:;">백수오(식품) 검사</a></li>
                                            <li><a href="javascript:;">식품원료 진위판별검사</a></li>
                                            <li><a href="javascript:;">살모넬라 검사</a></li>
                                            <li><a href="javascript:;">항생제 검사</a></li>
                                            <li><a href="javascript:;">동물 관련 유전자 검사</a></li>
                                            <li><a href="javascript:;">A2 Milk</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="javascript:;">검사신청</a></li>
                                </ul>
                            </li>
                            <li class="pu_gnb_li4">
                                <p>양돈정보</p>
                                <ul class="pu_gnb_2depth">
                                    <li>
                                        <a href="javascript:;">양돈시세</a>
                                        <ul class="pu_gnb_3depth">
                                            <li><a href="javascript:;">비돈육</a></li>
                                            <li><a href="javascript:;">모돈</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="javascript:;">시장정보</a>
                                        <ul class="pu_gnb_3depth">
                                            <li><a href="javascript:;">국내뉴스</a></li>
                                            <li><a href="javascript:;">국내시장정보</a></li>
                                            <li><a href="javascript:;">해외뉴스</a></li>
                                            <li><a href="javascript:;">해외시장정보</a></li>
                                        </ul>
                                    </li>
                                    <li><a href="javascript:;">질병정보</a></li>
                                    <li><a href="javascript:;">세계 사료곡물 동향</a></li>
                                    <li><a href="javascript:;">신기술/신정보</a></li>
                                    <li>
                                        <a href="javascript:;">실시간 서비스</a>
                                        <ul class="pu_gnb_3depth">
                                            <li><a href="javascript:;">정P&C 매거진</a></li>
                                            <li><a href="javascript:;">알림서비스신청</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li class="pu_gnb_li5">
                                <p>JPNC몰 (준비중)</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <button type="button" class="pu_snb_close_btn">닫기</button>
            </div>
            <div class="pu_mask"></div>
            <!-- 모바일 메뉴 (e)-->
        </div>
        <!-------------------------- 상단메뉴부분 (e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_home">

            <!-- 메인비쥬얼 상단영역 s -->
            <section class="pu_mainTopVisual">

                <!-- 메인 슬라이딩  s -->
                <div class="pu_slide_box">
                    <ul class="pu_slide_pic">
                        <li style="background:url('/common/img/main_visual01.png') no-repeat;background-size:cover;">
                            <div class="pu_wd_def_max">
                                <p class="pu_txt01">Real time Information Pork Industry</p>
                                <p class="pu_title01"><strong class="pu_txtWhite">양돈산업전문</strong> 종합컨설팅</p>
                                <p class="pu_linkbtn"> <a href="javascript:;">자세히보기</a></p>
                            </div>
                        </li>
                        <li style="background:url('/common/img/main_visual02.png') no-repeat;background-size:cover;">
                            <div class="pu_wd_def_max">
                                <p class="pu_txt01">Welcome to Jung P&C Laboratory</p>
                                <p class="pu_title01"><strong class="pu_txtWhite">건강,선진적</strong> 식문화사회 실천</p>
                                <p class="pu_linkbtn"> <a href="javascript:;">자세히보기</a></p>
                            </div>
                        </li>
                        <li style="background:url('/common/img/main_visual03.png') no-repeat;background-size:cover;">
                            <div class="pu_wd_def_max">
                                <p class="pu_txt01 pu_letter">최신 기술과 시설을 갖춘 공식 식품 유전자 분석 기관</p>
                                <p class="pu_title01">기능성유전자 <strong class="pu_txtWhite">GMO분석센터</strong></p>
                                <p class="pu_linkbtn"> <a href="javascript:;">자세히보기</a></p>
                            </div>
                        </li>
                        <li style="background:url('/common/img/main_visual04.png') no-repeat;background-size:cover;">
                            <div class="pu_wd_def_max">
                                <p class="pu_txt01">Real time Information Pork Industry</p>
                                <p class="pu_title01"><strong class="pu_txtWhite">종돈산업전문</strong> 종합컨설팅</p>
                                <p class="pu_linkbtn"> <a href="javascript:;">자세히보기</a></p>
                            </div>
                        </li>
                    </ul>
                    <ul class="pu_slide_tab"></ul>
                    <a class="pu_slide_nav pu_slide_prev" href="javascript:">&lt;</a>
                    <a class="pu_slide_nav pu_slide_next" href="javascript:">&gt;</a>
                </div>
                <!-- 메인슬라이딩 e -->
                <!--  gmo분석센터 / 통합건색영역 (s)-->
                <div class="pu_wd_def_max pu_jpncW pu_gmoSection">

                    <!-- gmo분석센터s  -->
                    <div class="pu_mainGmo">
                        <article>
                            <div class="pu_title">
                                <h2><span>GMO</span> 분석센터</h2>
                                <p>식품위생전문 검사기관</p>
                            </div>
                            <ul>
                                <li><a href="javascript:;">검사절차</a> </li>
                                <li><a href="javascript:;">분석의뢰서</a> </li>
                                <li><a href="javascript:;">분석조회</a> </li>
                            </ul>
                        </article>
                    </div>
                    <!-- gmo분석센터e  -->
                    <!-- 통합건색영역s  -->
                    <div class="pu_mainSearch">

                        <label for="">통합검색</label>
                        <input type="text" placeholder="검색어를 입력해주세요.">
                        <input type="button" class="pu_btnSerch" title="검색">

                        <article>
                            <a href="javascript:;">#양돈산업</a>
                            <a href="javascript:;">#항생제</a>
                            <a href="javascript:;">#유전자검사</a>
                            <a href="javascript:;">#항체생성</a>
                            <a href="javascript:;">#질병</a>
                        </article>
                    </div>
                    <!-- 통합건색영역e  -->
                </div>
                <!--  gmo분석센터 / 통합건색영역 (e)-->

            </section>
            <!-- 메인비쥬얼 상단영역 e -->
            <!-- 소식및이벤트 영역 (s) -->
            <section class="pu_wd_def_max pu_jpncW pu_mainIssue">
                <!-- 뉴스영역s -->
                <div class="pu_mainNews">
                    <div class="pu_tabTitle">
                        <h2>정P&C<span>소식</span></h2>
                        <ul>
                            <li class="pu_on"><span>최신</span></li>
                            <li><span>공지사항</span></li>
                            <li><span>양돈정보</span></li>
                            <li><span>양돈뉴스</span></li>
                            <li><span>국내뉴스</span></li>
                        </ul>
                    </div>
                    <div class="pu_MainNewsList">
                        <ul>
                            <li>
                                <dl>
                                    <dt>
                                        <p>양돈뉴스 <span class="pu_new">N</span></p>
                                    </dt>
                                    <dd class="pu_subject">
                                        <a href="javascript:;">
                                            창립 50주년 앞둔 선진, ‘지속 가능한 축산업’ 위한 미래비전
                                            제시하다
                                        </a>
                                    </dd>
                                    <dd class="pu_date">2022.07.20</dd>
                                </dl>
                            </li>
                            <li>
                                <dl>
                                    <dt>
                                        <p>양돈뉴스</p>
                                    </dt>
                                    <dd class="pu_subject"><a href="javascript:;">중국 2위 양돈업체, 사료공급 중단</a></dd>
                                    <dd class="pu_date">2022.07.20</dd>
                                </dl>
                            </li>
                        </ul> <!-- // 1단 -->
                        <ul>
                            <li>
                                <dl>
                                    <dt>
                                        <p>양돈정보 <span class="pu_pay">유</span></p>
                                    </dt>
                                    <dd class="pu_subject">
                                        <a href="javascript:;">
                                            본격적인 휴가철로 인한 돈가의 강보합세 유지세 양돈시장의 변화를
                                            꾀하다.
                                        </a>
                                    </dd>
                                    <dd class="pu_date">2022.07.20</dd>
                                </dl>
                            </li>
                            <li>
                                <dl>
                                    <dt>
                                        <p>공지사항</p>
                                    </dt>
                                    <dd class="pu_subject">
                                        <a href="javascript:;">
                                            DNS 서버 변경으로 인해 잠시 홈페이지가 열리지 않을 수
                                            있습니다.
                                        </a>
                                    </dd>
                                    <dd class="pu_date">2022.07.20</dd>
                                </dl>
                            </li>
                        </ul> <!-- // 2단 -->
                        <ul>
                            <li>
                                <dl>
                                    <dt>
                                        <p>양돈정보</p>
                                    </dt>
                                    <dd class="pu_subject">
                                        <a href="javascript:;">
                                            우크라 곡물수출 협상 타결‥흑해 항로 재개, 22일 서명식
                                            준비
                                        </a>
                                    </dd>
                                    <dd class="pu_date">2022.07.20</dd>
                                </dl>
                            </li>
                            <li>
                                <dl>
                                    <dt>
                                        <p>양돈정보 <span class="pu_pay">유</span></p>
                                    </dt>
                                    <dd class="pu_subject">
                                        <a href="javascript:;">
                                            우크라 곡물수출 협상 타결‥흑해 항로 재개, 22일 서명식 준비DNS 서버 변경으로 인해
                                            잠시 홈페이지가 열리지 않을 수 있습니다.
                                        </a>
                                    </dd>
                                    <dd class="pu_date">2022.07.20</dd>
                                </dl>
                            </li>
                        </ul> <!-- // 3단 -->
                    </div>
                </div>
                <!-- 뉴스영역e -->
                <!-- 이벤트배너 영역s -->
                <div class="pu_mainEvent">
                    <div id="pu_MBanner">
                        <div class="title">
                            <h2>정P&C<span>이슈</span></h2>
                        </div>
                        <div class="pu_jpnc-slider">
                            <div>
                                <a class="pu_bannerLinks" href="javascript:;">
                                    <p class="title">제목 : 환절기 주의 이벤트</p>
                                    <img src="/common/img/main_banner01.png" alt="">
                                </a>
                            </div>
                            <div>
                                <a class="pu_bannerLinks" href="javascript:;">
                                    <p class="title">제목 : 서버점검 안내</p>
                                    <img src="/common/img/main_banner02.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="pu_mainIconLink">
                        <ul>
                            <li>
                                <a href="javascript:;">
                                    <span class="pu_icons">
                                        <i class="xi-book-o xi-2x"></i>
                                    </span><br><span class="pu_txt">정P&C 매거진</span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:;">
                                    <span class="pu_icons"><i class="xi-bell-o xi-2x"></i></span>
                                    <br><span class="pu_txt">알림 서비스 신청</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
                <!-- 이벤트배너 영역e -->

            </section>
            <!-- 소식및이벤트 영역 (s) -->
            <!-- 분석센터 영역 (s) -->
            <section class="pu_wd_def_max pu_jpncW pu_BusinessSection">
                <div class="pu_mainBusiness">
                    <div class="pu_PContent">
                        <div class="pu_BusinessTitle">
                            <h2><span>분석</span>CENTER</h2>
                            <p>정피앤씨연구소는 국내최고의 공인검사법으로 신뢰있는 검사를 합니다.</p>
                        </div>
                        <div class="pu_jpnc-slider">
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business01.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>한우 확인검사</dt>
                                        <dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business02.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>GMO 검사</dt>
                                        <dd>유전자를 다른 생물체의 유전자와 결합시켜 특정한 목적에 맞도록 유전자 일부를 변형</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business03.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>백수오(식품) 검사</dt>
                                        <dd>백수오 검사를 합니다.설명글 입력예정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business04.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>쌀/현미 품종 검사</dt>
                                        <dd>쌀·현미품종검정은 국립농산물품질관리원의 「SNP 유전자분석법」을 이용한 분석법입니다.</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business05.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>살모넬라 검사</dt>
                                        <dd>살모넬라 검사</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business06.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>A2 Milk</dt>
                                        <dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business07.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>항생제 검사</dt>
                                        <dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                        </div>
                    </div>

                    <!--------------------------  mobile버전 배너(s) -------------------------->
                    <div class="pu_MContent">
                        <div class="pu_wd_def_max pu_jpncW pu_BusinessTitle">
                            <h2><span>분석</span>CENTER</h2>
                            <p>국내최고의 공인검사법으로 신뢰있는 검사를 합니다.</p>
                        </div>
                        <div class="pu_jpnc-slider">
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business01.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>한우 확인검사</dt>
                                        <dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business02.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>GMO 검사</dt>
                                        <dd>유전자를 다른 생물체의 유전자와 결합시켜 특정한 목적에 맞도록 유전자 일부를 변형</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business03.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>백수오(식품) 검사</dt>
                                        <dd>백수오 검사를 합니다.설명글 입력예정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business04.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>쌀/현미 품종 검사</dt>
                                        <dd>쌀·현미품종검정은 국립농산물품질관리원의 「SNP 유전자분석법」을 이용한 분석법입니다.</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business05.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>살모넬라 검사</dt>
                                        <dd>살모넬라 검사</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business06.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>A2 Milk</dt>
                                        <dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                            <!--  -->
                            <div class="pu_business"
                                 style="background: url('/common/img/main_business07.png') no-repeat center;background-size: cover;">
                                <a href="javascript:;">
                                    <dl>
                                        <dt>항생제 검사</dt>
                                        <dd>농림수산식품부에서 기술이전 받은 공인검사법으로'쇠고기내 모색 유전자의 유무'로 한우형을 판정</dd>
                                    </dl>
                                </a>
                            </div>
                            <!--  -->
                        </div>
                    </div>
                    <!-------------------------- mobile버전 배너(e) -------------------------->
                </div>
            </section>
            <!-- 분석센터 영역 (e) -->
            <!-- 퀵링크 배너 (s) -->
            <section class="pu_wd_def_max pu_jpncW pu_mainQuick">
                <ul>
                    <li>
                        <a href="javascript:;">
                            <p class="pu_Quick01"></p>
                            <span>신기술 / 신정보</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <p class="pu_Quick02"></p>
                            <span>주요사업 및 상품</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <p class="pu_Quick03"></p>
                            <span>국내시장정보</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <p class="pu_Quick04"></p>
                            <span>해외시장정보</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <p class="pu_Quick05"></p>
                            <span>질병정보</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;">
                            <p class="pu_Quick06"></p>
                            <span>연구수행 및 과제</span>
                        </a>
                    </li>
                </ul>
            </section>
            <!-- 퀵링크 배너 (e) -->
            <!-- 찾아오시는 길(s) -->
            <section class="pu_mainMapSection">
                <div class="pu_wd_def_max">
                    <div class="pu_mainCompay">
                        <div class="pu_mainCompayInfo">
                            <div class="pu_title">
                                <h2>정P&C<span>연구소</span></h2>
                                <p>경기도 용인시 기흥구 흥덕중앙로 20 U-Tower 1504 호</p>
                            </div>
                            <ul>
                                <li><a href="javascript:;"><span>회사소개</span> <i class="xi-city"></i></a></li>
                                <li><a href="javascript:;"><span>오시는길</span> <i class="xi-maker"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="pu_mainMap"></div>
                </div>
            </section>
            <!-- 찾아오시는 길(e) -->

        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">
                <div class="pu_joinEnd">
                    <dl>
                        <dt>회원가입이 <strong>완료</strong>되었습니다.</dt>
                        <dd>
                            홍길동님의 회원가입을 축하합니다.<br>
                            알차고 빠른 서비스로 찾아뵙겠습니다.
                        </dd>
                    </dl>

                    <div class="pu_btnDiv01">
                        <a class="pu_btn_red" href="javascript:;"><i class="xi-lock-o"></i> 로그인</a>
                        <a class="pu_btn_defalut" href="javascript:;"><i class="xi-home-o"></i> 홈으로</a>
                    </div>
                </div>
            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-- 타이틀 (s) -->
            <div class="pu_subTitle01 loginTit">
                <article class="pu_wd_def_max">
                    <h2 class="pu_login">Member <span class="pu_txtColor4">회원가입 1</span></h2>
                    <p>정P&C연구소를 방문해주셔서 감사합니다.</p>
                </article>
            </div>
            <!-- 타이틀 (e) -->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">
                <div class="pu_joinDiv">
                    <div>
                        <table class="pu_table_join">
                            <colgroup>
                                <col style="width:20%;">
                                <col style="width:80%;">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>이름 <strong>*</strong></th>
                                    <td>홍길동 </td>
                                </tr>
                                <tr>
                                    <th>생년월일 <strong>*</strong></th>
                                    <td>19800101 </td>
                                </tr>
                                <tr>
                                    <th>아이디 <strong>*</strong></th>
                                    <td>
                                        <input type="text">
                                        <a class="pu_s_btn_dark" href="javascript:;">중복확인</a>
                                    </td>
                                </tr>
                                <tr>
                                    <th>비밀번호 <strong>*</strong></th>
                                    <td>
                                        <input type="password">
                                        <span class="pu_txt">영문, 숫자를 조합하여 8~20자리로 설정하셔야 합니다.</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>비밀번호확인 <strong>*</strong></th>
                                    <td>
                                        <input type="password">
                                    </td>
                                </tr>
                                <tr>
                                    <th>이메일 <strong>*</strong></th>
                                    <td>
                                        <input type="text">
                                    </td>
                                </tr>
                                <tr>
                                    <th>연락처 <strong>*</strong></th>
                                    <td>010-9898-2520</td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <div class="pu_btnDiv01">
                                <a class="pu_btn_red" href="join_ok.html"><i class="xi-border-color"></i> 가입하기</a>
                                <a class="pu_btn_defalut" href="javascript:;"><i class="xi-close"></i> 취소하기</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-- 타이틀 (s) -->
            <div class="pu_subTitle01 loginTit">
                <article class="pu_wd_def_max">
                    <h2 class="pu_login">Member <span class="pu_txtColor4">회원가입 2</span></h2>
                    <p>정P&C연구소를 방문해주셔서 감사합니다.</p>
                </article>
            </div>
            <!-- 타이틀 (e) -->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">
                <div style="margin: 0px auto; width: 460px;">
                    <table class="pu_table_mobile_regi">
                        <colgroup>
                            <col style="width:100%;">
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            이름 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span_pd_r">
                                                홍길동
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            생년월일 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span_pd_r">
                                                19800101
                                                <span class="pu_input_sign_none">none</span>
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            아이디 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span_pd_r">
                                                <input type="text" />
                                                <span class="pu_input_sign_ok">사용가능한 아이디</span>
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            비밀번호 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span_pd_r">
                                                <input type="password" />
                                                <span class="pu_input_sign_no"><i class="xi-close-square"></i>비밀번호를 입력하세요</span>
                                            </span>
                                            <span class="pu_input_span_alert">영문, 숫자를 조합하여 8~20자리로 설정하셔야 합니다.</span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            비밀번호확인 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span_pd_r">
                                                <input type="password" />
                                                <span class="pu_input_sign_ok"><i class="xi-check-square"></i>사용가능한 아이디</span>
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            이메일 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span_pd_r">
                                                <input type="text" />
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            연락처 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span_pd_r">
                                                010-9898-2520
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>

                        <div class="pu_btnDiv01">
                            <a class="pu_btn_red" href="join_ok.html"><i class="xi-border-color"></i> 가입하기</a>
                            <a class="pu_btn_defalut" href="javascript:;"><i class="xi-close"></i> 취소하기</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-- 타이틀 (s) -->
            <div class="pu_subTitle01 loginTit">
                <article class="pu_wd_def_max">
                    <h2 class="pu_login">Member <span class="pu_txtColor4">게시글 1</span></h2>
                    <p>정P&C연구소를 방문해주셔서 감사합니다.</p>
                </article>
            </div>
            <!-- 타이틀 (e) -->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">
                <div style="margin: 0px auto; width: 850px;">
                    <table class="pu_table_mobile_regi">
                        <colgroup>
                            <col style="width:100%;">
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-pen"></i> 이름 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span">
                                                홍길동
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-pen"></i> 생년월일 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span">
                                                19800101
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-pen"></i> 아이디 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span">
                                                <input type="text" placeholder="아이디를 입력해주세요" />
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-pen"></i> 비밀번호 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span">
                                                <input type="password" placeholder="비밀번호를 입력해주세요" />
                                            </span>
                                            <span class="pu_input_span_alert">영문, 숫자를 조합하여 8~20자리로 설정하셔야 합니다.</span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-pen"></i> 비밀번호확인 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span" style="width: 30%;">
                                                <select name="" class="" title="검색어 분류를 선택하세요.">
                                                    <option value="">제목+내용</option>
                                                    <option value="" select="select">제목</option>
                                                    <option value="">내용</option>
                                                    <option value="">작성자</option>
                                                </select>
                                            </span>

                                            <span class="pu_input_span" style="width: 60%;">
                                                <select name="" class="" title="검색어 분류를 선택하세요.">
                                                    <option value="">제목+내용</option>
                                                    <option value="" select="select">제목</option>
                                                    <option value="">내용</option>
                                                    <option value="">작성자</option>
                                                </select>
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-pen"></i> 이메일 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span">
                                                <input type="text" placeholder="이메일을 입력해주세요" />
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-pen"></i> 성별 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span">

                                                <input type="radio" id="radio01" name="radioset01" checked=""><label for="radio01">전체</label>
                                                <input type="radio" id="radio02" name="radioset01"><label for="radio02">미적용</label>
                                                <input type="radio" id="radio03" name="radioset01"><label for="radio03">적용</label>
                                                <input type="radio" id="radio04" name="radioset02" checked="" disabled="disabled"><label for="radio04">적용완료</label>


                                                <input type="checkbox" id="chk_1"><label for="chk_1">선택01</label>
                                                <input type="checkbox" id="chk_2"><label for="chk_2">선택01</label>
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-pen"></i> 연락처 <strong>*</strong>
                                        </p>
                                        <p>
                                            <span class="pu_input_span">
                                                <textarea placeholder="내용을 입력해주세요"></textarea>
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>

                        <div class="pu_btnDiv01">
                            <a class="pu_btn_red" href="join_ok.html"><i class="xi-border-color"></i> 가입하기</a>
                            <a class="pu_btn_defalut" href="javascript:;"><i class="xi-close"></i> 취소하기</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-- 타이틀 (s) -->
            <div class="pu_subTitle01 loginTit">
                <article class="pu_wd_def_max">
                    <h2 class="pu_login">Member <span class="pu_txtColor4">게시글 1 뷰</span></h2>
                    <p>정P&C연구소를 방문해주셔서 감사합니다.</p>
                </article>
            </div>
            <!-- 타이틀 (e) -->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">
                <div style="margin: 0px auto; width: 850px;">
                    <table class="pu_table_mobile_view">
                        <colgroup>
                            <col style="width:100%;">
                        </colgroup>
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-info-o"></i> 이름
                                        </p>
                                        <p>
                                            <span class="pu_input_span">
                                                홍길동
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-info-o"></i> 생년월일
                                        </p>
                                        <p>
                                            <span class="pu_input_span">
                                                19800101
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-info-o"></i> 아이디
                                        </p>
                                        <p>
                                            <span class="pu_input_span">
                                                billionpjh
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-info-o"></i> 비밀번호
                                        </p>
                                        <p>
                                            <span class="pu_input_span">
                                                ******
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-info-o"></i> 비밀번호확인
                                        </p>
                                        <p>
                                            <span class="pu_input_span" style="width: 30%;">
                                                콤보박스 1
                                            </span>

                                            <span class="pu_input_span" style="width: 60%;">
                                                콤보박스 2
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-info-o"></i> 이메일
                                        </p>
                                        <p>
                                            <span class="pu_input_span">
                                                billionpjh@naver.com
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <p class="pu_input_label">
                                            <i class="xi-info-o"></i> 연락처
                                        </p>
                                        <p>
                                            <span class="pu_input_span">
                                                010-4811-2727
                                            </span>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>

                        <div class="pu_btnDiv01">
                            <a class="pu_btn_red" href="join_ok.html"><i class="xi-border-color"></i> 가입하기</a>
                            <a class="pu_btn_defalut" href="javascript:;"><i class="xi-close"></i> 취소하기</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-- 서브 배너 (s) -->
            <div class="pu_subVisual pu_sub01">
                <dl>
                    <dt>연구·사업</dt>
                    <dd>정부 및 대 기업체의 양돈관련 연구 프로젝트를 수행 중에 있습니다.</dd>
                </dl>
            </div>
            <!-- 서브 배너 (e) -->

            <div class="pu_wrapper_body pu_wd_def_max">

                <div class="pu_wrapper_body_left_menu">

                    <!-- 서브 메뉴 (s) -->
                    <div>

                        <div class="pu_locationWrap">
                            <div class="pu_wd_def_max pu_locationMenu">
                                <a class="pu_home" href="javascript:;"><i class="xi-home-o"></i></a>
                                <ul class="pu_lnbList">
                                    <li>
                                        <div class="pu_select_wrap">
                                            <a class="pu_txtwrap"><span>연구·사업</span> <i class="xi-angle-down-min"></i></a>
                                            <div class="pu_select_list">
                                                <ul class="pu_select_menu">
                                                    <li><a href="javascript:;">㈜정P&C연구소</a></li>
                                                    <li><a href="javascript:;">연구·사업</a></li>
                                                    <li><a href="javascript:;">분석센터</a></li>
                                                    <li><a href="javascript:;">양돈정보</a></li>
                                                    <li><a href="javascript:;">JP&C몰</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="pu_select_wrap">
                                            <a class="pu_txtwrap"><span>양돈컨설팅</span><i class="xi-angle-down-min"></i></a>
                                            <div class="pu_select_list">
                                                <ul class="pu_select_menu">
                                                    <li><a href="javascript:;">양돈컨설팅</a></li>
                                                    <li><a href="javascript:;">종돈사업</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="pu_select_wrap">
                                            <a class="pu_txtwrap"><span>컨설팅 분야</span><i class="xi-angle-down-min"></i></a>
                                            <div class="pu_select_list">
                                                <ul class="pu_select_menu">
                                                    <li><a href="javascript:;">컨설팅 분야</a></li>
                                                    <li><a href="javascript:;">컨설팅 실적</a></li>
                                                    <li><a href="javascript:;">농업경영 컨설팅</a></li>
                                                    <li><a href="javascript:;">HACCP 컨설팅</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="pu_locationWrapLeft">
                            <ul>
                                <li>
                                    <a>
                                        <i class="xi-library-books-o"></i>
                                        <span>뉴스속보</span>
                                    </a>
                                    <ul>
                                        <li>
                                            <a class="pu_on" href="javascript:;">
                                                <i class="xi-angle-right-min"></i>
                                                <span>국내뉴스</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">
                                                <i class="xi-angle-right-min"></i>
                                                <span>해외뉴스</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <a>
                                        <i class="xi-library-books-o"></i>
                                        <span>양돈 컨설팅</span>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="javascript:;">
                                                <i class="xi-angle-right-min"></i>
                                                <span>컨설팅 분야</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">
                                                <i class="xi-angle-right-min"></i>
                                                <span>컨설팅 실적</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">
                                                <i class="xi-angle-right-min"></i>
                                                <span>농업경영 컨설팅</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">
                                                <i class="xi-angle-right-min"></i>
                                                <span>HACCP 컨설팅</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <a>
                                        <i class="xi-library-books-o"></i>
                                        <span>종돈사업</span>
                                    </a>
                                    <ul>
                                        <li>
                                            <a href="javascript:;">
                                                <i class="xi-angle-right-min"></i>
                                                <span>종돈 수출입 소개</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">
                                                <i class="xi-angle-right-min"></i>
                                                <span>해외 종돈장 소개</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:;">
                                                <i class="xi-angle-right-min"></i>
                                                <span>육종기술정보</span>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <!-- 서브 메뉴 (e) -->

                </div>

                <div class="pu_wrapper_body_right_cont">
                    <!-- 타이틀 (s) -->
                    <div class="pu_subTitle01">
                        <article class="pu_wd_def_max">
                            <h2>컨설팅 분야</h2>
                        </article>
                    </div>
                    <!-- 타이틀 (e) -->
                    <!-- 서브 실제 컨텐츠 영역 (s) -->
                    <div class="pu_wd_def_max pu_layoutContent">

                        <div class="pu_loginDiv">
                            <ul>
                                <li class="pu_id">
                                    <label class="pu_blind">아이디</label> <input type="text" class="pu_loginput"
                                                                               name="" placeholder="아이디 입력">
                                </li>
                                <li class="pu_pw">
                                    <label class="pu_blind">비밀번호</label> <input type="password"
                                                                                class="pu_loginput" name="" placeholder="비밀번호 입력">
                                </li>
                                <li>
                                    <div class="pu_idched">
                                        <input type="checkbox" id="chk_td1" checked><label for="chk_td1">아이디저장</label>
                                        <a class="idsearch" href="javascript:;">아이디/비밀번호 찾기</a>
                                    </div>
                                </li>
                            </ul>
                            <div>
                                <input type="button" class="pu_loginbtn" value="로그인" onclick="">
                                <a class="pu_newjoin" href="javascript:;"><i class="xi-border-color"></i> 신규 회원가입</a>
                            </div>


                        </div>

                    </div>
                    <!-- 서브 실제 컨텐츠 영역 (e) -->
                </div>    
            </div>
        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-- 타이틀 (s) -->
            <div class="pu_subTitle01 loginTit">
                <article class="pu_wd_def_max">
                    <h2 class="pu_login">Member <span class="pu_txtColor4">Login</span></h2>
                    <p>정P&C연구소를 방문해주셔서 감사합니다.</p>
                </article>
            </div>
            <!-- 타이틀 (e) -->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">

                <div class="pu_loginDiv">
                    <ul>
                        <li class="pu_id">
                            <label class="pu_blind">아이디</label> <input type="text" class="pu_loginput"
                                                                       name="" placeholder="아이디 입력">
                        </li>
                        <li class="pu_pw">
                            <label class="pu_blind">비밀번호</label> <input type="password"
                                                                        class="pu_loginput" name="" placeholder="비밀번호 입력">
                        </li>
                        <li>
                            <div class="pu_idched">
                                <input type="checkbox" id="chk_td1" checked><label for="chk_td1">아이디저장</label>
                                <a class="idsearch" href="javascript:;">아이디/비밀번호 찾기</a>
                            </div>
                        </li>
                    </ul>
                    <div>
                        <input type="button" class="pu_loginbtn" value="로그인" onclick="">
                        <a class="pu_newjoin" href="javascript:;"><i class="xi-border-color"></i> 신규 회원가입</a>
                    </div>


                </div>

            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-- 서브 배너 (s) -->
            <div class="pu_subVisual pu_sub04">
                <dl>
                    <dt>양돈정보</dt>
                    <dd>정부 및 대 기업체의 양돈관련 연구 프로젝트를 수행 중에 있습니다.</dd>
                </dl>
            </div>
            <!-- 서브 배너 (e) -->
            <!-- 서브 메뉴 (s) -->
            <div class="pu_locationWrap">
                <div class="pu_wd_def_max pu_locationMenu">
                    <a class="pu_home" href="javascript:;"><i class="xi-home-o"></i></a>
                    <ul class="pu_lnbList">
                        <li>
                            <div class="pu_select_wrap">
                                <a class="pu_txtwrap"><span>양돈정보</span> <i class="xi-angle-down-min"></i></a>
                                <div class="pu_select_list">
                                    <ul class="pu_select_menu">
                                        <li><a href="javascript:;">㈜정P&C연구소</a></li>
                                        <li><a href="javascript:;">연구·사업</a></li>
                                        <li><a href="javascript:;">분석센터</a></li>
                                        <li><a href="javascript:;">양돈정보</a></li>
                                        <li><a href="javascript:;">JP&C몰</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="pu_select_wrap">
                                <a class="pu_txtwrap"><span>양돈시세</span><i class="xi-angle-down-min"></i></a>
                                <div class="pu_select_list">
                                    <ul class="pu_select_menu">
                                        <li><a href="javascript:;">양돈시세</a></li>
                                        <li><a href="javascript:;">시장정보</a></li>
                                        <li><a href="javascript:;">질병정보</a></li>
                                        <li><a href="javascript:;">세계 사료곡물 동향</a></li>
                                        <li><a href="javascript:;">신기술/신정보</a></li>
                                        <li><a href="javascript:;">실시간 서비스</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="pu_select_wrap">
                                <a class="pu_txtwrap"><span>국내뉴스</span><i class="xi-angle-down-min"></i></a>
                                <div class="pu_select_list">
                                    <ul class="pu_select_menu">
                                        <li><a href="javascript:;">국내뉴스</a></li>
                                        <li><a href="javascript:;">국내시장정보</a></li>
                                        <li><a href="javascript:;">해외뉴스</a></li>
                                        <li><a href="javascript:;">해외시장정보</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 서브 메뉴 (e) -->
            <!-- 타이틀 (s) -->
            <div class="pu_subTitle01">
                <article class="pu_wd_def_max">
                    <h2>국내뉴스</h2>
                    <p>국내 돈가 전망 및 뉴스에 대한 정보를 제공해드립니다.</p>
                </article>
            </div>
            <!-- 타이틀 (e) -->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">

                <!-- 검색영역(s) -->
                <div class="pu_listSearch">
                    <div class="pu_search01">
                        <legend>검색</legend>
                        <select name="" class="" title="검색어 분류를 선택하세요.">
                            <option value="">제목+내용</option>
                            <option value="" select="select">제목</option>
                            <option value="">내용</option>
                            <option value="">작성자</option>
                        </select>
                        <div class=" pu_input_wrap">
                            <label class="pu_blind" for="search">검색어</label>
                            <input type="text" name="search" id="search" value="" placeholder="검색어를 입력하세요"
                                   title="검색어를 입력하세요" />
                            <a class="pu_btnSerch01" href="javascript:;">검색</a>
                        </div>
                    </div>
                    <div class="pu_totalTxt">총 <span>26</span>page, <span>266</span>개 등록</div>
                </div>
                <!-- 검색영역(e) -->
                <!-- 리스트(s) -->
                <div>
                    <table class="pu_table_list">
                        <colgroup>
                            <col style="width:7%;">
                            <col style="width:61%;">
                            <col style="width:15%;">
                            <col style="width:10%;">
                            <col style="width:7%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">번호</th>
                                <th scope="col" class="list">제목</th>
                                <th scope="col">작성자</th>
                                <th scope="col">작성일</th>
                                <th scope="col">조회</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>20</td>
                                <td class="list">
                                    <a href="news_view.html">
                                        <span class="pu_notice">알림</span>종개협 등록
                                        칡소·제주흑우, 한우로 인정
                                    </a>
                                </td>
                                <td>관리자</td>
                                <td>2022-08-14</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list">
                                    <a href="news_view.html">
                                        <span class="pu_re">RE</span> 참치·연어 통조림
                                        GMO표시 전무…허술한 표시제도
                                    </a>
                                </td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list"><a href="news_view.html">지방 줄인 '슈퍼돼지'가 탄생하다 </a></td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list">유전자로 참깨 원산지 판별하는 기술 개발 </td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list"><a href="news_view.html">참치·연어 통조림 GMO표시 전무…허술한 표시제도 </a></td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list"><a href="news_view.html">지방 줄인 '슈퍼돼지'가 탄생하다 </a></td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list">유전자로 참깨 원산지 판별하는 기술 개발 </td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 리스트(e) -->
                <!-- 페이징(s) -->
                <div class="pu_pagination_2">
                    <a href="javascript:;"><i class="xi-angle-left"></i></a>
                    <a href="javascript:;">1</a>
                    <a href="javascript:;" class="active">2</a>
                    <a href="javascript:;">3</a>
                    <a href="javascript:;">4</a>
                    <a href="javascript:;">5</a>
                    <a href="javascript:;">6</a>
                    <a href="javascript:;">7</a>
                    <a href="javascript:;">8</a>
                    <a href="javascript:;">9</a>
                    <a href="javascript:;">10</a>
                    <a href="javascript:;"><i class="xi-angle-right"></i></a>
                </div>
                <!-- 페이징(e) -->
            </div>
            <!-- 서브 실제 컨텐츠 영역(e) -->
        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-- 서브 배너 (s) -->
            <div class="pu_subVisual pu_sub04">
                <dl>
                    <dt>양돈정보</dt>
                    <dd>정부 및 대 기업체의 양돈관련 연구 프로젝트를 수행 중에 있습니다.</dd>
                </dl>
            </div>
            <!-- 서브 배너 (e) -->
            <!-- 서브 메뉴 (s) -->
            <div class="pu_locationWrap">
                <div class="pu_wd_def_max pu_locationMenu">
                    <a class="pu_home" href="javascript:;"><i class="xi-home-o"></i></a>
                    <ul class="pu_lnbList">
                        <li>
                            <div class="pu_select_wrap">
                                <a class="pu_txtwrap"><span>양돈정보</span> <i class="xi-angle-down-min"></i></a>
                                <div class="pu_select_list">
                                    <ul class="pu_select_menu">
                                        <li><a href="javascript:;">㈜정P&C연구소</a></li>
                                        <li><a href="javascript:;">연구·사업</a></li>
                                        <li><a href="javascript:;">분석센터</a></li>
                                        <li><a href="javascript:;">양돈정보</a></li>
                                        <li><a href="javascript:;">JP&C몰</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="pu_select_wrap">
                                <a class="pu_txtwrap"><span>양돈시세</span><i class="xi-angle-down-min"></i></a>
                                <div class="pu_select_list">
                                    <ul class="pu_select_menu">
                                        <li><a href="javascript:;">양돈시세</a></li>
                                        <li><a href="javascript:;">시장정보</a></li>
                                        <li><a href="javascript:;">질병정보</a></li>
                                        <li><a href="javascript:;">세계 사료곡물 동향</a></li>
                                        <li><a href="javascript:;">신기술/신정보</a></li>
                                        <li><a href="javascript:;">실시간 서비스</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="pu_select_wrap">
                                <a class="pu_txtwrap"><span>국내뉴스</span><i class="xi-angle-down-min"></i></a>
                                <div class="pu_select_list">
                                    <ul class="pu_select_menu">
                                        <li><a href="javascript:;">국내뉴스</a></li>
                                        <li><a href="javascript:;">국내시장정보</a></li>
                                        <li><a href="javascript:;">해외뉴스</a></li>
                                        <li><a href="javascript:;">해외시장정보</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 서브 메뉴 (e) -->
            <!-- 타이틀 (s) -->
            <div class="pu_subTitle01">
                <article class="pu_wd_def_max">
                    <h2>국내뉴스</h2>
                    <p>국내 돈가 전망 및 뉴스에 대한 정보를 제공해드립니다.</p>
                </article>
            </div>
            <!-- 타이틀 (e) -->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">

                <!-- 검색영역(s) /*pu_fixedTopSearch pu_fixedTopSearchMin*/ -->
                <div class="pu_listSearchMulti">
                    <div class="pu_wd_def_max">
                        <dl class="pu_searchItemHide">
                            <dt><label>교육과정:</label></dt>
                            <dd>
                                <select name="" class="">
                                    <option value="" selected>- 과목 선택</option>
                                </select>

                                <select name="" class="">
                                    <option value="" selected>- 개정년도</option>
                                </select>

                                <select name="" class="">
                                    <option value="" selected>- 교육과정</option>
                                </select>

                                <select name="" class="">
                                    <option value="" selected>- 대단원</option>
                                </select>

                                <select name="" class="">
                                    <option value="" selected>- 중단원</option>
                                </select>
                            </dd>
                        </dl>

                        <dl>
                            <dt><label>학교</label></dt>
                            <dd>
                                <select name="" class="">
                                    <option value="" selected>- 시도를 선택하세요.</option>
                                </select>

                                <select name="" class="">
                                    <option value="" selected>- 구군을 선택하세요.</option>
                                </select>

                                <select name="" class="">
                                    <option value="" selected>- 초중고를 선택하세요.</option>
                                </select>

                                <select name="" class="">
                                    <option value="" selected>- 학교명을 선택하세요.</option>
                                </select>
                            </dd>
                        </dl>

                        <dl>
                            <dt><label>타입 :</label></dt>
                            <dd>
                                <select name="" class="">
                                    <option value="" selected>- 타입을 선택하세요.</option>
                                </select>
                            </dd>

                            <dt class="pu_ml20"><label>난이도</label></dt>
                            <dd>
                                <select name="" class="">
                                    <option value="" selected>- 난이도를 선택하세요.</option>
                                </select>
                            </dd>

                            <dt class="pu_ml20"><label>년도</label></dt>
                            <dd>
                                <select name="" class="">
                                    <option value="" selected>- 년도를 선택하세요.</option>
                                </select>
                            </dd>

                            <dt class="pu_ml20"><label>학기</label></dt>
                            <dd>
                                <select name="" class="">
                                    <option value="" selected>- 학기를 선택하세요.</option>
                                </select>
                            </dd>
                        </dl>

                        <dl class="pu_searchItemHide">
                            <dt><label>수식</label></dt>
                            <dd>
                                <a class="pu_ss_btn_grey" href="javascript:;" style="margin-top: 2px;">수식추가</a>
                            </dd>
                            <dd style="width:100%">

                            </dd>
                        </dl>

                        <dl class="pu_searchItemHide">
                            <dt><label>검색조건</label></dt>
                            <dd>
                                <select name="" class="">
                                    <option value="" selected>질문 내용</option>
                                </select>
                                <input type="text" placeholder="검색어를 입력해주세요." style="width: 400px;" />
                            </dd>
                            <dd>
                                <a href="javascript:;" class="pu_btnSerch01">검색</a>
                            </dd>
                        </dl>

                        <div class="pu_btn_wrap">
                            <a class="dev_btn_opt_open pu_ss_btn_defalut pu_btn_opt_open"><i class="xi-plus"></i> 옵션</a>
                            <a class="dev_btn_opt_close pu_ss_btn_grey pu_btn_opt_close"><i class="xi-minus"></i> 옵션</a>
                        </div>
                    </div>
                </div>
                <!-- 검색영역(e) -->
                <!-- 리스트(s) -->
                <div>
                    <table class="pu_table_list">
                        <colgroup>
                            <col style="width:7%;">
                            <col style="width:61%;">
                            <col style="width:15%;">
                            <col style="width:10%;">
                            <col style="width:7%;">
                        </colgroup>
                        <thead>
                            <tr>
                                <th scope="col">번호</th>
                                <th scope="col" class="list">제목</th>
                                <th scope="col">작성자</th>
                                <th scope="col">작성일</th>
                                <th scope="col">조회</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>20</td>
                                <td class="list">
                                    <a href="news_view.html">
                                        <span class="pu_notice">알림</span>종개협 등록
                                        칡소·제주흑우, 한우로 인정
                                    </a>
                                </td>
                                <td>관리자</td>
                                <td>2022-08-14</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list">
                                    <a href="news_view.html">
                                        <span class="pu_re">RE</span> 참치·연어 통조림
                                        GMO표시 전무…허술한 표시제도
                                    </a>
                                </td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list"><a href="news_view.html">지방 줄인 '슈퍼돼지'가 탄생하다 </a></td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list">유전자로 참깨 원산지 판별하는 기술 개발 </td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list"><a href="news_view.html">참치·연어 통조림 GMO표시 전무…허술한 표시제도 </a></td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list"><a href="news_view.html">지방 줄인 '슈퍼돼지'가 탄생하다 </a></td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                            <tr>
                                <td>20</td>
                                <td class="list">유전자로 참깨 원산지 판별하는 기술 개발 </td>
                                <td>정P&C연구소</td>
                                <td>2022-07-30</td>
                                <td>256</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 리스트(e) -->
                <!-- 페이징(s) -->
                <div class="pu_pagination_2">
                    <a href="javascript:;"><i class="xi-angle-left"></i></a>
                    <a href="javascript:;">1</a>
                    <a href="javascript:;" class="active">2</a>
                    <a href="javascript:;">3</a>
                    <a href="javascript:;">4</a>
                    <a href="javascript:;">5</a>
                    <a href="javascript:;">6</a>
                    <a href="javascript:;">7</a>
                    <a href="javascript:;">8</a>
                    <a href="javascript:;">9</a>
                    <a href="javascript:;">10</a>
                    <a href="javascript:;"><i class="xi-angle-right"></i></a>
                </div>
                <!-- 페이징(e) -->
            </div>
            <!-- 서브 실제 컨텐츠 영역(e) -->
        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-- 서브 배너 (s) -->
            <div class="pu_subVisual pu_sub04">
                <dl>
                    <dt>양돈정보</dt>
                    <dd>정부 및 대 기업체의 양돈관련 연구 프로젝트를 수행 중에 있습니다.</dd>
                </dl>
            </div>
            <!-- 서브 배너 (e) -->
            <!-- 서브 메뉴 (s) -->
            <div class="pu_locationWrap">
                <div class="pu_wd_def_max pu_locationMenu">
                    <a class="pu_home" href="javascript:;"><i class="xi-home-o"></i></a>
                    <ul class="pu_lnbList">
                        <li>
                            <div class="pu_select_wrap">
                                <a class="pu_txtwrap"><span>양돈정보</span> <i class="xi-angle-down-min"></i></a>
                                <div class="pu_select_list">
                                    <ul class="pu_select_menu">
                                        <li><a href="javascript:;">㈜정P&C연구소</a></li>
                                        <li><a href="javascript:;">연구·사업</a></li>
                                        <li><a href="javascript:;">분석센터</a></li>
                                        <li><a href="javascript:;">양돈정보</a></li>
                                        <li><a href="javascript:;">JP&C몰</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="pu_select_wrap">
                                <a class="pu_txtwrap"><span>양돈시세</span><i class="xi-angle-down-min"></i></a>
                                <div class="pu_select_list">
                                    <ul class="pu_select_menu">
                                        <li><a href="javascript:;">양돈시세</a></li>
                                        <li><a href="javascript:;">시장정보</a></li>
                                        <li><a href="javascript:;">질병정보</a></li>
                                        <li><a href="javascript:;">세계 사료곡물 동향</a></li>
                                        <li><a href="javascript:;">신기술/신정보</a></li>
                                        <li><a href="javascript:;">실시간 서비스</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="pu_select_wrap">
                                <a class="pu_txtwrap"><span>국내뉴스</span><i class="xi-angle-down-min"></i></a>
                                <div class="pu_select_list">
                                    <ul class="pu_select_menu">
                                        <li><a href="javascript:;">국내뉴스</a></li>
                                        <li><a href="javascript:;">국내시장정보</a></li>
                                        <li><a href="javascript:;">해외뉴스</a></li>
                                        <li><a href="javascript:;">해외시장정보</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 서브 메뉴 (e) -->
            <!-- 타이틀 (s) -->
            <div class="pu_subTitle01">
                <article class="pu_wd_def_max">
                    <h2>국내뉴스</h2>
                    <p>국내 돈가 전망 및 뉴스에 대한 정보를 제공해드립니다.</p>
                </article>
            </div>
            <!-- 타이틀 (e) -->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">
                <div>
                    <table class="pu_table_view">
                        <thead>
                            <tr>
                                <th scope="col">유전자로 참깨 원산지 판별하는 기술 개발</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <ul class="pu_newsTopInofo">
                                        <li>
                                            <p class="pu_subject"><span>작성일 : </span></p>
                                            <p class="pu_info">2022-09-11</p>
                                        </li>
                                        <li>
                                            <p class="pu_subject"><span>조회 : </span></p>
                                            <p class="pu_info"><i class="xi-eye-o"></i> 2305</p>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ul class="pu_newsTopInofo">
                                        <li>
                                            <p class="pu_subject"><span>첨부파일 : </span></p>
                                            <p class="pu_info">
                                                <a class="pu_attachFile"
                                                   href="javascript:;">첨부파일명_20220710.hwp</a>
                                            </p>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="pu_contents">
                                        농관원, ‘과실생장촉진제 간편분석법’ 등 실용기술도
                                        <br><br>
                                        국립농산물품질관리원(원장 김대근)은 세계 최초로 ‘수입산ㆍ국내산 혼합 참깨 원산지 판별법’, ‘첨단 분석기기를 이용한 청국장
                                        원산지 판별법’, ‘과실 생장촉진제(에세폰) 신속분석법’ 등 농식품 품질ㆍ안전관리 첨단 실용기술을 개발했다고 최근 밝혔다

                                        <br><br>
                                        이번에 개발된 ‘유전자 분석을 이용한 참깨 원산지 판별법’은 현재 이화학검정법을 이용해 원산지판별을 하는 참깨를 유전자분석을 통해
                                        원산지 판별이 가능하도록 한 것이다. 종전의 이화학적 분석법으로는 수입ㆍ국내산의 혼합품 분석이 어려웠으나, 새로 개발된
                                        유전자분석법은 정확하게 판별이 가능해 참깨의 지능적인 부정유통을 원천적으로 차단할 수 있게 됐다
                                        <br><br>
                                        출처 : 전업농신문(http://www.palnews.co.kr)
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="pu_prenext">
                                        <dl>
                                            <dt><span>이전글</span> <i class="xi-angle-up"></i></dt>
                                            <dd><a href="javascript:;">중소벤처기업부 박영선 장관님 축사</a></dd>
                                        </dl>
                                        <p class="pu_date">2022.08.30</p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="pu_prenext">
                                        <dl>
                                            <dt><span>다음글</span> <i class="xi-angle-down"></i></dt>
                                            <dd><a href="javascript:;">다음글 없음</a></dd>
                                        </dl>
                                        <p class="pu_date">2022.08.30</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="pu_btnDiv01">
                        <a class="pu_btn_red" href="news_list.html"><i class="xi-bars"></i> 목록</a>
                        <a class="pu_btn_dark" href="javascript:;"><i class="xi-print"></i> 인쇄</a>
                    </div>
                </div>
            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->
        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->
        <!-------------------------- 컨텐츠 영역 부분(s) -------------------------->
        <div class="dev_wrapper_middle pu_wrapper_middle_normal">

            <!-- 타이틀 (s) -->
            <div class="pu_subTitle01">
                <article class="pu_wd_def_max">
                    <h2 class="pu_search">통합검색</h2>
                    <p>찾으시는 단어를 입력해주세요.</p>
                </article>
            </div>
            <!-- 타이틀 (e) -->
            <!-- 서브 실제 컨텐츠 영역 (s) -->
            <div class="pu_wd_def_max pu_layoutContent">

                <!-- 검색영역(s) -->
                <div class="pu_topSearchDiv">

                    <input type="text" placeholder="검색어를 입력해주세요.">
                    <input type="button" class="pu_btnSerch01" title="검색">

                    <article>
                        <h4>인기검색어 :</h4>
                        <a href="javascript:;">#양돈산업</a>
                        <a href="javascript:;">#항생제</a>
                        <a href="javascript:;">#유전자검사</a>
                        <a href="javascript:;">#항체생성</a>
                        <a href="javascript:;">#질병</a>
                    </article>
                </div>
                <!-- 검색영역(e) -->
                <!-- 검색결과 list(s) -->
                <div class="pu_searchListDiv">

                    <div class="pu_search_result">
                        <div class="pu_search_tit">
                            <p>
                                <strong>'양돈산업'</strong>의 결과목록
                            </p>
                            <span>검색결과<strong>2</strong>건</span>
                        </div>

                        <ul class="pu_search_list">
                            <li>
                                <div class="pu_search_location">
                                    <i class="xi-paper-o"></i> <span>
                                        연구/사업 <i class="xi-angle-right"></i> 양돈컨설팅 <i class="xi-angle-right"></i> 컨설턴트 소개
                                    </span>
                                </div>
                                <div class="pu_search_list_txt">
                                    <span class="pu_num">1.</span>
                                    <span class="pu_tit">
                                        <a href="javascript:;">
                                            한국<strong>양돈산업</strong>컨설팅그룹은 나로
                                            대형화하고 고도의
                                            기술지향으로 변하고 있는
                                        </a>
                                    </span>
                                    <span class="pu_linkdate">
                                        <a class="pu_ss_btn_defalut"
                                           href="javascript:;">바로가기</a>
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div class="pu_search_location">
                                    <i class="xi-paper-o"></i> <span>
                                        사업관리 <i class="xi-angle-right"></i> 사업관리소개
                                    </span>
                                </div>
                                <div class="pu_search_list_txt">
                                    <span class="pu_num">1.</span>
                                    <span class="pu_tit">
                                        <a href="javascript:;">
                                            <strong>양돈산업</strong>다산성모돈의 생시 사산돈
                                            감소
                                            방안
                                        </a>
                                    </span>
                                    <span class="pu_linkdate">
                                        <a class="pu_ss_btn_defalut"
                                           href="javascript:;">바로가기</a>
                                    </span>
                                </div>
                                <div class="pu_search_list_txt">
                                    <span class="pu_num">2.</span>
                                    <span class="pu_tit">
                                        <a href="javascript:;">
                                            고도의 기술지향으로 변하고
                                            있는<strong>양돈산업</strong>컨설팅그룹은 나로
                                            대형화하고
                                        </a>
                                    </span>
                                    <span class="pu_linkdate">
                                        <a class="pu_ss_btn_defalut"
                                           href="javascript:;">바로가기</a>
                                    </span>
                                </div>
                                <div class="pu_search_list_txt">
                                    <span class="pu_num">3.</span>
                                    <span class="pu_tit">
                                        <a href="javascript:;">
                                            <strong>양돈산업</strong>컨설팅그룹은 나로 대형화하고
                                            고도의 기술지향으로
                                            변하고 있는
                                        </a>
                                    </span>
                                    <span class="pu_linkdate">
                                        <a class="pu_ss_btn_defalut"
                                           href="javascript:;">바로가기</a>
                                    </span>
                                </div>
                            </li>

                        </ul>
                    </div>

                    <div class="pu_search_result">
                        <div class="pu_search_tit">
                            <p>
                                <strong>'양돈산업'</strong>의 관련게시글
                            </p>
                            <span>검색결과<strong>122</strong>건</span>
                        </div>

                        <ul class="pu_search_list">
                            <li>
                                <div class="pu_search_location">
                                    <i class="xi-paper-o"></i> <span>
                                        연구/사업 <i class="xi-angle-right"></i> 양돈컨설팅 <i class="xi-angle-right"></i> 컨설턴트 소개
                                    </span>
                                </div>
                                <div class="pu_search_list_txt">
                                    <span class="pu_num">1.</span>
                                    <span class="pu_tit">
                                        <a href="javascript:;">
                                            한국<strong>양돈산업</strong>컨설팅그룹은 나로
                                            대형화하고 고도의
                                            기술지향으로 변하고 있는
                                        </a>
                                    </span>
                                    <span class="pu_linkdate">2022.02.10</span>
                                </div>
                            </li>
                            <li>
                                <div class="pu_search_location">
                                    <i class="xi-paper-o"></i> <span>
                                        사업관리 <i class="xi-angle-right"></i> 사업관리소개
                                    </span>
                                </div>
                                <div class="pu_search_list_txt">
                                    <span class="pu_num">1.</span>
                                    <span class="pu_tit">
                                        <a href="javascript:;">
                                            <strong>양돈산업</strong>다산성모돈의 생시 사산돈
                                            감소
                                            방안
                                        </a>
                                    </span>
                                    <span class="pu_linkdate">2022.02.10</span>
                                </div>
                                <div class="pu_search_list_txt">
                                    <span class="pu_num">2.</span>
                                    <span class="pu_tit">
                                        <a href="javascript:;">
                                            고도의 기술지향으로 변하고
                                            있는<strong>양돈산업</strong>컨설팅그룹은 나로
                                            대형화하고
                                        </a>
                                    </span>
                                    <span class="pu_linkdate">2022.02.10</span>
                                </div>
                                <div class="pu_search_list_txt">
                                    <span class="pu_num">3.</span>
                                    <span class="pu_tit">
                                        <a href="javascript:;">
                                            <strong>양돈산업</strong>컨설팅그룹은 나로 대형화하고
                                            고도의 기술지향으로
                                            변하고 있는
                                        </a>
                                    </span>
                                    <span class="pu_linkdate">2022.02.10</span>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
                <!-- 검색결과 list(e) -->
            </div>
            <!-- 서브 실제 컨텐츠 영역 (e) -->

        </div>
        <!-------------------------- 컨텐츠 영역 부분(e) -------------------------->
        <!-------------------------- 하단footer부분 (s) -------------------------->
        <div class="pu_wrapper_footer">
            <footer class="pu_wd_def_max pu_jpncW">
                <ul class="pu_footMenu">
                    <li><a href="javascript:;">회사소개</a></li>
                    <li><a href="javascript:;">찾아오시는길</a></li>
                    <li><a href="javascript:;">이용약관</a></li>
                    <li><a href="javascript:;">개인정보보호정책</a></li>
                </ul>
                <address>
                    <ul>
                        <li>(주)정피앤씨연구소 ｜ 대표: 정영철 ｜ 사업자등록번호 : 129-66-14186 ｜ 통신판매업 : 제2011-용인기흥-00370호</li>
                        <li>주소 : 경기도 용인시 기흥구 흥덕중앙로 20 U-Tower 1504 호</li>
                        <li><span>Tel. 031-704-8113</span> Fax. 031-705-0296 E-mail. pnc8113@jpnc.co.kr</li>
                        <li>Copyright 1999~2022 Jung P&C Institute, Inc. All right reserved</li>
                    </ul>
                </address>
                <ul class="pu_footerSns">
                    <li><a href="javascript:;"><i class="xi-facebook-official xi-2x"></i> <span>페이스북</span></a></li>
                    <li><a href="javascript:;"><i class="xi-naver xi-2x"></i> <span>네이버</span></a></li>
                    <li><a href="javascript:;"><i class="xi-twitter xi-2x"></i> <span>트위터</span></a></li>
                    <li><a href="javascript:;"><i class="xi-youtube xi-2x"></i> <span>유투브</span></a></li>
                </ul>
            </footer>
        </div>
        <button class="pu_ToTopBtn"><i class="xi-arrow-up xi-2x"></i></button>
        <!-------------------------- 하단footer부분 (e) -------------------------->

        <div class="pu_wd_def_max"
             style="margin: 0px auto; display: flex; flex-direction: row; justify-content: center; height: 700px;">

            <section style="width: 50%;">
                <h2>폼 스타일</h2>
                <dl>
                    <dt>기본 input</dt>
                    <dd><input type="text" name=""></dd>
                    <dd><input type="text" name="" disabled="disabled" value="disabled"></dd>
                    <dd><input type="text" name="" readonly="readonly" value="readonly"></dd>
                </dl>

                <dl>
                    <dt>날짜</dt>
                    <dd>
                        <div class="pu_dateSelect">
                            <ul class="pu_date_calendar">
                                <li>
                                    <input type="text" name="" id="" class="" value="2021-08-10" readonly="readonly">
                                    <input type="image" src="../common/img/icon_calendar.png">
                                </li>
                                <li>~</li>
                                <li>
                                    <input type="text" name="" id="" class="" value="2021-12-10" readonly="readonly">
                                    <input type="image" src="../common/img/icon_calendar.png">
                                </li>
                            </ul>
                        </div>

                        <div>
                            <ul class="pu_date_calendar">
                                <li>
                                    <input type="text" name="" id="" class="" value="2021-08-10" readonly="readonly">
                                    <input type="image" src="../common/img/icon_calendar.png">
                                </li>
                                <li>~</li>
                                <li>
                                    <input type="text" name="" id="" class="" value="2021-12-10" readonly="readonly">
                                    <input type="image" src="../common/img/icon_calendar.png">
                                </li>
                            </ul>
                        </div>
                    </dd>
                </dl>

                <dl>
                    <dt>기본 selectbox</dt>
                    <dd>
                        <select name="" class="pu_select-box" title="">
                            <option value="" selected>선택하세요</option>
                            <option value="">아이디</option>
                            <option value="">이름</option>
                        </select>
                    </dd>
                    <dd>
                        <select name="" class="pu_select-box" disabled="disabled" title="">
                            <option value="" selected>선택하세요</option>
                            <option value="">아이디</option>
                            <option value="">이름</option>
                        </select>
                    </dd>
                </dl>

                <dl>
                    <dt>radio박스</dt>
                    <dd>
                        <input type="radio" id="radio01" name="radioset01" checked=""><label for="radio01">전체</label>
                        <input type="radio" id="radio02" name="radioset01"><label for="radio02">미적용</label>
                        <input type="radio" id="radio03" name="radioset01"><label for="radio03">적용</label>
                        <input type="radio" id="radio04" name="radioset02" checked="" disabled="disabled"><label for="radio04">적용완료</label>
                    </dd>
                </dl>

                <dl>
                    <dt>check 박스</dt>
                    <dd>
                        <input type="checkbox" id="chk_1"><label for="chk_1">선택01</label>
                        <input type="checkbox" id="chk_2"><label for="chk_2">선택01</label>
                    </dd>
                </dl>

                <dl>
                    <dt>파일첨부</dt>
                    <dd>
                        <div class="pu_file_attach">
                            <input type="text" id="fileName" class="pu_file_input_textbox" readonly="readonly">
                            <div class="pu_file_input_div">
                                <input type="button" value="첨부하기" class="pu_file_input_button" />
                                <input type="file" name="" class="pu_file_input_hidden"
                                       onchange="javascript: document.getElementById('fileName').value = this.value" />
                            </div>
                        </div>
                    </dd>
                </dl>

            </section>

            <section style="width: 50%;">
                <h2>버튼 스타일</h2>

                <div class="">
                    <a href="javascript:;" class="pu_btn_defalut">기본버튼</a>
                    <a href="javascript:;" class="pu_btn_dark">다크버튼</a>
                    <a href="javascript:;" class="pu_btn_grey">red버튼</a>
                    <a href="javascript:;" class="pu_btn_red">red버튼</a>
                    <a href="javascript:;" class="pu_btn_blue">blue버튼</a>
                </div>
                <br><br>
                <div class="">
                    <a href="javascript:;" class="pu_s_btn_defalut">작은기본버튼</a>
                    <a href="javascript:;" class="pu_s_btn_dark">작은다크버튼</a>
                    <a href="javascript:;" class="pu_s_btn_grey">작은회색버튼</a>
                    <a href="javascript:;" class="pu_s_btn_red">작은red버튼</a>
                    <a href="javascript:;" class="pu_s_btn_blue">작은blue버튼</a>
                </div>
                <br><br>
                <div class="">
                    <a href="javascript:;" class="pu_ss_btn_defalut">더작은 기본버튼</a>
                    <a href="javascript:;" class="pu_ss_btn_dark">더작은다크버튼</a>
                    <a href="javascript:;" class="pu_ss_btn_grey">더작은회색버튼</a>
                    <a href="javascript:;" class="pu_ss_btn_red">더작은red버튼</a>
                    <a href="javascript:;" class="pu_ss_btn_blue">더작은blue버튼</a>
                </div>
                <br><br>

                <h2>포인트 텍스트</h2>
                <div>
                    <ul>
                        <li><span class="pu_txtRed">포인트 red</span></li>
                        <li><span class="pu_txtBlue">포인트 blue</span></li>
                        <li><span class="pu_txtGrey">포인트 grey</span></li>
                        <li><span class="pu_txtGreen">포인트 green</span></li>
                        <li><span class="pu_txtPink">포인트 pink</span></li>
                    </ul>
                </div>
            </section>
        </div>
    </div>

</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {
            return {

                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , list_1: []
                , list_2: []
                , list_3: []
                , list_4: []

                , view_1: {}
                , view_2: {}
                , view_3: {}
                , view_4: {}
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                // this
                let _this = this;

                _this.exec("INIT");
            }

            // 실행
            , exec: function (gubun) {

                // this
                let _this = this;
                let url = "";
                let data = { "gubunProc": gubun };

                switch (gubun) {

                    case "INIT":
                        {
                            //url = "/vue_api/user/login/login";
                        }
                        break;

                    case "ALERT":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // API 조회
                _this.$SendPost(url, data, _this.execReturn);
            }

            // 리턴 데이터
            , execReturn: function (data, err) {

                console.log(data);

                // this
                let _this = this;

                switch (data.GUBUN_PROC) {

                    case "INIT":
                        {

                        }
                        break;

                    case "ALERT":
                        {

                        }
                        break;

                    default:
                        {

                        };
                }

                // dimm 숨기기
                _this.$HideDimmLogo();
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);

            $(document).off("click", ".dev_btn_opt_open");
            $(document).on("click", ".dev_btn_opt_open", function () {
                $(".pu_listSearchMulti").removeClass("pu_fixedTopSearchMin");
            });

            $(document).off("click", ".dev_btn_opt_close");
            $(document).on("click", ".dev_btn_opt_close", function () {
                $(".pu_listSearchMulti").addClass("pu_fixedTopSearchMin");
            });

        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }
    }

</script>