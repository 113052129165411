<template>
    <div class="pu_AdminLayout">
        <div class="pu_AdminWrap">

            <inc_zadmin_head />
            <inc_zadmin_left />

            <div id="main" class="pu_ContentsWrap">
                <div class="pu_ContentsDiv">

                    <inc_zadmin_title />

                    <!-- 검색조건 (s) -->
                    <section class="pu_searchSection">
                        <form id="searchForm" class="pu_searchBox" onsubmit="return false">
                            <dl>
                                <dt class="pu_wd90"><label for="">교육과정</label></dt>
                                <dd>
                                    <select class="pu_select-box" name="code_subject_1" v-model="code_subject_depth_1"
                                            v-on:change="getCodeSubject(2, '', $event)" title="과목을 선택하세요." style="width:200px">
                                        <option value="">- 과목을 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_subject_2" v-model="code_subject_depth_2"
                                            v-on:change="getCodeSubject(3, '', $event)" title="개정년도를 선택하세요." style="width:200px">
                                        <option value="">- 개정년도를 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_subject_3" v-model="code_subject_depth_3"
                                            v-on:change="getCodeSubject(4, '', $event)" title="교육과정을 선택하세요." style="width:200px">
                                        <option value="">- 교육과정을 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_3" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_subject_4" v-model="code_subject_depth_4"
                                            v-on:change="getCodeSubject(5, '', $event)" title="대단원을 선택하세요." style="width:200px">
                                        <option value="">- 대단원을 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_4" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_subject_5" v-model="code_subject_depth_5"
                                            v-on:change="getCodeSubject(6, '', $event)" title="중단원을 선택하세요." style="width:200px">
                                        <option value="">- 중단원을 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_subject_depth_5" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>
                                </dd>
                            </dl>

                            <dl>
                                <dt class="pu_wd90"><label for="">학교</label></dt>
                                <dd>
                                    <select class="pu_select-box" name="code_area_1" v-model="code_area_depth_1"
                                            v-on:change="getCodeArea(2, '', $event)" title="시도를 선택하세요." style="width:200px">
                                        <option value="">- 시도를 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_area_depth_1" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_area_2" v-model="code_area_depth_2"
                                            v-on:change="getCodeArea(3, '', $event)" title="구군을 선택하세요." style="width:200px">
                                        <option value="">- 구군을 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_area_depth_2" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_area_3" v-model="code_area_depth_3"
                                            v-on:change="getCodeArea(4, '', $event)" title="초중고를 선택하세요." style="width:200px">
                                        <option value="">- 초중고를 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_area_depth_3" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>

                                    <select class="pu_select-box" name="code_area_4" v-model="code_area_depth_4"
                                            v-on:change="getCodeArea(5, '', $event)" title="학교명을 선택하세요." style="width:200px">
                                        <option value="">- 학교명을 선택하세요.</option>
                                        <option v-for="(row, idx) in opt_code_area_depth_4" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>
                                </dd>
                            </dl>

                            <dl>
                                <dt class="pu_wd90"><label for="">타입</label></dt>
                                <dd>
                                    <select name="gubun_exam_type" class="dev_option pu_select-box"
                                            title="타입을 선택하세요." style="width:200px">
                                        <option value="">- 타입을 선택하세요</option>
                                        <option value="A">선택</option>
                                        <option value="B">서술</option>
                                    </select>
                                </dd>

                                <dt class="pu_wd90"><label for="" style="margin-left: 20px;">난이도</label></dt>
                                <dd>
                                    <select name="gubun_exam_diff" class="dev_option pu_select-box"
                                            title="난이도를 선택하세요." style="width:200px">
                                        <option value="">- 난이도를 선택하세요</option>
                                        <option value="1">하</option>
                                        <option value="2">중</option>
                                        <option value="3">상</option>
                                        <option value="4">킬러</option>
                                    </select>
                                </dd>
                                <dt class="pu_wd90"><label for="" style="margin-left: 20px;">년도</label></dt>
                                <dd>
                                    <select name="school_year" class="dev_option pu_select-box"
                                            title="년도를 선택하세요." style="width:200px">
                                        <option value="">- 년도를 선택하세요</option>
                                        <option v-for="(row, idx) in opt_school_year" v-bind:value="row.YEAR">{{ row.YEAR }}</option>
                                    </select>
                                </dd>

                                <dt class="pu_wd90"><label for="" style="margin-left: 20px;">학기</label></dt>
                                <dd>
                                    <select name="school_semester" class="dev_option pu_select-box"
                                            title="학기를 선택하세요." style="width:200px">
                                        <option value="">- 학기를 선택하세요</option>
                                        <option v-for="(row, idx) in opt_school_semester" v-bind:value="row.CODE">{{ row.TITLE }}</option>
                                    </select>
                                </dd>
                            </dl>

                            <dl>
                                <dt class="pu_wd90"><label for="" style="width:100px;display:block;">수식</label></dt>
                                <dd>
                                    <a href="javascript:;" v-on:click="getLatex()" class="pu_ss_btn_grey pu_minw55" style="width: 80px;">수식 추가</a>
                                </dd>
                                <dd style="width:100%">

                                    <!--라텍스 검색-->
                                    <ul class="dev_search_latex_ul"></ul>
                                    <!--//라텍스 검색-->

                                </dd>
                            </dl>

                            <dl>
                                <dt class="pu_wd90"><label for="">검색조건</label></dt>
                                <dd>
                                    <select name="search_gubun" class="pu_select-box" title="검색조건을 선택하세요." style="width:150px">
                                        <option value="cont_q" selected="selected">질문 내용</option>
                                    </select>
                                    <input type="text" name="search_text" placeholder="검색어를 입력해주세요." style="width:500px">
                                </dd>
                            </dl>
                        </form>
                        <div class="pu_searchBtn"><a href="javascript:" class="dev_btn_search xi-search"><span>검색</span></a></div>
                    </section>
                    <!-- 검색조건(e) -->

                    <!-- 컨텐츠영역 (s) -->
                    <section class="pu_contentsSection pu_pd15">

                        <div class="pu_flex">

                            <!-- 테이블영역 (s)  -->
                            <div class="pu_listTable01" style="width:47%;">
                                <p class="pu_listNum">
                                    <i class="xi-paper-o"></i> 총 <strong>{{ list_1.length }}</strong>개의 시험문제가 있습니다.
                                </p>
                                <div class="pu_TableX01">
                                    <table id="dev_exam_from">
                                        <colgroup>
                                            <col width="35px" />
                                            <col width="70px" />
                                            <col width="510px" />
                                            <col width="70px" />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <input type="checkbox" class="dev_checkbox_all" />
                                                </th>
                                                <th scope="col">번호</th>
                                                <th scope="col">교육과정</th>
                                                <th scope="col">난이도</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, idx) in list_1">
                                                <td>
                                                    <input type="checkbox" class="dev_checkbox_each" :value="row.NO" />
                                                </td>
                                                <td>
                                                    {{ getRowNum(idx) }}
                                                </td>
                                                <td class="pu_leftTxt" style="min-width:500px !important;">
                                                    <span style="margin: 0px 0px 10px 0px; display: block; border-bottom: solid 1px #ddd;">{{ row.CODE_SUBJECT_NAME }}</span>
                                                    <span v-if="row.CODE_AREA_NAME" style="margin: 0px 0px 10px 0px; display: block; border-bottom: solid 1px #ddd;">{{ row.CODE_AREA_NAME }} / {{ row.SCHOOL_YEAR }} / {{ row.SCHOOL_SEMESTER_NAME }}</span>
                                                    <div class="pu_math_cont_opt_n" v-html="$ConHtml(row.CONT_Q)"></div>
                                                </td>
                                                <td>
                                                    {{ row.GUBUN_EXAM_DIFF_NAME }}
                                                </td>
                                            </tr>
                                            <tr v-if="list_1.length == 0">
                                                <td colspan="4">조회된 결과가 없습니다.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- 테이블영역 (e)  -->

                            <div class="pu_listTable01" style="width:150px !important; min-width:150px; position:relative;">
                                <div style="position: sticky; top: 50%;">
                                    <a class="pu_btn_tri_right_red" href="javascript:;" v-on:click="addExamItem()"
                                       style="width:100px; display:block; left:calc(50% - 50px);"><i class="xi-pen"></i> 추가</a>

                                    <a class="pu_btn_tri_left_blue" href="javascript:;" v-on:click="removeExamItem()"
                                       style="width:100px; display:block; left:calc(50% - 50px); margin-top:7px;"><i class="xi-trash"></i> 삭제</a>

                                    <a class="pu_s_btn_dark" href="javascript:;" v-on:click="savePrintItem()"
                                       style="width:110px; height:50px; line-height:50px; display:block; left:calc(50% - 55px); margin-top:30px; position:relative;"> 문제 생성</a>
                                </div>
                            </div>

                            <!-- 테이블영역 (s)  -->
                            <div class="pu_listTable01" style="width:47%;">
                                <p class="pu_listNum">
                                    <i class="xi-paper-o"></i> 총 <strong>{{ list_2.length }}</strong>개의 시험문제를 추가 하였습니다.
                                </p>
                                <div class="pu_TableX01">
                                    <table id="dev_exam_to">
                                        <colgroup>
                                            <col width="35px" />
                                            <col width="70px" />
                                            <col width="510px" />
                                            <col width="70px" />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    <input type="checkbox" class="dev_checkbox_all" />
                                                </th>
                                                <th scope="col">번호</th>
                                                <th scope="col">교육과정</th>
                                                <th scope="col">난이도</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, idx) in list_2">
                                                <td>
                                                    <input type="checkbox" class="dev_checkbox_each" :value="row.NO" />
                                                </td>
                                                <td>
                                                    {{ list_2.length - idx }}
                                                </td>
                                                <td class="pu_leftTxt" style="min-width:500px !important;">
                                                    <span style="margin: 0px 0px 10px 0px; display: block; border-bottom: solid 1px #ddd;">{{ row.CODE_SUBJECT_NAME }}</span>
                                                    <span v-if="row.CODE_AREA_NAME" style="margin: 0px 0px 10px 0px; display: block; border-bottom: solid 1px #ddd;">{{ row.CODE_AREA_NAME }} / {{ row.SCHOOL_YEAR }} / {{ row.SCHOOL_SEMESTER_NAME }}</span>
                                                    <div class="pu_math_cont_opt_n" v-html="$ConHtml(row.CONT_Q)"></div>
                                                </td>
                                                <td>
                                                    {{ row.GUBUN_EXAM_DIFF_NAME }}
                                                </td>
                                            </tr>
                                            <tr v-if="list_2.length == 0">
                                                <td colspan="4">조회된 결과가 없습니다.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- 테이블영역 (e)  -->

                        </div>

                    </section>
                    <!-- 컨텐츠영역 (e) -->
                </div>
                <div class="pu_pb20"></div>
            </div>
            <!-- 컨텐츠 layout  (e) -->
        </div>
    </div>
</template>

<script>
    // import
    import { mapGetters } from "vuex";
    import { mapActions } from "vuex";

    // export
    export default {

        computed: {

            ...mapGetters({
                getBase: "getBase"
            }),

        }

        , data() {

            return {
                gubun_page: "LIST"

                , rownum: 0    // 번호
                , paging: ""   // 페이징

                , isOkSearch: false // 검색가능
                , list_1: []
                , list_2: []

                , code_subject: "" // 검색 코드
                , code_area: ""    // 검색 코드

                , opt_code_subject_depth_1: [] // 과목 선택 옵션
                , opt_code_subject_depth_2: []
                , opt_code_subject_depth_3: []
                , opt_code_subject_depth_4: []
                , opt_code_subject_depth_5: []

                , code_subject_depth_1: ''     // 과목 선택 코드
                , code_subject_depth_2: ''
                , code_subject_depth_3: ''
                , code_subject_depth_4: ''
                , code_subject_depth_5: ''

                , opt_code_area_depth_1: [] // 학교 선택 옵션
                , opt_code_area_depth_2: []
                , opt_code_area_depth_3: []
                , opt_code_area_depth_4: []

                , code_area_depth_1: ''     // 학교 선택 코드
                , code_area_depth_2: ''
                , code_area_depth_3: ''
                , code_area_depth_4: ''

                , opt_school_year: [] // 년도

                , opt_school_semester: [] // 학기
            }
        }

        , methods: {

            ...mapActions({
                setBase: "setBase"
            })

            // 진입점
            , init() {

                let _this = this;

                // 과목코드 조회
                _this.getCodeSubject(1, "000|000|000|000|000|");

                // 학교코드 조회
                _this.getCodeArea(1, "000|000|000|000|");

                // 년도코드 조회
                _this.getCodeYear();

                // 학기코드 조회
                _this.getCodeSemester();

                // 검색 latex
                {
                    $(document).off("click", ".dev_search_latex_li");
                    $(document).on("click", ".dev_search_latex_li", function () {

                        if (confirm("수식을 삭제 하시겠습니까?")) {

                            // 삭제
                            $(this).remove();

                            // 검색
                            _this.getSearchData(1);
                        }
                    })
                }

                // 검색
                _this.getSearchData(1);
            }

            // 검색
            , getSearchData: function (curPage) {

                // qs
                var qs = this.$GetQueryMap();

                // 폼
                var data = this.$FormToMap($("#searchForm"));
                data["gubun_page"] = this.gubun_page;
                data["curPage"] = curPage;
                data["code_subject"] = this.code_subject;
                data["code_area"] = this.code_area;

                // 라텍스
                {
                    let arrLatex = [];
                    let $arrLi = $(".dev_search_latex_li");
                    for (let idx = 0; idx < $arrLi.length; idx++) {

                        let latex = $($arrLi[idx]).find("svg").attr("data-latex");
                        arrLatex.push(latex);
                    }

                    data["search_latex"] = arrLatex.join(",");
                }

                // API 조회
                this.$SendPost("/vue_api/zadmin/exam_select/list", data, this.searchDataReturn);

            }
            , searchDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.rownum = data.LIST_1.length;
                        this.list_1 = data.LIST_1;

                        this.isOkSearch = true; // 검색가능

                        // dimm 숨기기
                        this.$HideDimmLogo();
                    }
                }
            }

            // 과목코드 조회
            , getCodeSubject: function (depth, code, event) {

                // 폼
                var data = {};
                data["depth"] = depth;

                if (code) {
                    data["code"] = code;
                }
                else if (event) {
                    data["code"] = event.target.value;
                }

                this.$SendPost("/vue_api/zadmin/exam_make/code_subject", data, this.getCodeSubjectDataReturn);
            }
            , getCodeSubjectDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        var depth = data.HASH.DEPTH;
                        switch (depth) {
                            case 1:
                                this.opt_code_subject_depth_1 = data.LIST_1; this.code_subject_depth_1 = "";
                                this.opt_code_subject_depth_2 = []; this.code_subject_depth_2 = "";
                                this.opt_code_subject_depth_3 = []; this.code_subject_depth_3 = "";
                                this.opt_code_subject_depth_4 = []; this.code_subject_depth_4 = "";
                                this.opt_code_subject_depth_5 = []; this.code_subject_depth_5 = "";
                                break;

                            case 2:
                                this.opt_code_subject_depth_2 = data.LIST_1; this.code_subject_depth_2 = "";
                                this.opt_code_subject_depth_3 = []; this.code_subject_depth_3 = "";
                                this.opt_code_subject_depth_4 = []; this.code_subject_depth_4 = "";
                                this.opt_code_subject_depth_5 = []; this.code_subject_depth_5 = "";
                                break;

                            case 3:
                                this.opt_code_subject_depth_3 = data.LIST_1; this.code_subject_depth_3 = "";
                                this.opt_code_subject_depth_4 = []; this.code_subject_depth_4 = "";
                                this.opt_code_subject_depth_5 = []; this.code_subject_depth_5 = "";
                                break;

                            case 4:
                                this.opt_code_subject_depth_4 = data.LIST_1; this.code_subject_depth_4 = "";
                                this.opt_code_subject_depth_5 = []; this.code_subject_depth_5 = "";
                                break;

                            case 5:
                                this.opt_code_subject_depth_5 = data.LIST_1; this.code_subject_depth_5 = "";
                                break;
                        }

                        // 과정선택
                        {
                            this.code_subject =
                                this.code_subject_depth_5 ? this.code_subject_depth_5 :
                                    this.code_subject_depth_4 ? this.code_subject_depth_4 :
                                        this.code_subject_depth_3 ? this.code_subject_depth_3 :
                                            this.code_subject_depth_2 ? this.code_subject_depth_2 : this.code_subject_depth_1;
                        }

                        // 검색
                        if (true == this.isOkSearch) {
                            this.getSearchData(1);
                        }
                    }
                }
            }

            // 학교코드 조회
            , getCodeArea: function (depth, code, event) {

                // 폼
                var data = {};
                data["depth"] = depth;

                if (code) {
                    data["code"] = code;
                }
                else if (event) {
                    data["code"] = event.target.value;
                }

                this.$SendPost("/vue_api/zadmin/exam_make/code_area", data, this.getCodeAreaDataReturn);
            }

            , getCodeAreaDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        var depth = data.HASH.DEPTH;
                        switch (depth) {
                            case 1:
                                this.opt_code_area_depth_1 = data.LIST_1; this.code_area_depth_1 = "";
                                this.opt_code_area_depth_2 = []; this.code_area_depth_2 = "";
                                this.opt_code_area_depth_3 = []; this.code_area_depth_3 = "";
                                this.opt_code_area_depth_4 = []; this.code_area_depth_4 = "";
                                break;

                            case 2:
                                this.opt_code_area_depth_2 = data.LIST_1; this.code_area_depth_2 = "";
                                this.opt_code_area_depth_3 = []; this.code_area_depth_3 = "";
                                this.opt_code_area_depth_4 = []; this.code_area_depth_4 = "";
                                break;

                            case 3:
                                this.opt_code_area_depth_3 = data.LIST_1; this.code_area_depth_3 = "";
                                this.opt_code_area_depth_4 = []; this.code_area_depth_4 = "";
                                break;

                            case 4:
                                this.opt_code_area_depth_4 = data.LIST_1; this.code_area_depth_4 = "";
                                break;
                        }

                        // 과정선택
                        {
                            this.code_area =
                                this.code_area_depth_4 ? this.code_area_depth_4 :
                                    this.code_area_depth_3 ? this.code_area_depth_3 :
                                        this.code_area_depth_2 ? this.code_area_depth_2 : this.code_area_depth_1;
                        }

                        // 검색
                        if (true == this.isOkSearch) {
                            this.getSearchData(1);
                        }
                    }
                }
            }

            // 년도코드 조회
            , getCodeYear: function () {

                // 폼
                var data = {};

                this.$SendPost("/vue_api/zadmin/exam_make/code_year", data, this.getCodeYearDataReturn);
            }

            , getCodeYearDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.opt_school_year = data.LIST_1;
                    }
                }
            }

            // 학기코드 조회
            , getCodeSemester: function () {

                // 폼
                var data = {};

                this.$SendPost("/vue_api/zadmin/exam_make/code_semester", data, this.getCodeSemesterDataReturn);
            }

            , getCodeSemesterDataReturn: function (data, err) {

                if (data) {

                    // 데이터 처리
                    if (data.RESULT_YN === "Y") {

                        this.opt_school_semester = data.LIST_1;
                    }
                }
            }

            // 라텍스 조회
            , getLatex: function () {

                window.removeEventListener("message", this.getLatexReturn, false);
                window.addEventListener("message", this.getLatexReturn);

                FrameLayer.open("https://js.hyper-nomad.com/module/matheditor/index.html", '720px', '520px');
            }
            , getLatexReturn: function (event) {

                let $span = $(event.data);
                let $svg = $span.find("svg");

                let val = $svg.attr("data-latex");
                if (val) {
                    let $li = $("<li class='dev_search_latex_li'></li>")
                    $li.append($svg);
                    $(".dev_search_latex_ul").append($li);
                }

                FrameLayer.close();

                // 검색
                this.getSearchData(1);
            }

            // 시험문제 추가
            , addExamItem: function () {

                // 포인트 조회
                this.$SendPost("/vue_api/zadmin/exam_select/point", {}, this.addExamItemReturn);
            }
            , addExamItemReturn: function (data, err) {

                if (data) {

                    // 포인트
                    let isOver = false;
                    let point = data.HASH.POINT;

                    // 체크된 elm
                    let arrElmCh = [];
                    arrElmCh = $.merge(arrElmCh, $("#dev_exam_from").find(".dev_checkbox_each:checked"));

                    // 체크된 no 추출
                    let arrNoCh = [];
                    $.each(arrElmCh, function (idx, el) {

                        var value = el.value;
                        arrNoCh.push(value);
                    });

                    for (let idx_noCh = 0; idx_noCh < arrNoCh.length; idx_noCh++) {

                        // 선택된 번호
                        let noCh = arrNoCh[idx_noCh];

                        // list_2 에 이미 추가되었는지 체크
                        let isEx = false;
                        for (let idx_list_2 = 0; idx_list_2 < this.list_2.length; idx_list_2++) {
                            if (noCh == this.list_2[idx_list_2].NO) {
                                isEx = true; break;
                            }
                        }

                        // 추가안되어 있으면 넣기
                        if (false == isEx) {

                            let row;
                            for (let idx_list_1 = 0; idx_list_1 < this.list_1.length; idx_list_1++) {
                                if (noCh == this.list_1[idx_list_1].NO) {
                                    row = this.list_1[idx_list_1]; break;
                                }
                            }

                            if (row) {

                                if (this.list_2.length + 1 > point) {

                                    // 포인트 오버
                                    isOver = true;
                                } else {

                                    // 문제 추가
                                    this.list_2.push(row);
                                }
                            }
                        }
                    }

                    $("#dev_exam_from").find(".dev_checkbox_each").prop("checked", false);

                    if (isOver == true) {
                        alert("포인트가 부족하여\n일부 문제가 추가되지 않았습니다.");
                    }
                }
            }

            // 시험문제 제거
            , removeExamItem: function () {

                let arrEle = [];
                let arrSel = [];
                arrEle = $.merge(arrEle, $("#dev_exam_to").find(".dev_checkbox_each:checked"));

                // value 추출
                $.each(arrEle, function (idx, el) {

                    var value = el.value;
                    arrSel.push(value);
                });

                for (let idx_sel = 0; idx_sel < arrSel.length; idx_sel++) {

                    // 선택된 번호
                    let noSel = arrSel[idx_sel];

                    for (let idx_list_2 = 0; idx_list_2 < this.list_2.length; idx_list_2++) {
                        if (noSel == this.list_2[idx_list_2].NO) {
                            this.list_2.splice(idx_list_2, 1);
                            break;
                        }
                    }
                }

                $("#dev_exam_to").find(".dev_checkbox_each").prop("checked", false);
            }

            // 시험문제 출력
            , savePrintItem: function () {

                if (this.list_2.length < 1) {
                    alert("추가된 시험문제가 없습니다.");
                    return;
                }

                if (confirm("추가된 시험문제를 프린트 하시겠습니까?\n[ " + this.list_2.length + " ]포인트가 차감됩니다.")) {

                    let title = prompt("시험지 제목을 입력해주세요.", "") || "";
                    if (!title.trim()) {

                        alert("취소 되었습니다.");
                    }
                    else {

                        // 문제 번호
                        let arrNo = [];
                        for (let idx = 0; idx < this.list_2.length; idx++) {
                            arrNo.push(this.list_2[idx].NO);
                        }

                        // 폼
                        var data = {};
                        data["title"] = title;
                        data["strNo"] = arrNo.join(",");

                        this.$SendPost("/vue_api/zadmin/exam_select/save", data, this.savePrintItemReturn);
                    }
                }
                else {
                    alert("취소 되었습니다.");
                }
            }
            , savePrintItemReturn: function (data, err) {

                if (data) {

                    let no = data.HASH.NO;
                    this.$PageMove('/zadmin/exam_print/list?no=' + no);
                }
            }
        }

        , beforeCreate() {
        }

        , created() {
        }

        , beforeMount() {
        }

        , mounted() {
            this.$AfterMounted(this);
        }

        , beforeUpdate() {
        }

        , updated() {
            this.$AfterUpdated(this);
        }

        , beforeDestroy() {
        }

        , destroyed() {
        }

    }

</script>